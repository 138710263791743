import React from "react";
import PropTypes from "prop-types";
import { SharedCard } from "../../shared";

function MainBenefitsCard({ imageSrc, title, body, className, action }) {
    return (
        <SharedCard className={`bg-flychainPurple-white ${className}`}>
            <img src={imageSrc} alt={title} className="mb-[20px] w-full" />
            <div className="text-[18px] font-medium mb-[10px] [&_sup]:text-[12.5px] [&_sup]:font-normal">
                {title}
            </div>
            <div className="text-[14px] font-normal text-flychainGray">
                {body}
            </div>
            {action}
        </SharedCard>
    );
}

MainBenefitsCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    body: PropTypes.string.isRequired,
    className: PropTypes.string,
    action: PropTypes.node,
};

MainBenefitsCard.defaultProps = {
    className: "",
    action: null,
};

export default MainBenefitsCard;
