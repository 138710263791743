import React, { useState, useEffect } from "react";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { SharedSectionTitle } from "../../../shared/Text";
import { SharedStateTextInput } from "../../../shared/Inputs";
import PendingInviteCard from "./PendingInviteCard";
import CloseSvg from "../../../../assets/Close.svg";
import TriangleAlertSvg from "../../../../assets/TriangleAlert";
import DownCaret from "../../../../assets/DownCaret.svg";
import RightCaret from "../../../../assets/RightCaret.svg";
import { useGetPendingInvitesForProviderOrgQuery } from "../../../../redux/slices/apiV2";

export default function PendingInvitesTable() {
    const providerId = useGetProviderId();
    const [showContent, setShowContent] = useState(true);
    const [inviteQuery, setInviteQuery] = useState("");
    const [inviteResults, setInviteResults] = useState([]);
    const { data: pendingInvites } = useGetPendingInvitesForProviderOrgQuery({
        providerId,
    });

    useEffect(() => {
        if (pendingInvites) {
            const filteredInvites = pendingInvites.filter((invite) =>
                invite.invitee_email
                    .toLowerCase()
                    .includes(inviteQuery.toLowerCase())
            );
            setInviteResults(filteredInvites);
        }
    }, [pendingInvites, inviteQuery]);

    const renderPendingInvites = () => {
        if (inviteResults.length === 0) {
            return (
                <div className="flex flex-row justify-center items-center gap-x-[10px] py-[20px] text-[16px] text-flychainPurple-dark">
                    <TriangleAlertSvg fill="#110823" width={20} height={20} />
                    No pending invitations found for your query
                </div>
            );
        }

        return (
            <div className="flex flex-col">
                <div className="grid grid-cols-[2fr_1fr_1.5fr_1.5fr_1.5fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                    <div>Email</div>
                    <div>Role</div>
                    <div>Created At</div>
                    <div>Expires At</div>
                    <div className="flex justify-center">Revoke Invite</div>
                </div>
                {inviteResults.map((invite, index) => (
                    <PendingInviteCard
                        key={index}
                        providerId={providerId}
                        invite={invite}
                        isLast={index === inviteResults.length - 1}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="flex flex-col pt-[16px] border-t">
            <div
                className="flex flex-row items-center gap-x-[10px] mb-[10px] cursor-pointer"
                onClick={() => setShowContent(!showContent)}
            >
                <SharedSectionTitle>Pending Invitations</SharedSectionTitle>
                <img
                    src={showContent ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[14px] h-[14px] brightness-0"
                />
            </div>
            {showContent && (
                <div className="flex flex-col gap-y-[15px] mt-[5px]">
                    <div className="flex flex-row justify-between items-center w-full gap-x-[40px]">
                        <div className="flex flex-row items-center w-full gap-x-[20px]">
                            <SharedStateTextInput
                                className="w-full"
                                name="search"
                                placeholder="Search for pending invitations by email"
                                value={inviteQuery}
                                onChange={(value) => setInviteQuery(value)}
                            />
                            <img
                                className="cursor-pointer h-[15px]"
                                src={CloseSvg}
                                alt="Close"
                                onClick={() => setInviteQuery("")}
                            />
                        </div>
                    </div>
                    {renderPendingInvites()}
                </div>
            )}
        </div>
    );
}
