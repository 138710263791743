import React from "react";
import MainBenefitsCard from "../../MainBenefitsCard";
import FreeAccountsSvg from "./FreeAccounts.svg";

function FreeAccounts() {
    return (
        <MainBenefitsCard
            imageSrc={FreeAccountsSvg}
            title="$0 To Open Accounts"
            body="Quickly open up FDIC-insured checking and savings accounts. Zero monthly fees and no overdraft fees."
        />
    );
}

export default FreeAccounts;
