import React from "react";
import BillPay from "./Cards/BillPay";
import Cashback from "./Cards/Cashback";
import FreeWiresAndAch from "./Cards/FreeWiresAndAch";
import FreeAccounts from "./Cards/FreeAccounts";
import MultiEin from "./Cards/MultiEin";
import Invoicing from "./Cards/Invoicing";
import { SharedPageTitle } from "../../shared/Text";

function MainBenefits() {
    return (
        <div className="p-[60px] flex flex-col justify-center items-center">
            <div className="mb-[40px] w-2/3 text-center items-center">
                <SharedPageTitle className="text-[36px]">
                    A Delightful Banking Experience Built For Healthcare
                    Providers
                </SharedPageTitle>
            </div>
            <div className="grid grid-cols-3 gap-[20px]">
                <Cashback />
                <FreeWiresAndAch />
                <BillPay />
                <Invoicing />
                <FreeAccounts />
                <MultiEin />
            </div>
        </div>
    );
}

export default MainBenefits;
