import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import useGetGraphPeriodOptions from "../../../hooks/useGetGraphPeriodOptions";
import { GRAPH_PERIOD } from "../constants";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ExpensesByCategory from "./ExpensesByCategory";
import ExpensesByMerchant from "./ExpensesByMerchant";
import ExpensesByCategoryGraph from "./ExpensesByCategory/ExpensesByCategoryGraph";
import ValidUpUntil from "../ValidUpUntil";
import { SharedBadge, SharedHorizontalTabs } from "../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../shared/Text";
import { SharedStateSelectInput } from "../../shared/Inputs";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";

const TABS = [
    { key: "byCategory", label: "By Category" },
    {
        key: "byMerchant",
        label: (
            <div className="flex flex-row items-center gap-x-[10px]">
                <span>By Merchant</span>
                <SharedBadge color="green">
                    <div className="text-xs font-normal text-white">Beta</div>
                </SharedBadge>
            </div>
        ),
    },
];

function Expenses() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const isPlatformAdmin = useIsAdminDashboard();
    const { currentTabIndex, setSearchParams } =
        SharedHorizontalTabs.useGetTabIndex(TABS);
    const [expensesGraphPeriod, setExpensesGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });
    const {
        data: graphPeriodOptions,
        isFetching: isFetchingGraphPeriodOptions,
    } = useGetGraphPeriodOptions({
        providerId,
        businessEntityId,
    });

    if (
        !businessEntities ||
        !graphPeriodOptions ||
        isFetchingGraphPeriodOptions
    )
        return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    // Tab change handler to be passed to the HorizontalTabs component
    const handleTabChange = (index) => {
        setSearchParams({ tab: TABS[index].key }, { replace: true });
    };

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <div className="flex flex-col gap-y-[30px]">
                <SharedPageTitle>Expenses By Category</SharedPageTitle>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex flex-row justify-between items-center">
                        <SharedSectionTitle>By Month</SharedSectionTitle>
                        <div className="mt-[5px]">
                            <SharedStateSelectInput
                                name="Expenses Period Selector"
                                label="Period"
                                variant="outlined"
                                size="small"
                                options={graphPeriodOptions}
                                currentValue={expensesGraphPeriod}
                                onChange={(value) => {
                                    setExpensesGraphPeriod(value);
                                }}
                            />
                        </div>
                    </div>
                    <ExpensesByCategoryGraph period={expensesGraphPeriod} />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <SharedSectionTitle>For Date Range</SharedSectionTitle>
                    {
                        // TODO: remove the conditional code below once we've refined ExpensesByMerchant
                        isPlatformAdmin ? (
                            <>
                                <SharedHorizontalTabs.Component
                                    tabs={TABS.map((tab) => tab.label)}
                                    initialIndex={currentTabIndex}
                                    onChange={handleTabChange}
                                />
                                {currentTabIndex === 0 ? (
                                    <ExpensesByCategory />
                                ) : (
                                    <ExpensesByMerchant />
                                )}
                            </>
                        ) : (
                            <ExpensesByCategory />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Expenses;
