import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { SharedModal } from "../shared";
import ConfirmSuccessfulSchedule from "./ConfirmSuccessfulSchedule";
import { useInitInlineWidget, useOnCalendlyEventScheduled } from "./utils";
import { STATES, CALENDLY_PARENT_ELEMENT_ID } from "./constants";
import { selectUser } from "../../redux/slices/authentication";

function CalendlyModal({ onClose, calendlyUrl, customAnswersPrefill }) {
    const user = useSelector(selectUser);
    const [state, setState] = useState(STATES.SCHEDULE_CALL);

    useInitInlineWidget(user, calendlyUrl, customAnswersPrefill);

    useOnCalendlyEventScheduled(() =>
        setState(STATES.CONFIRM_SUCCESSFUL_SCHEDULE)
    );

    return (
        <SharedModal onClose={onClose} cardClassName="w-1/2">
            {state === STATES.SCHEDULE_CALL && (
                <div className="h-[70vh]" id={CALENDLY_PARENT_ELEMENT_ID} />
            )}
            {state === STATES.CONFIRM_SUCCESSFUL_SCHEDULE && (
                <ConfirmSuccessfulSchedule onClose={onClose} />
            )}
        </SharedModal>
    );
}

CalendlyModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    calendlyUrl: PropTypes.string.isRequired,
    customAnswersPrefill: PropTypes.object,
};

CalendlyModal.defaultProps = {
    customAnswersPrefill: {},
};

export default CalendlyModal;
