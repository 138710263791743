import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import setSentryUserInfo from "../../../../sentry";
import { clearAuth } from "../index";

const signOut = createAsyncThunk(
    "authentication/signOut",
    async (logoutFunction, { dispatch }) => {
        try {
            // Log out of PropelAuth if function provided
            if (logoutFunction) {
                await logoutFunction();
            }
            // Clear Redux auth state
            dispatch(clearAuth());
            // Navigate to root to reset app state
            window.location.href = "/";
        } catch (error) {
            Sentry.captureException(new Error(JSON.stringify(error)));
            setSentryUserInfo(null);
            // Still clear auth state and redirect even if logout fails
            dispatch(clearAuth());
            window.location.href = "/";
        }
    }
);

export default signOut;
