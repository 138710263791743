import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveOrg } from "../../../redux/slices/authentication";
import { useGetProviderQuery } from "../../../redux/slices/apiV2";

export default function OrgSelectorMenuItem({
    org,
    setShowOrgMenu,
    isFirst,
    isLast,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: provider } = useGetProviderQuery({
        providerId: org.legacyOrgId,
    });

    if (!provider) {
        return null;
    }

    const selectOrg = () => {
        // TODO: Watch out for errors here
        // TODO: Figure out a better way to unmount all components when the org is changed and redirect to root
        dispatch(setActiveOrg(org));
        setShowOrgMenu(false);
        navigate("/");
    };

    return (
        <Tooltip title={provider.legal_name} placement="right">
            <div
                className={`flex flex-row w-full items-center cursor-pointer gap-x-[10px] ${
                    !isLast ? "border-white/50 border-b-[0.5px] pb-[5px]" : ""
                } ${!isFirst ? "pt-[5px]" : ""}`}
                onClick={selectOrg}
            >
                <div className="overflow-hidden items-center text-white truncate cursor-pointer">
                    {provider.legal_name}
                </div>
            </div>
        </Tooltip>
    );
}

OrgSelectorMenuItem.propTypes = {
    org: PropTypes.object.isRequired,
    setShowOrgMenu: PropTypes.func.isRequired,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
};
