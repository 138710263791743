/* eslint-disable camelcase */
import React from "react";
import PlaidLinkButton from "../PlaidLinkButton";
import PlaidAccount from "../PlaidAccount";
import PlaidItemHeader from "../PlaidItemHeader";
import { useGetPlaidItemsQuery } from "../../redux/slices/apiV2";
import useGetProviderId from "../../hooks/useGetProviderId";

function Connections() {
    const providerId = useGetProviderId();
    const { data: plaidItems } = useGetPlaidItemsQuery({
        providerId,
        params: {},
    });

    if (!plaidItems) {
        return null;
    }

    return (
        <div className="flex flex-col gap-y-[30px]">
            <PlaidLinkButton />
            {plaidItems.map((plaidItem) => (
                <div
                    key={plaidItem.item.item_id}
                    className="flex flex-col gap-y-[10px]"
                >
                    <PlaidItemHeader plaidItem={plaidItem} />
                    {plaidItem.accounts.map((account) => (
                        <PlaidAccount
                            key={account.account_id}
                            account={account}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Connections;
