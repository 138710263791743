import React from "react";
import { useNavigate } from "react-router-dom";
import SelectedProvider from "../../FinancialReports/SelectedProvider";
import AccountMappingsTable from "./AccountMappingsTable";
import { SharedAlert, SharedButton } from "../../../shared";
import { SharedPageTitle } from "../../../shared/Text";
import TriangleAlertSvg from "../../../../assets/TriangleAlert";
import useGetProviderPath from "../../../../hooks/useGetProviderPath";
import {
    useGetBusinessEntitiesQuery,
    useGetPlaidAccountMappingsQuery,
} from "../../../../redux/slices/apiV2";
import useGetProviderId from "../../../../hooks/useGetProviderId";

function OnboardingAccountMapping() {
    const providerId = useGetProviderId();
    const providerPath = useGetProviderPath();
    const navigate = useNavigate();
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: true },
    });
    const { data: plaidAccountMappings } = useGetPlaidAccountMappingsQuery({
        providerId,
    });

    if (!businessEntities || !plaidAccountMappings) {
        return null;
    }

    const navigateTofinalSteps = () => {
        navigate(`${providerPath}/onboard/finalSteps`);
    };

    return (
        <div>
            <SelectedProvider providerId={providerId} />
            <div className="flex flex-col px-[60px] pb-[60px] pt-[40px] gap-y-[30px]">
                <SharedPageTitle>Account Mapping</SharedPageTitle>
                <SharedAlert
                    icon={
                        <TriangleAlertSvg
                            fill="#8D58FF"
                            width={18}
                            height={18}
                        />
                    }
                    message="Ensure each Plaid account is linked to the correct business entity per the bookkeepers' spreadsheet. Failure to do so will break future linking."
                    borderColor="border-flychainPurple-light"
                />
                <AccountMappingsTable
                    title="Active Accounts"
                    defaultOpen
                    businessEntities={businessEntities}
                    plaidAccountMappings={plaidAccountMappings.filter(
                        (plaidAccountMapping) => plaidAccountMapping.is_active
                    )}
                />
                <AccountMappingsTable
                    title="Inactive Accounts"
                    defaultOpen={false}
                    businessEntities={businessEntities}
                    plaidAccountMappings={plaidAccountMappings.filter(
                        (plaidAccountMapping) => !plaidAccountMapping.is_active
                    )}
                />
                <SharedButton onClick={navigateTofinalSteps}>
                    Proceed to Final Checks
                </SharedButton>
            </div>
        </div>
    );
}

export default OnboardingAccountMapping;
