import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LastPaymentsTable from "./LastPaymentsTable";
import {
    SharedAlert,
    SharedButton,
    SharedBackIconButton,
} from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";
import CircleCheckSvg from "../../../../../assets/CircleCheck";
import { useGetCollectionsLastPaymentsCheckForProviderQuery } from "../../../../../redux/slices/apiV2";

function LastPaymentCheck({
    selectedPayments,
    setSelectedPayments,
    setCurrentTabIndex,
}) {
    const [allPaymentsUpdated, setAllPaymentsUpdated] = useState(false);
    const paymentIds = selectedPayments.map((payment) => payment.id);
    const { data: lastPaymentData } =
        useGetCollectionsLastPaymentsCheckForProviderQuery({
            providerId: selectedPayments[0].provider_id,
            params: { paymentIds },
        });

    // Disable the continue button until all payments have been updated
    useEffect(() => {
        if (lastPaymentData) {
            setAllPaymentsUpdated(
                // Array.every() returns true if the array is empty
                lastPaymentData.every((lastPayment) => {
                    const payment = selectedPayments.find(
                        (p) => p.id === lastPayment.payment_id
                    );
                    return (
                        lastPayment.draw_outstanding_balance_cents ===
                        payment.payment_amount
                    );
                })
            );
        } else {
            setAllPaymentsUpdated(false);
        }
    }, [lastPaymentData, selectedPayments]);

    const onClickContinue = () => {
        setCurrentTabIndex(2);
    };

    const onClickBack = () => {
        setCurrentTabIndex(0);
    };

    const renderProceedMessage = () => {
        if (lastPaymentData.length === 0) {
            return (
                <SharedAlert
                    icon={
                        <CircleCheckSvg fill="#00B894" width={18} height={18} />
                    }
                    message="No last payments detected. Please proceed."
                    borderColor="border-flychainGreen"
                />
            );
        }

        if (allPaymentsUpdated) {
            return (
                <SharedAlert
                    icon={
                        <CircleCheckSvg fill="#00B894" width={18} height={18} />
                    }
                    message="Payment amounts match outstanding draw balances for all final payments."
                    borderColor="border-flychainGreen"
                />
            );
        }

        return (
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#D32F2F" width={18} height={18} />
                }
                message="Payment amounts do not match outstanding draw balances for all final payments. Please update payments before proceeding."
                borderColor="border-flychainError"
            />
        );
    };

    if (!lastPaymentData) {
        return null;
    }

    return (
        <div className="flex flex-col gap-y-[30px] px-[30px] pb-[30px]">
            <div className="flex flex-row items-center gap-x-[20px] ">
                <SharedBackIconButton onClick={onClickBack} />
                <SharedSectionTitle>Last Payment Check</SharedSectionTitle>
            </div>
            <div className="flex flex-col gap-y-[20px]">
                {lastPaymentData.length > 0 && (
                    <div className="flex flex-col gap-y-[20px]">
                        <SharedAlert
                            icon={
                                <TriangleAlertSvg
                                    fill="#8D58FF"
                                    width={18}
                                    height={18}
                                />
                            }
                            message="The following payments have been identified as the last payment for their respective draws. Please review the payments below and update the payment amount if necessary."
                            borderColor="border-flychainPurple-light"
                        />
                        <LastPaymentsTable
                            selectedPayments={selectedPayments}
                            lastPaymentData={lastPaymentData}
                            setSelectedPayments={setSelectedPayments}
                        />
                    </div>
                )}
                {renderProceedMessage()}
                <SharedButton
                    onClick={onClickContinue}
                    disabled={!allPaymentsUpdated}
                >
                    Proceed to Balance Check
                </SharedButton>
            </div>
        </div>
    );
}

LastPaymentCheck.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setSelectedPayments: PropTypes.func.isRequired,
    setCurrentTabIndex: PropTypes.func.isRequired,
};

export default LastPaymentCheck;
