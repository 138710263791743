/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { getFinancialReportsValidUpUntil } from "../apiV2";
import resetProviderState from "../actions";

const getEndDateFromValidUpUntil = (state, action) => {
    if (state.endDate) {
        return state;
    }
    return {
        endDate: action.payload.valid_up_until,
    };
};

const initialState = {
    endDate: null,
};

const endDateSlice = createSlice({
    name: "endDate",
    initialState,
    reducers: {
        updateEndDate(state, action) {
            return { endDate: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                getFinancialReportsValidUpUntil.matchFulfilled,
                getEndDateFromValidUpUntil
            )
            .addMatcher(
                (action) => action.type === resetProviderState.type,
                () => initialState
            );
    },
});

export const selectEndDate = (state) => state.endDate.endDate;

export const { updateEndDate } = endDateSlice.actions;

export default endDateSlice.reducer;
