import React from "react";
import PropTypes from "prop-types";
import { PropelAuthChangeNameResultType } from "../../../../constants";
import CircleCheckSvg from "../../../../assets/CircleCheck";

export default function ChangeNameResultMessage({ changeNameResult }) {
    if (changeNameResult === PropelAuthChangeNameResultType.SUCCESS) {
        return (
            <div className="flex flex-row items-center justify-center gap-x-[5px]">
                <CircleCheckSvg width={15} height={15} fill="#00B894" />
                <div className="text-flychainGray">
                    Name updated successfully.
                </div>
            </div>
        );
    }

    // BAD_REQUEST, UNEXPECTED_OR_UNHANDLED
    return (
        <div className="text-[12px] text-flychainError text-wrap text-center">
            An unexpected error occurred. Please try again later.
        </div>
    );
}

ChangeNameResultMessage.propTypes = {
    changeNameResult: PropTypes.oneOf(
        Object.values(PropelAuthChangeNameResultType)
    ).isRequired,
};
