import React from "react";
import PropTypes from "prop-types";
import { SharedButton } from "../../shared";
import TaxPartnerSubCard from "./TaxPartnerSubcard";

function TaxPartnerCard({
    cardColor,
    partnerName,
    partnerLogo,
    partnerDescription,
    callToActionOnClick,
    subCards,
}) {
    return (
        <div
            className={`flex flex-col w-full rounded-[30px] py-[35px] px-[50px] gap-y-[40px] ${cardColor}`}
        >
            <div className="flex flex-row w-full justify-between items-center gap-x-[40px]">
                <div className="flex flex-row justify-start items-center gap-x-[20px]">
                    {partnerLogo}
                    <div className="flex flex-col justify-start items-start">
                        <div className="text-[22px] font-semibold">
                            {partnerName}
                        </div>
                        <div className="text-[16px] font-normal">
                            {partnerDescription}
                        </div>
                    </div>
                </div>
                <SharedButton onClick={callToActionOnClick}>
                    Schedule an intro call
                </SharedButton>
            </div>
            <div className="grid grid-cols-2 gap-[50px]">
                {subCards.map((subcard) => (
                    <TaxPartnerSubCard {...subcard} />
                ))}
            </div>
        </div>
    );
}

TaxPartnerCard.propTypes = {
    cardColor: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    partnerLogo: PropTypes.node.isRequired,
    partnerDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    callToActionOnClick: PropTypes.func.isRequired,
    subCards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TaxPartnerCard;
