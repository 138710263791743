/* eslint-disable camelcase */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import DrawsTablePlusButton from "./DrawsTablePlusButton";
import { SharedBadge, SharedTable } from "../../../../shared";

const HEADERS = [
    "Amount",
    "Fee",
    "Funding date",
    "Outstanding",
    "Next repayment",
    "See repayments",
];

const getBadgeColor = (nextRepayment) => {
    if (nextRepayment === "Fully repaid") {
        return "green";
    }
    return "gray";
};

function DrawsTable({ draws, includeSeeRepaymentsColumn, onClick }) {
    const headers = useMemo(() => {
        if (includeSeeRepaymentsColumn) {
            return HEADERS;
        }
        return HEADERS.slice(0, -1);
    }, [includeSeeRepaymentsColumn]);

    const rows = useMemo(
        () =>
            draws.map((draw, drawIndex) => {
                const plusButtonOnClick = () => {
                    onClick(drawIndex);
                };
                const row = [
                    `${getCurrencyString(draw.draw_principal_cents)}`,
                    `${getCurrencyString(draw.draw_fee_cents)}`,
                    draw.draw_funding_date,
                    `${getCurrencyString(draw.draw_outstanding_balance_cents)}`,
                    <SharedBadge
                        color={getBadgeColor(draw.draw_next_repayment)}
                    >
                        <div className="text-white">
                            {draw.draw_next_repayment}
                        </div>
                    </SharedBadge>,
                ];
                if (includeSeeRepaymentsColumn) {
                    row.push(
                        <div className="flex flex-row justify-center">
                            <DrawsTablePlusButton onClick={plusButtonOnClick} />
                        </div>
                    );
                }
                return row;
            }),
        [draws]
    );
    return <SharedTable headers={headers} rows={rows} />;
}

export const drawShape = PropTypes.shape({
    draw_id: PropTypes.string.isRequired,
    draw_principal_cents: PropTypes.number.isRequired,
    draw_fee_cents: PropTypes.number.isRequired,
    draw_funding_date: PropTypes.string.isRequired,
    draw_payments: PropTypes.arrayOf(
        PropTypes.shape({
            payment_id: PropTypes.string.isRequired,
            payment_amount_cents: PropTypes.number.isRequired,
            payment_status: PropTypes.string.isRequired,
            payment_collection_date: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    draw_outstanding_balance_cents: PropTypes.number.isRequired,
    draw_next_repayment: PropTypes.string.isRequired,
});

DrawsTable.propTypes = {
    draws: PropTypes.arrayOf(drawShape.isRequired).isRequired,
    includeSeeRepaymentsColumn: PropTypes.bool,
    onClick: PropTypes.func,
};

DrawsTable.defaultProps = {
    includeSeeRepaymentsColumn: false,
    onClick: () => {},
};

export default DrawsTable;
