/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import useGetProviderId from "../../../hooks/useGetProviderId";
import EntitiesValuedTooltip from "../EntitiesValuedTooltip";
import { SharedPageTitle } from "../../shared/Text";
import { SharedStateSelectInput } from "../../shared/Inputs";
import { useGetValuationForProviderQuery } from "../../../redux/slices/apiV2";
import SingleEntityValuationExplanation from "./SingleEntityValuationExplanation";

function ValuationExplanation() {
    const providerId = useGetProviderId();
    const { data: valuationData } = useGetValuationForProviderQuery({
        providerId,
    });
    const [valuationBusinessEntityId, setValuationBusinessEntityId] =
        useState(null);

    useEffect(() => {
        if (!valuationData || valuationData.valuations.length === 0) return;
        setValuationBusinessEntityId(
            valuationData.valuations[0].business_entity.id
        );
    }, [valuationData]);

    if (
        !valuationData ||
        valuationData.valuations.length === 0 ||
        !valuationBusinessEntityId
    )
        return null;

    const valuationEntityOptions = valuationData.valuations.map(
        (valuation) => ({
            label:
                valuation.business_entity.display_name ||
                valuation.business_entity.legal_name,
            value: valuation.business_entity.id,
        })
    );

    return (
        <div className="flex flex-col p-[60px] bg-white">
            <div className="flex flex-col gap-y-[40px] w-full">
                <div className="flex flex-row justify-center w-full gap-x-[60px]">
                    <SharedPageTitle className="w-[45%]">
                        How Do We Calculate Your Valuation Range?
                    </SharedPageTitle>
                    <div className="w-[45%] text-base font-normal">
                        Your practice&apos;s total Valuation Range is the sum of
                        the Valuation Ranges for each of your business entities.
                        The first step to increasing your total valuation is
                        understanding how each business entity&apos;s valuation
                        is calculated and what factors go into this calculation.
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center w-full gap-x-[50px]">
                    <SharedStateSelectInput
                        className="w-[calc(100%-400px)]"
                        name="valuationEntity"
                        label="Select a Business Entity"
                        options={valuationEntityOptions}
                        currentValue={valuationBusinessEntityId}
                        onChange={(value) =>
                            setValuationBusinessEntityId(value)
                        }
                    />
                    <EntitiesValuedTooltip
                        className="text-[14px] font-normal underline text-flychainPurple-light pt-[10px]"
                        valuationData={valuationData}
                    />
                </div>
                {valuationBusinessEntityId && (
                    <SingleEntityValuationExplanation
                        valuationEntity={valuationData.valuations.find(
                            (valuation) =>
                                valuationBusinessEntityId ===
                                valuation.business_entity.id
                        )}
                    />
                )}
            </div>
        </div>
    );
}

export default ValuationExplanation;
