import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { getCurrencyString, MMM_D_YYYY_FORMAT } from "../../../../../utils";
import {
    useGetCollectionsBalanceCheckDataForProviderQuery,
    useGetProviderQuery,
} from "../../../../../redux/slices/apiV2";
import BalanceCheckTable from "./BalanceCheckTable";
import {
    SharedAlert,
    SharedButton,
    SharedBackIconButton,
} from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";
import CircleCheckSvg from "../../../../../assets/CircleCheck";

const BALANCE_BUFFER = 0.8;

const getProviderBankAccountBalanceCents = (providerBankAccountData) => {
    const providerBankAccount = providerBankAccountData.provider_bank_account;
    const balance = providerBankAccount.balances.available;
    return Math.round(balance * 100);
};

const hasSufficientBalance = (providerBankAccountData, totalPaymentCents) => {
    const providerBankAccountBalanceCents = getProviderBankAccountBalanceCents(
        providerBankAccountData
    );
    return providerBankAccountBalanceCents >= totalPaymentCents;
};

const paymentsExceedBalanceBuffer = (
    providerBankAccountData,
    totalPaymentCents
) => {
    const providerBankAccountBalanceCents = getProviderBankAccountBalanceCents(
        providerBankAccountData
    );
    return totalPaymentCents > providerBankAccountBalanceCents * BALANCE_BUFFER;
};

const renderCheckMessage = (providerBankAccountData, totalPaymentsCents) => {
    if (Object.keys(providerBankAccountData).length === 0) {
        return (
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#D32F2F" width={18} height={18} />
                }
                message="No bank balance for provider! You're flying blind! Watch out!"
                borderColor="border-flychainError"
            />
        );
    }

    if (!hasSufficientBalance(providerBankAccountData, totalPaymentsCents)) {
        return (
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#D32F2F" width={18} height={18} />
                }
                message="The provider does not have sufficient balance to cover the collections."
                borderColor="border-flychainError"
            />
        );
    }

    if (
        paymentsExceedBalanceBuffer(providerBankAccountData, totalPaymentsCents)
    ) {
        return (
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#8D58FF" width={18} height={18} />
                }
                message={`The total collection amount exceeds ${
                    BALANCE_BUFFER * 100
                }% of the provider's bank balance. Proceed with caution.`}
                borderColor="border-flychainPurple-light"
            />
        );
    }

    return (
        <SharedAlert
            icon={<CircleCheckSvg fill="#00B894" width={18} height={18} />}
            message="The provider has sufficient balance to cover the collections."
            borderColor="border-flychainGreen"
        />
    );
};

function BalanceCheck({ selectedPayments, setCurrentTabIndex }) {
    // assumes there is at least one payment because of checks in Selection
    const { data: providerBankAccountData } =
        useGetCollectionsBalanceCheckDataForProviderQuery({
            providerId: selectedPayments[0].provider_id,
        });

    const { data: provider } = useGetProviderQuery({
        providerId: selectedPayments[0].provider_id,
    });

    const onClickContinue = () => {
        setCurrentTabIndex(3);
    };

    const onClickBack = () => {
        setCurrentTabIndex(1);
    };

    // add up all the payments
    const totalPaymentsCents = selectedPayments.reduce(
        (total, payment) => total + payment.payment_amount,
        0
    );

    if (!providerBankAccountData || !provider) {
        return null;
    }

    return (
        <div className="flex flex-col px-[30px] pb-[30px] gap-y-[40px]">
            <div className="flex flex-row items-center gap-x-[20px]">
                <SharedBackIconButton onClick={onClickBack} />
                <SharedSectionTitle>Bank Balance Check</SharedSectionTitle>
            </div>
            <div className="flex flex-col gap-y-[25px]">
                <div className="flex flex-row gap-x-[60px] px-[30px]">
                    <div className="flex flex-col gap-y-[10px] items-center w-fit">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Provider
                        </p>
                        <p className="font-semibold text-[24px]">
                            {provider.legal_name}
                        </p>
                    </div>
                    <div className="flex flex-col gap-y-[10px] items-center w-fit">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Payments Total
                        </p>
                        <p className="font-semibold text-[24px]">
                            {getCurrencyString(totalPaymentsCents)}
                        </p>
                    </div>
                    <div className="flex flex-col gap-y-[10px] items-center w-fit">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Bank Balance
                        </p>
                        <div className="flex flex-col items-center w-fit">
                            {Object.keys(providerBankAccountData).length > 0 ? (
                                <>
                                    <p className="font-semibold text-[24px]">
                                        {getCurrencyString(
                                            getProviderBankAccountBalanceCents(
                                                providerBankAccountData
                                            )
                                        )}
                                    </p>
                                    <p className="text-flychainGray">
                                        as of{" "}
                                        {dayjs(
                                            providerBankAccountData.fetch_date
                                        ).format(MMM_D_YYYY_FORMAT)}
                                    </p>
                                </>
                            ) : (
                                <p className="font-semibold text-[24px]">N/A</p>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-[10px] items-center w-fit">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Buffered Balance ({BALANCE_BUFFER * 100}%)
                        </p>
                        <div className="flex flex-col items-center w-fit">
                            {Object.keys(providerBankAccountData).length > 0 ? (
                                <>
                                    <p className="font-semibold text-[24px]">
                                        {getCurrencyString(
                                            getProviderBankAccountBalanceCents(
                                                providerBankAccountData
                                            ) * BALANCE_BUFFER
                                        )}
                                    </p>
                                    <p className="text-flychainGray">
                                        as of{" "}
                                        {dayjs(
                                            providerBankAccountData.fetch_date
                                        ).format(MMM_D_YYYY_FORMAT)}
                                    </p>
                                </>
                            ) : (
                                <p className="font-semibold text-[24px]">N/A</p>
                            )}
                        </div>
                    </div>
                </div>
                {renderCheckMessage(
                    providerBankAccountData,
                    totalPaymentsCents
                )}
                <BalanceCheckTable selectedPayments={selectedPayments} />
                <SharedButton onClick={onClickContinue}>
                    Proceed to Collection
                </SharedButton>
            </div>
        </div>
    );
}

BalanceCheck.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setCurrentTabIndex: PropTypes.func.isRequired,
};

export default BalanceCheck;
