import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import useGetProviderPath from "../../../hooks/useGetProviderPath";
import { SharedAlert, SharedButton, SharedCard } from "../../shared";
import { SharedPageTitle } from "../../shared/Text";
import SelectedProvider from "../FinancialReports/SelectedProvider";
import TriangleAlertSvg from "../../../assets/TriangleAlert";
import {
    useGetBusinessEntitiesQuery,
    useGetTealInstancesQuery,
    useCreateTealInstanceMutation,
} from "../../../redux/slices/apiV2";

function OnboardingBusinessEntities() {
    const [missingInstances, setMissingInstances] = useState(false);
    const providerId = useGetProviderId();
    const providerPath = useGetProviderPath();
    const navigate = useNavigate();
    const [createTealInstance] = useCreateTealInstanceMutation();
    const { data: tealInstances } = useGetTealInstancesQuery({
        providerId,
    });
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: true },
    });

    useEffect(() => {
        if (businessEntities && tealInstances) {
            setMissingInstances(
                businessEntities.some(
                    (businessEntity) =>
                        !tealInstances.find(
                            (tealInstance) =>
                                tealInstance.business_entity_id ===
                                businessEntity.id
                        )
                )
            );
        }
    }, [businessEntities, tealInstances]);

    if (!businessEntities || !tealInstances) {
        return null;
    }

    const navigateToBusinessEntities = () => {
        navigate(`${providerPath}/settings?tab=businessEntities`);
    };

    const navigateToAccountMapping = () => {
        navigate(`${providerPath}/onboard/accountMapping`);
    };

    const handleCreateTealInstance = (businessEntityId) => {
        createTealInstance({
            providerId,
            body: { business_entity_id: businessEntityId },
        });
    };

    const renderBusinessEntityTableRows = () =>
        businessEntities.map((businessEntity) => {
            const tealInstance = tealInstances.find(
                (instance) => instance.business_entity_id === businessEntity.id
            );
            return (
                <div
                    key={businessEntity.id}
                    className="grid grid-cols-[2fr_2fr_2fr_1.5fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{businessEntity.legal_name}</div>
                    <div>{businessEntity.id}</div>
                    {tealInstance ? (
                        <>
                            <div>{tealInstance.id}</div>
                            <div>{tealInstance.external_teal_instance_id}</div>
                        </>
                    ) : (
                        <>
                            <div className="flex font-semibold text-flychainError">
                                Instance Not Found
                            </div>
                            <SharedButton
                                className="rounded-full w-fit px-[10px] py-[5px]"
                                onClick={() =>
                                    handleCreateTealInstance(businessEntity.id)
                                }
                            >
                                Create Instance
                            </SharedButton>
                        </>
                    )}
                </div>
            );
        });

    return (
        <div>
            <SelectedProvider providerId={providerId} />
            <div className="flex flex-col px-[60px] pb-[60px] pt-[40px] gap-y-[30px]">
                <SharedPageTitle>Business Entities</SharedPageTitle>
                <SharedCard>
                    <div className="flex flex-col gap-y-[25px]">
                        <div className="flex flex-row items-center gap-x-[30px]">
                            <SharedAlert
                                icon={
                                    <TriangleAlertSvg
                                        fill="#8D58FF"
                                        width={18}
                                        height={18}
                                    />
                                }
                                message="If any business entities are missing, please navigate to the business entities settings tab to add them."
                                borderColor="border-flychainPurple-light"
                            />
                            <SharedButton
                                color="secondary"
                                onClick={navigateToBusinessEntities}
                            >
                                Provider Business Entities
                            </SharedButton>
                        </div>
                        <div className="flex flex-col">
                            <div className="grid grid-cols-[2fr_2fr_2fr_1.5fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                                <div>Legal Name</div>
                                <div>ID</div>
                                <div>Internal Teal ID</div>
                                <div>External Teal ID</div>
                            </div>
                            {renderBusinessEntityTableRows()}
                        </div>
                    </div>
                </SharedCard>
                {missingInstances && (
                    <SharedAlert
                        icon={
                            <TriangleAlertSvg
                                fill="#D32F2F"
                                width={18}
                                height={18}
                            />
                        }
                        message="Some of your business entities are missing Teal instances. Please create a Teal instance for each business entity before proceeding."
                        borderColor="border-flychainError"
                    />
                )}
                <SharedButton
                    onClick={navigateToAccountMapping}
                    disabled={missingInstances}
                >
                    Proceed to Account Mapping
                </SharedButton>
            </div>
        </div>
    );
}

export default OnboardingBusinessEntities;
