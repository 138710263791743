import React from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";
import ExportReportButton from "../../ExportReportButton";
import Section from "../../Section";
import Summary from "./Summary";
import { SharedUnverifiedDataWarningBanner } from "../../../shared";
import { SharedSectionTitle } from "../../../shared/Text";
import { SharedDateRangePicker } from "../../../shared/Dates";
import {
    updateDateRange,
    selectDateRange,
} from "../../../../redux/slices/dateRange";
import {
    useGetIncomeStatementQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../../redux/slices/apiV2";
import useValidateDateRange from "../../../../hooks/useValidateDateRange";
import useResetDateRangeIfInvalid from "../../../../hooks/useResetDateRangeIfInvalid";
import useGetProviderId from "../../../../hooks/useGetProviderId";

function Report() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    const { data: incomeStatementData } = useGetIncomeStatementQuery({
        providerId,
        businessEntityId,
        params: { ...dateRange },
    });
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    useResetDateRangeIfInvalid(entriesStartData, validUpUntilData);

    if (!incomeStatementData || !validUpUntilData || !entriesStartData)
        return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    const operatingRevenues = incomeStatementData.operating_revenues.record;

    const totalNetSales = incomeStatementData.total_net_sales.record;

    const costOfGoodsSold = incomeStatementData.cost_of_goods_sold.record;

    const grossProfitMargin = incomeStatementData.gross_profit.percentage;
    const grossProfit = incomeStatementData.gross_profit.record;

    const operatingExpenses = incomeStatementData.operating_expenses.record;

    const totalOperatingProfit =
        incomeStatementData.total_operating_profit.record;

    const otherExpenses = incomeStatementData.other_expenses.record;

    const otherIncome = incomeStatementData.other_income.record;

    const netProfitMargin = incomeStatementData.net_profit.percentage;
    const netProfit = incomeStatementData.net_profit.record;

    return (
        <div>
            <div className="mb-[20px]">
                <SharedSectionTitle>Income Statement Report</SharedSectionTitle>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-row justify-between pr-[20px] items-center">
                    <SharedDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                        minDate={entriesStartData.entries_start}
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                    <ExportReportButton />
                </div>
                {dateRangeUnvalidated && (
                    <SharedUnverifiedDataWarningBanner
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                )}
                <Summary />
                <div className="flex flex-col gap-y-[20px]">
                    <Section
                        name={operatingRevenues.name}
                        totalAmountCents={operatingRevenues.total_amount_cents}
                    >
                        {operatingRevenues.children}
                    </Section>
                    <Section
                        name="Total Net Sales"
                        totalAmountCents={totalNetSales.total_amount_cents}
                    />
                    <Section
                        name={costOfGoodsSold.name}
                        totalAmountCents={costOfGoodsSold.total_amount_cents}
                    >
                        {costOfGoodsSold.children}
                    </Section>
                    <Section
                        name={grossProfit.name}
                        totalAmountCents={grossProfit.total_amount_cents}
                        percentage={grossProfitMargin}
                        percentageTooltip={
                            <div>
                                <p>
                                    Gross Profit Margin = Gross Profit / Total
                                    Net Sales
                                </p>
                                <p>What does this mean?</p>
                                <p>
                                    For each dollar of revenue, how much is left
                                    after paying off Cost of Goods Sold (COGS).
                                </p>
                            </div>
                        }
                    />
                    <Section
                        name={operatingExpenses.name}
                        totalAmountCents={operatingExpenses.total_amount_cents}
                    >
                        {operatingExpenses.children}
                    </Section>
                    <Section
                        name="Total Operating Profit"
                        totalAmountCents={
                            totalOperatingProfit.total_amount_cents
                        }
                    />
                    <Section
                        name={otherExpenses.name}
                        totalAmountCents={otherExpenses.total_amount_cents}
                    >
                        {otherExpenses.children}
                    </Section>
                    <Section
                        name={otherIncome.name}
                        totalAmountCents={otherIncome.total_amount_cents}
                    >
                        {otherIncome.children}
                    </Section>
                    <Section
                        name={netProfit.name}
                        totalAmountCents={netProfit.total_amount_cents}
                        percentage={netProfitMargin}
                        percentageTooltip={
                            <div>
                                <p>
                                    Net Profit Margin = Net Profit / Total Net
                                    Sales
                                </p>
                                <p>What does this mean?</p>
                                <p>
                                    For each dollar of revenue, how much is left
                                    after all expenses are paid including COGS,
                                    Operating Expenses, and Other Expenses.
                                </p>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default Report;
