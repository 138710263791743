import React from "react";
import PropTypes from "prop-types";
import BusinessEntityPayments from "./BusinessEntityPayments";
import { SharedButton } from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import { useGetCollectionsAllUncollectedPaymentsQuery } from "../../../../../redux/slices/apiV2";

// TODO: delete mockPayment below once we're done testing CollectionFlow
// const mockPayment = {
//     id: "43f43386-643e-4900-b210-ea7a6a3081ed",
//     provider_id: "22780de7-6ec0-4737-8f12-7a5d62e8abde",
//     business_entity_id: "a7edc753-8bc2-49cc-8a03-ee7b07f66c71",
//     payment_amount: 100,
//     earliest_payment_date: "2025-01-01",
//     draw_id: "8fba68f8-9ae8-4af9-871b-81fc875acf1b",
// };

const paymentsBelongToOneProvider = (payments) =>
    payments.every(
        (payment) => payment.provider_id === payments[0].provider_id
    );

function Selection({
    selectedPayments,
    setSelectedPayments,
    setCurrentTabIndex,
}) {
    const { data: uncollectedPayments } =
        useGetCollectionsAllUncollectedPaymentsQuery();

    if (!uncollectedPayments) {
        return null;
    }

    // TODO: delete alternate paymentsByBusinessEntity below once we're done testing CollectionFlow
    // const paymentsByBusinessEntity = [
    //     ...tasks.uncollected_payments,
    //     mockPayment,
    // ].reduce((acc, payment) => {
    //     acc[payment.business_entity_id] = acc[payment.business_entity_id] || [];
    //     acc[payment.business_entity_id].push(payment);
    //     return acc;
    // }, {});

    const paymentsByBusinessEntity = uncollectedPayments.reduce(
        (acc, payment) => {
            acc[payment.business_entity_id] =
                acc[payment.business_entity_id] || [];
            acc[payment.business_entity_id].push(payment);
            return acc;
        },
        {}
    );

    const onClick = () => {
        setCurrentTabIndex(1);
    };

    return (
        <div className="flex flex-col px-[30px] pb-[30px] gap-y-[20px]">
            <SharedSectionTitle>Select Payments to Collect</SharedSectionTitle>
            <div className="flex flex-col gap-y-[20px]">
                {Object.keys(paymentsByBusinessEntity).map(
                    (businessEntityId) => (
                        <BusinessEntityPayments
                            key={businessEntityId}
                            providerId={
                                paymentsByBusinessEntity[businessEntityId][0]
                                    .provider_id
                            }
                            businessEntityId={businessEntityId}
                            businessEntityPayments={
                                paymentsByBusinessEntity[businessEntityId]
                            }
                            selectedPayments={selectedPayments}
                            setSelectedPayments={setSelectedPayments}
                        />
                    )
                )}
            </div>
            <div className="flex flex-row mt-[20px] gap-x-[20px] items-center">
                <SharedButton
                    onClick={onClick}
                    disabled={
                        selectedPayments.length === 0 ||
                        !paymentsBelongToOneProvider(selectedPayments)
                    }
                >
                    Proceed to Last Payment Check
                </SharedButton>
                <div className="font-semibold text-[16px]">
                    {selectedPayments.length} payments selected
                </div>
            </div>
        </div>
    );
}

Selection.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setSelectedPayments: PropTypes.func.isRequired,
    setCurrentTabIndex: PropTypes.func.isRequired,
};

export default Selection;
