import React, { useState } from "react";
import { SharedSupportModal } from "../shared";
import Content from "../Content";
import LogIn from "./LogIn";
import SignUp from "./SignUp";
import EmailConfirmation from "./EmailConfirmation";
import LoginRequired from "./LoginRequired";
import ForgotPassword from "./ForgotPassword";
import CircleHelpSvg from "../../assets/CircleHelp";
import { AuthPageEnums } from "../../constants";

const getInviteToken = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("invite_token") || undefined;
};

function LogInAndSignUp() {
    const inviteToken = getInviteToken();
    const [authPage, setAuthPage] = useState(
        inviteToken ? AuthPageEnums.SIGN_UP : AuthPageEnums.LOG_IN
    );
    const [confirmationEmail, setConfirmationEmail] = useState("");
    const [showSupportModal, setShowSupportModal] = useState(false);

    const renderAuthPage = () => {
        switch (authPage) {
            case AuthPageEnums.SIGN_UP:
                return (
                    <SignUp
                        setAuthPage={setAuthPage}
                        setConfirmationEmail={setConfirmationEmail}
                        inviteToken={inviteToken}
                    />
                );
            case AuthPageEnums.EMAIL_CONFIRMATION:
                return (
                    <EmailConfirmation
                        setAuthPage={setAuthPage}
                        confirmationEmail={confirmationEmail}
                        setConfirmationEmail={setConfirmationEmail}
                    />
                );
            case AuthPageEnums.FORGOT_PASSWORD:
                return (
                    <ForgotPassword
                        setAuthPage={setAuthPage}
                        confirmationEmail={confirmationEmail}
                        setConfirmationEmail={setConfirmationEmail}
                    />
                );
            case AuthPageEnums.LOGIN_REQUIRED:
                return <LoginRequired setAuthPage={setAuthPage} />;
            case AuthPageEnums.LOG_IN:
            default:
                return (
                    <LogIn
                        setAuthPage={setAuthPage}
                        setConfirmationEmail={setConfirmationEmail}
                    />
                );
        }
    };

    return (
        <Content backgroundColor="bg-flychainPurple-light">
            <div
                className="absolute top-[5px] right-[12px] flex flex-row items-center cursor-pointer h-[30px] gap-x-[5px] self-end mb-4"
                onClick={() => setShowSupportModal(true)}
            >
                <CircleHelpSvg width={15} height={15} fill="#ffffff" />
                <div className="text-[14px] font-[400] text-white">Help</div>
            </div>
            {showSupportModal && (
                <SharedSupportModal
                    onClose={() => setShowSupportModal(false)}
                />
            )}
            {renderAuthPage()}
        </Content>
    );
}

export default LogInAndSignUp;
