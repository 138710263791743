import CAPITAL_PRODUCT_PATHS from "../Capital/CapitalProducts/constants";

export const financialReportsSubtabs = [
    {
        label: "Income Statement",
        path: "/incomeStatement",
    },
    {
        label: "Cash Flow",
        path: "/cashFlow",
    },
    {
        label: "Balance Sheet",
        path: "/balanceSheet",
    },
    {
        label: "Revenue",
        path: "/revenue",
    },
    {
        label: "Expenses",
        path: "/expenses",
    },
];

export const capitalSubtabs = [
    {
        label: "Advanced Claims",
        path: `/${CAPITAL_PRODUCT_PATHS.ADVANCED_PAYMENT_ON_CLAIMS}`,
    },
    {
        label: "Term Loan",
        path: `/${CAPITAL_PRODUCT_PATHS.TERM_LOAN}`,
    },
    {
        label: "SBA",
        path: `/${CAPITAL_PRODUCT_PATHS.SBA_PROGRAMS}`,
    },
];

export const onboardingFlowSubtabs = [
    {
        label: "Business Entities",
        path: "/businessEntities",
    },
    {
        label: "Account Mapping",
        path: "/accountMapping",
    },
    {
        label: "Final Steps",
        path: "/finalSteps",
    },
];

export const partnerServicesSubtabs = [
    {
        label: "Taxes",
        path: "/taxes",
    },
    {
        label: "Banking",
        path: "/banking",
    },
    {
        label: "Treasury",
        path: "/treasury",
    },
];
