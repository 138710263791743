import React from "react";
import { useNavigate } from "react-router-dom";
import { SharedButton, SharedModal } from "../../shared";
import useGetProviderId from "../../../hooks/useGetProviderId";

function OfferConfirmationModal() {
    const providerId = useGetProviderId();
    const navigate = useNavigate();

    const onClose = () => {
        if (providerId) {
            navigate("/draws?tab=viewOffers");
        } else {
            navigate("/capital");
        }
    };

    return (
        <SharedModal cardClassName="w-[500px]" onClose={onClose}>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-col gap-y-[10px]">
                    <p className="text-flychainPurple-light text-[20px] font-semibold">
                        Success!
                    </p>
                    <p className="text-flychainGray text-[16px]">
                        Your offer has been confirmed. We will send you an email
                        within the next few hours once your funding has been
                        processed.
                    </p>
                </div>
                <SharedButton onClick={onClose}>Done</SharedButton>
            </div>
        </SharedModal>
    );
}

export default OfferConfirmationModal;
