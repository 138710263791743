export const OfferStatus = Object.freeze({
    UNCONFIRMED: "UNCONFIRMED",
    CONFIRMED: "CONFIRMED",
    UNFUNDED: "UNFUNDED",
    FUNDED: "FUNDED",
});

export const OfferType = Object.freeze({
    INSTALLMENTS: "INSTALLMENTS",
    SINGLE_PAYMENT: "SINGLE_PAYMENT",
});

export const ProviderPlatformState = Object.freeze({
    PROVIDER_CREATED: "PROVIDER_CREATED",
    PLAID_CONNECTED: "PLAID_CONNECTED",
    PLATFORM_PENDING: "PLATFORM_PENDING",
    PLATFORM_ACTIVE: "PLATFORM_ACTIVE",
});

export const PropelAuthLoginErrorType = Object.freeze({
    INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
    USER_ACCOUNT_DISABLED: "USER_ACCOUNT_DISABLED",
    USER_ACCOUNT_LOCKED: "USER_ACCOUNT_LOCKED",
    BAD_REQUEST: "BAD_REQUEST",
    UNEXPECTED_OR_UNHANDLED: "UNEXPECTED_OR_UNHANDLED",
    SIGNUP_DISABLED: "SIGNUP_DISABLED",
    PASSWORD_TOO_COMMON: "PASSWORD_TOO_COMMON",
});

export const PropelAuthSignUpErrorType = Object.freeze({
    SIGNUP_DISABLED: "SIGNUP_DISABLED",
    BAD_REQUEST: "BAD_REQUEST",
    UNEXPECTED_OR_UNHANDLED: "UNEXPECTED_OR_UNHANDLED",
});

export const PropelAuthResendEmailStatus = Object.freeze({
    SUCCESS: "SUCCESS",
    RATE_LIMITED: "RATE_LIMITED",
    EMAIL_ALREADY_CONFIRMED: "EMAIL_ALREADY_CONFIRMED",
    BAD_REQUEST: "BAD_REQUEST",
    UNEXPECTED_OR_UNHANDLED: "UNEXPECTED_OR_UNHANDLED",
});

export const PropelAuthLoginState = Object.freeze({
    LOGGED_IN: "LoggedIn",
    FINISHED: "Finished",
    CONFIRM_EMAIL_REQUIRED: "ConfirmEmailRequired",
    LOGIN_REQUIRED: "LoginRequired",
});

export const PropelAuthSignUpBadRequestErrors = Object.freeze({
    COMMON_PASSWORD: "common_password",
    EMAIL_TAKEN: "email_taken",
});

export const AuthPageEnums = Object.freeze({
    LOG_IN: "LOG_IN",
    SIGN_UP: "SIGN_UP",
    EMAIL_CONFIRMATION: "EMAIL_CONFIRMATION",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    LOGIN_REQUIRED: "LOGIN_REQUIRED",
});

export const PropelAuthChangeEmailErrorType = Object.freeze({
    FAILED_TO_SEND_EMAIL: "FAILED_TO_SEND_EMAIL",
    INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
    USER_ACCOUNT_LOCKED: "USER_ACCOUNT_LOCKED",
    RATE_LIMIT: "RATE_LIMIT",
    EMAIL_CHANGE_DISABLED: "EMAIL_CHANGE_DISABLED",
    ORG_MEMBERSHIP: "ORG_MEMBERSHIP",
    UNEXPECTED_OR_UNHANDLED: "UNEXPECTED_OR_UNHANDLED",
    BAD_REQUEST: "BAD_REQUEST",
});

export const PropelAuthChangePasswordErrorType = Object.freeze({
    INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
    USER_ACCOUNT_LOCKED: "USER_ACCOUNT_LOCKED",
    UNEXPECTED_OR_UNHANDLED: "UNEXPECTED_OR_UNHANDLED",
    BAD_REQUEST: "BAD_REQUEST",
});

export const PropelAuthChangeNameResultType = Object.freeze({
    SUCCESS: "SUCCESS",
    BAD_REQUEST: "BAD_REQUEST",
    UNEXPECTED_OR_UNHANDLED: "UNEXPECTED_OR_UNHANDLED",
});

export const PropelAuthNameChangeBadRequestErrors = Object.freeze({
    FIRST_NAME_LENGTH: "first_name_length",
    LAST_NAME_LENGTH: "last_name_length",
});

export const PropelAuthOrgRoles = Object.freeze({
    OWNER: "Owner",
    ADMIN: "Admin",
    MEMBER: "Member",
});

export const PropelAuthOrgPermissions = Object.freeze({
    CAN_INVITE: "propelauth::can_invite",
    CAN_CHANGE_ROLES: "propelauth::can_change_roles",
    CAN_REMOVE_USERS: "propelauth::can_remove_users",
});
