import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useGetProviderId from "../../../hooks/useGetProviderId";
import SelectBusinessEntity from "../../SelectBusinessEntity";
import LiveAdvancedPaymentOnClaimsLanding from "./LiveAdvancedPaymentOnClaimsLanding";
import BasicLanding from "./BasicLanding";
import {
    useGetBusinessEntitiesQuery,
    useGetAdvancedPaymentOnClaimsLineForBusinessEntityQuery,
} from "../../../redux/slices/apiV2";

function AdvancedPaymentLandingRouter({ businessEntityId, providerId }) {
    // isFetching was required here because we noticed that when switching business entity state,
    // the old line was in memory for a split second and being used before the new line was fetching
    // this was causing errors when the new entity had no line
    // TODO: may want to do on other requests that have dynamic state (isFetching)
    const { data: line, isFetching: isFetchingLine } =
        useGetAdvancedPaymentOnClaimsLineForBusinessEntityQuery({
            providerId,
            businessEntityId,
        });

    if (isFetchingLine || !line) {
        return null;
    }

    if (line.id) {
        return (
            <LiveAdvancedPaymentOnClaimsLanding
                businessEntityId={businessEntityId}
                // TODO: remove this once noble house is cleaned up
                // it should be line.business_entity_id
            />
        );
    }

    return <BasicLanding />;
}

AdvancedPaymentLandingRouter.propTypes = {
    businessEntityId: PropTypes.string.isRequired,
    providerId: PropTypes.string.isRequired,
};

function AdvancedPaymentOnClaims() {
    const providerId = useGetProviderId();
    const [businessEntityId, setBusinessEntityId] = useState(null);
    const { data: businessEntities, isFetching: isFetchingBusinessEntities } =
        useGetBusinessEntitiesQuery({
            providerId,
            params: { includeIncomplete: true },
        });

    useEffect(() => {
        if (businessEntities && businessEntities.length > 0) {
            setBusinessEntityId(businessEntities[0].id);
        }
    }, [businessEntities]);

    if (isFetchingBusinessEntities || !businessEntities || !businessEntityId) {
        return null;
    }

    const onBusinessEntityChange = (option) => {
        setBusinessEntityId(option.value);
    };

    return (
        <div>
            {businessEntities.length > 1 && (
                <SelectBusinessEntity
                    businessEntities={businessEntities}
                    businessEntityId={businessEntityId}
                    onChange={onBusinessEntityChange}
                />
            )}
            <AdvancedPaymentLandingRouter
                businessEntityId={businessEntityId}
                providerId={providerId}
            />
        </div>
    );
}

export default AdvancedPaymentOnClaims;
