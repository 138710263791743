import React from "react";
import PropTypes from "prop-types";
import { PropelAuthChangeEmailErrorType } from "../../../../constants";

export default function ChangeEmailError({ errorType, setContactSupport }) {
    if (errorType === PropelAuthChangeEmailErrorType.USER_ACCOUNT_LOCKED) {
        return (
            <div className="text-[12px] text-flychainError text-wrap">
                Your account is locked. Please{" "}
                <span
                    className="underline cursor-pointer text-flychainPurple-light"
                    onClick={() => setContactSupport(true)}
                >
                    contact support
                </span>
                .
            </div>
        );
    }

    if (errorType === PropelAuthChangeEmailErrorType.RATE_LIMIT) {
        return (
            <div className="text-[12px] text-flychainError text-wrap">
                You have made too many requests. Please try again later.
            </div>
        );
    }

    if (errorType === PropelAuthChangeEmailErrorType.EMAIL_CHANGE_DISABLED) {
        return (
            <div className="text-[12px] text-flychainError text-wrap">
                Email change is disabled. Please{" "}
                <span
                    className="underline cursor-pointer text-flychainPurple-light"
                    onClick={() => setContactSupport(true)}
                >
                    contact support
                </span>
                .
            </div>
        );
    }

    if (errorType === PropelAuthChangeEmailErrorType.ORG_MEMBERSHIP) {
        return (
            <div className="text-[12px] text-flychainError text-wrap">
                Your organization does not allow email changes. Please{" "}
                <span
                    className="underline cursor-pointer text-flychainPurple-light"
                    onClick={() => setContactSupport(true)}
                >
                    contact support
                </span>
                .
            </div>
        );
    }

    // FAILED_TO_SEND_EMAIL, BAD_REQUEST, UNEXPECTED_OR_UNHANDLED
    return (
        <div className="text-[12px] text-flychainError text-wrap">
            An unexpected error occurred. Please try again later.
        </div>
    );
}

ChangeEmailError.propTypes = {
    errorType: PropTypes.oneOf(Object.values(PropelAuthChangeEmailErrorType))
        .isRequired,
    setContactSupport: PropTypes.func.isRequired,
};
