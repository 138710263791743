import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DatePicker from "../DatePicker";
import ShortcutsButton from "./ShortcutsButton";

function DateRangePicker({
    dateRange,
    onChange,
    maxDate,
    minDate,
    validUpUntil = maxDate,
}) {
    const [currentDateRange, setCurrentDateRange] = useState({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    });

    useEffect(() => {
        setCurrentDateRange({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        });
    }, [dateRange]);

    const updateDateRange = (newDateRange) => {
        setCurrentDateRange(newDateRange);
        onChange(newDateRange);
    };

    const handleStartDateChange = (newStartDate) => {
        if (dayjs(newStartDate).isAfter(dayjs(currentDateRange.endDate))) {
            updateDateRange({
                startDate: newStartDate,
                endDate: newStartDate,
            });
        } else {
            updateDateRange({
                startDate: newStartDate,
                endDate: currentDateRange.endDate,
            });
        }
    };

    const handleEndDateChange = (newEndDate) => {
        if (dayjs(newEndDate).isBefore(dayjs(currentDateRange.startDate))) {
            updateDateRange({
                startDate: newEndDate,
                endDate: newEndDate,
            });
        } else {
            updateDateRange({
                startDate: currentDateRange.startDate,
                endDate: newEndDate,
            });
        }
    };

    return (
        <div className="flex flex-row items-center">
            <DatePicker
                date={currentDateRange.startDate}
                onChange={handleStartDateChange}
                maxDate={maxDate}
                minDate={minDate}
                validUpUntil={validUpUntil}
            />
            <span className="text-[24px] font-[300] mx-[10px] cursor-default">
                -
            </span>
            <DatePicker
                date={currentDateRange.endDate}
                onChange={handleEndDateChange}
                maxDate={maxDate}
                minDate={minDate}
                validUpUntil={validUpUntil}
            />
            <ShortcutsButton
                className="ml-[12px]"
                dateRange={dateRange}
                onChange={updateDateRange}
                maxDate={maxDate}
                minDate={minDate}
                validUpUntil={validUpUntil}
            />
        </div>
    );
}

DateRangePicker.propTypes = {
    dateRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    minDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
};

DateRangePicker.defaultProps = {
    validUpUntil: undefined,
};

export default DateRangePicker;
