import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function FormTextInput({
    name,
    label,
    control,
    error,
    type,
    placeholder,
    className,
    disabled,
}) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const getInputType = () => {
        if (type === "password") {
            return showPassword ? "text" : "password";
        }
        return type;
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                    className={className}
                    disabled={disabled}
                    variant="standard"
                    type={getInputType()}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                    label={label}
                    error={error.message !== undefined}
                    helperText={error.message}
                    placeholder={placeholder}
                    InputProps={{
                        endAdornment: type === "password" && (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        ),
                    }}
                    sx={{
                        "& .MuiInput-underline:after": {
                            borderBottomColor: "#8D58FF",
                        },
                    }}
                    InputLabelProps={{
                        sx: {
                            "&.Mui-focused": {
                                color: "#8D58FF",
                            },
                        },
                    }}
                />
            )}
        />
    );
}

FormTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    control: PropTypes.object.isRequired,
    type: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

FormTextInput.defaultProps = {
    type: "text",
    error: {},
    placeholder: "",
    label: undefined,
    className: "",
    disabled: false,
};

export default FormTextInput;
