import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import { useAuthInfo } from "@propelauth/react";
import {
    PropelAuthLoginErrorType,
    AuthPageEnums,
    PropelAuthLoginState,
} from "../../../constants";
import { SharedCard, SharedButton } from "../../shared";
import { SharedStateTextInput } from "../../shared/Inputs";
import LogInError from "./LogInError";
import FlychainLogoSvg from "../../../assets/FlychainLogo.svg";

function LogIn({ setAuthPage, setConfirmationEmail }) {
    const authInfo = useAuthInfo();
    const { emailPasswordLogin } = useAuthFrontendApis();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorType, setErrorType] = useState(null);

    const handleSuccessResponse = async (response) => {
        if (
            response.data.login_state ===
            PropelAuthLoginState.CONFIRM_EMAIL_REQUIRED
        ) {
            setConfirmationEmail(email);
            setAuthPage(AuthPageEnums.EMAIL_CONFIRMATION);
        } else if (
            [
                PropelAuthLoginState.LOGGED_IN,
                PropelAuthLoginState.FINISHED,
            ].includes(response.data.login_state)
        ) {
            await authInfo.refreshAuthInfo();
        } else {
            throw new Error(`Unexpected login response: ${response}`);
        }
    };

    const handleErrorResponse = (newErrorType, error) => {
        if (
            newErrorType !== PropelAuthLoginErrorType.INVALID_CREDENTIALS &&
            error
        ) {
            Sentry.captureException(new Error(JSON.stringify(error)));
        }
        setErrorType(newErrorType);
    };

    const handleLogin = async () => {
        setIsLoading(true);
        setErrorType(null);
        const response = await emailPasswordLogin({ email, password });
        response.handle({
            success: async () => {
                await handleSuccessResponse(response);
                setIsLoading(false);
            },
            invalidCredentials: () => {
                handleErrorResponse(
                    PropelAuthLoginErrorType.INVALID_CREDENTIALS
                );
                setIsLoading(false);
            },
            userAccountDisabled: (error) => {
                handleErrorResponse(
                    PropelAuthLoginErrorType.USER_ACCOUNT_DISABLED,
                    error
                );
                setIsLoading(false);
            },
            userAccountLocked: (error) => {
                handleErrorResponse(
                    PropelAuthLoginErrorType.USER_ACCOUNT_LOCKED,
                    error
                );
                setIsLoading(false);
            },
            badRequest: (error) => {
                handleErrorResponse(
                    PropelAuthLoginErrorType.BAD_REQUEST,
                    error
                );
                setIsLoading(false);
            },
            unexpectedOrUnhandled: (error) => {
                handleErrorResponse(
                    PropelAuthLoginErrorType.UNEXPECTED_OR_UNHANDLED,
                    error
                );
                setIsLoading(false);
            },
        });
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <SharedCard className="w-[400px] bg-white">
                <div className="flex flex-col justify-center items-center gap-y-[20px] ">
                    <img
                        src={FlychainLogoSvg}
                        alt="Flychain Logo"
                        className="w-[160px]"
                    />
                    <div className="flex flex-col w-full gap-y-[10px] items-center">
                        <SharedStateTextInput
                            className="w-full"
                            name="email"
                            label="Email"
                            value={email}
                            onChange={setEmail}
                        />
                        <SharedStateTextInput
                            className="w-full"
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={setPassword}
                        />
                    </div>
                    {errorType && (
                        <LogInError
                            errorType={errorType}
                            email={email}
                            setAuthPage={setAuthPage}
                            setConfirmationEmail={setConfirmationEmail}
                        />
                    )}
                    <SharedButton
                        onClick={handleLogin}
                        disabled={isLoading}
                        loadingText={isLoading ? "Logging in..." : undefined}
                    >
                        Log In
                    </SharedButton>
                    <div className="flex flex-row items-center gap-x-[8px] mt-[10px]">
                        <div className="text-[16px] cursor-default text-flychainGray">
                            Don&apos;t have an account?
                        </div>
                        <div
                            onClick={() => setAuthPage(AuthPageEnums.SIGN_UP)}
                            className="underline text-[16px] cursor-pointer text-flychainPurple-light font-semibold"
                        >
                            Sign Up
                        </div>
                    </div>
                </div>
            </SharedCard>
        </div>
    );
}

LogIn.propTypes = {
    setAuthPage: PropTypes.func.isRequired,
    setConfirmationEmail: PropTypes.func.isRequired,
};

export default LogIn;
