import { useSelector } from "react-redux";
import { selectActiveOrg } from "../../redux/slices/authentication";

const useIsAdminDashboard = () => {
    const activeOrg = useSelector(selectActiveOrg);
    if (!activeOrg) return false;
    return activeOrg.orgMetadata.platform_admin === "true";
};

export default useIsAdminDashboard;
