import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import REPORT_TYPE from "../../constants";
import LinesTable from "./LinesTable";
import { getCurrencyString, YYYY_MM_DD_FORMAT } from "../../../../utils";
import RecategorizeTransactions from "./RecategorizeTransactions";
import ExportReportButton from "../../ExportReportButton";
import { SharedUnverifiedDataWarningBanner } from "../../../shared";
import { SharedLabeledText, SharedLabel } from "../../../shared/Text";
import { SharedDateRangePicker, SharedDatePicker } from "../../../shared/Dates";
import useGetReportType from "../../../../hooks/useGetReportType";
import {
    selectDateRange,
    updateDateRange,
} from "../../../../redux/slices/dateRange";
import { selectEndDate, updateEndDate } from "../../../../redux/slices/endDate";
import {
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../../redux/slices/apiV2";
import useValidateDateRange from "../../../../hooks/useValidateDateRange";

function Lines({ ledgerLinesData }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const reportType = useGetReportType();
    const dateRange = useSelector(selectDateRange);
    const endDate = useSelector(selectEndDate);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };
    const setEndDate = (newEndDate) => {
        dispatch(updateEndDate(newEndDate));
    };

    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    if (!validUpUntilData || !entriesStartData) return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    const label =
        ledgerLinesData.ledger_name === "No data available for this date range"
            ? ""
            : `${ledgerLinesData.ledger_name} Total`;

    return (
        <div className="flex flex-col gap-y-[30px]">
            <div className="flex flex-col gap-y-[20px]">
                {reportType === REPORT_TYPE.BALANCE_SHEET ? (
                    <div className="flex flex-col gap-y-[3px] w-fit">
                        <SharedLabel>End Date</SharedLabel>
                        <SharedDatePicker
                            date={endDate}
                            onChange={setEndDate}
                            maxDate={validUpUntilData.valid_up_until}
                            minDate={entriesStartData.entries_start}
                        />
                    </div>
                ) : (
                    <SharedDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                        minDate={entriesStartData.entries_start}
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                )}
                {dateRangeUnvalidated &&
                    reportType !== REPORT_TYPE.BALANCE_SHEET && (
                        <SharedUnverifiedDataWarningBanner
                            validUpUntil={validUpUntilData.valid_up_until}
                        />
                    )}
                <div className="flex flex-row justify-between items-center">
                    <SharedLabeledText
                        label={label}
                        text={getCurrencyString(
                            ledgerLinesData.ledger_amount_cents
                        )}
                    />
                    {
                        // TODO: remove the casing below once we implement export for revenue by category
                    }
                    {![
                        REPORT_TYPE.REVENUE_BY_CATEGORY,
                        REPORT_TYPE.EXPENSES_BY_CATEGORY,
                    ].includes(reportType) && <ExportReportButton />}
                </div>
            </div>
            <RecategorizeTransactions />
            <LinesTable lines={ledgerLinesData.ledger_lines} />
        </div>
    );
}

Lines.propTypes = {
    ledgerLinesData: PropTypes.object.isRequired,
};

export default Lines;
