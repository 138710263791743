import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as Sentry from "@sentry/react";
import timezone from "dayjs/plugin/timezone";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { buildDestinations, BUILD_DESTINATION } from "../../buildDestination";
import { setAuthErrorOccurred } from "./authentication";
import { getCurrentUserId } from "../../mixpanel";
// Initialize the dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const prepareHeaders = (headers, accessToken) => {
    headers.set("authorization", `Bearer ${accessToken}`);
    headers.set("Client-Timezone", "UTC"); // TODO: Figure out a strategy to handle dayjs.tz.guess()
    headers.set("X-Frontend-Version", process.env.REACT_APP_VERSION);
    headers.set("X-Mixpanel-Distinct-Id", getCurrentUserId()); // link fe and be mixpanel distinct id
    return headers;
};

const createBaseQuery = () => {
    const reactAppApiUrl =
        BUILD_DESTINATION === buildDestinations.STAGING
            ? process.env.REACT_APP_STAGING_API_URL
            : process.env.REACT_APP_API_URL;
    const baseUrl = `${reactAppApiUrl}/api`;

    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const { accessToken } = getState().authentication;
            // We don't need an access token for the sendUserCreatedEmail endpoint as the user is not logged in yet
            if (!accessToken && endpoint !== "sendUserCreatedEmail") {
                throw new Error("No access token found");
            }
            return prepareHeaders(headers, accessToken);
        },
    });

    return async (args, api, extraOptions) => {
        const result = await baseQuery(args, api, extraOptions);

        // Check for auth errors (401 Unauthorized or 403 Forbidden)
        if (
            result.error &&
            (result.error.status === 401 || result.error.status === 403)
        ) {
            Sentry.captureException(
                new Error(`Auth error: ${result.error.status}`)
            );
            // Dispatch the auth error action
            api.dispatch(setAuthErrorOccurred());
        }

        return result;
    };
};

export default createBaseQuery;
