import { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { YYYY_MM_DD_FORMAT } from "../../utils";
import { updateDateRange, selectDateRange } from "../../redux/slices/dateRange";

function useResetDateRangeIfInvalid(entriesStartData, validUpUntilData) {
    const dispatch = useDispatch();
    const dateRange = useSelector(selectDateRange);

    useEffect(() => {
        if (entriesStartData && validUpUntilData) {
            const parsedStartDate = dayjs(dateRange.startDate);
            const parsedEndDate = dayjs(dateRange.endDate);
            const parsedEntriesStart = dayjs(entriesStartData.entries_start);
            if (
                parsedStartDate.isBefore(parsedEntriesStart, "day") ||
                parsedEndDate.isBefore(parsedEntriesStart, "day")
            ) {
                const defaultDateRange = {
                    startDate: dayjs(validUpUntilData.valid_up_until)
                        .startOf("month")
                        .format(YYYY_MM_DD_FORMAT),
                    endDate: validUpUntilData.valid_up_until,
                };
                dispatch(updateDateRange(defaultDateRange));
            }
        }
    }, [entriesStartData, validUpUntilData, dateRange]);
}

export default useResetDateRangeIfInvalid;
