import React from "react";
import PropTypes from "prop-types";
import SharedModal from "../Modal";
import { SharedPageTitle, SharedSubheading } from "../Text";

function SupportModal({ onClose }) {
    return (
        <SharedModal onClose={onClose} cardClassName="w-fit">
            <div className="flex flex-col justify-start gap-y-[20px]">
                <SharedPageTitle className="text-flychainPurple-light">
                    Contact us
                </SharedPageTitle>
                <div className="flex flex-col justify-start gap-y-[5px]">
                    <SharedSubheading>
                        If you need any help, please don&apos;t hesitate to
                        reach out to{" "}
                        <a
                            href="mailto:jaime@flychain.us"
                            className="underline text-flychainPurple-light"
                        >
                            jaime@flychain.us
                        </a>
                        .
                    </SharedSubheading>
                    <SharedSubheading>
                        We&apos;ll get back to you as soon as possible.
                    </SharedSubheading>
                </div>
            </div>
        </SharedModal>
    );
}

SupportModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SupportModal;
