import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SharedButton } from "../../../../../shared";
import ChevronDownSvg from "../../../../../../assets/ChevronDown";
import PlusSvg from "../../../../../../assets/Plus";
import EyeSvg from "../../../../../../assets/Eye";
import { useCreateDrawAndOatfiOfferMutation } from "../../../../../../redux/slices/apiV2";

function ConfirmedOfferActions({ offer }) {
    const navigate = useNavigate();
    const [createDrawAndOatfiOffer] = useCreateDrawAndOatfiOfferMutation();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        if (event && event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateDraw = () => {
        createDrawAndOatfiOffer({
            providerId: offer.provider_id,
            businessEntityId: offer.business_entity_id,
            offerId: offer.id,
        });
    };

    const reviewOffer = () => {
        navigate(
            `/provider/${offer.provider_id}/businessEntity/${offer.business_entity_id}/capital/offer/${offer.id}`
        );
    };

    return (
        <div className="flex justify-center w-full">
            <SharedButton
                className="rounded-[6px] px-[10px] py-[8px]"
                onClick={handleClick}
            >
                <div className="flex flex-row items-center gap-[5px] ">
                    Actions
                    <ChevronDownSvg fill="#FFFFFF" width={20} height={20} />
                </div>
            </SharedButton>
            <Menu
                id="admin-actions"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "&:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
            >
                <MenuItem
                    key="create_draw"
                    value="create_draw"
                    className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
                    onClick={handleCreateDraw}
                >
                    <PlusSvg fill="currentColor" width={16} height={16} />
                    Create Draw
                </MenuItem>
                <MenuItem
                    key="review_offer"
                    value="review_offer"
                    className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
                    onClick={reviewOffer}
                >
                    <EyeSvg fill="currentColor" width={16} height={16} />
                    Review Offer
                </MenuItem>
            </Menu>
        </div>
    );
}

ConfirmedOfferActions.propTypes = {
    offer: PropTypes.object.isRequired,
};

export default ConfirmedOfferActions;
