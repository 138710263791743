const REPORT_TYPE = {
    BALANCE_SHEET: "BALANCE_SHEET",
    INCOME_STATEMENT: "INCOME_STATEMENT",
    CASH_FLOW_REPORT: "CASH_FLOW_REPORT",
    EXPENSES_BY_CATEGORY: "EXPENSES_BY_CATEGORY",
    REVENUE_BY_CATEGORY: "REVENUE_BY_CATEGORY",
    TRIAL_BALANCE: "TRIAL_BALANCE",
    GENERAL_LEDGER: "GENERAL_LEDGER",
};

export default REPORT_TYPE;

export const GRAPH_PERIOD = {
    LAST_12_MONTHS: "LAST_12_MONTHS",
};
