import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import { selectActiveOrg } from "../../../../redux/slices/authentication";
import { PropelAuthOrgRoles } from "../../../../constants";
import {
    useInviteUserToProviderOrgMutation,
    useGetProviderQuery,
} from "../../../../redux/slices/apiV2";
import { SharedModal, SharedButton } from "../../../shared";
import {
    SharedFormTextInput,
    SharedFormSelectInput,
    SharedRequiredMessage,
} from "../../../shared/Inputs";
import FlychainLogoSvg from "../../../../assets/FlychainLogo.svg";

export default function InviteUserModal({
    providerId,
    setShowInviteUserModal,
}) {
    const isAdminDashboard = useIsAdminDashboard();
    const activeOrg = useSelector(selectActiveOrg);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const { data: provider } = useGetProviderQuery({ providerId });
    const [inviteUserToProviderOrg] = useInviteUserToProviderOrgMutation();

    const getRoleOptions = () => {
        if (
            isAdminDashboard ||
            activeOrg.userAssignedRole === PropelAuthOrgRoles.OWNER
        ) {
            return [
                PropelAuthOrgRoles.OWNER,
                PropelAuthOrgRoles.ADMIN,
                PropelAuthOrgRoles.MEMBER,
            ];
        }

        if (activeOrg.userAssignedRole === PropelAuthOrgRoles.ADMIN) {
            return [PropelAuthOrgRoles.ADMIN, PropelAuthOrgRoles.MEMBER];
        }

        return [PropelAuthOrgRoles.MEMBER];
    };

    const defaultValues = {
        email: "",
        role: PropelAuthOrgRoles.MEMBER,
    };

    const schema = yup.object({
        email: yup.string().email().required(SharedRequiredMessage),
        role: yup
            .string()
            .required(SharedRequiredMessage)
            .oneOf(
                getRoleOptions(),
                `Role must be one of: ${getRoleOptions().join(", ")}`
            ),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const email = watch("email");

    if (!provider) {
        return null;
    }

    const onSubmit = async (formData) => {
        setIsLoading(true);
        setIsSuccess(false);
        setIsError(false);

        const body = {
            email: formData.email,
            role: formData.role,
        };
        const response = await inviteUserToProviderOrg({
            providerId,
            body,
        }).unwrap();

        if (response.success) {
            setIsSuccess(true);
        } else {
            setIsError(true);
        }

        setIsLoading(false);
    };

    const triggerInviteUser = () => {
        handleSubmit(onSubmit)();
    };

    const renderModalContent = () => {
        if (isSuccess) {
            return (
                <>
                    <div className="text-[16px] text-flychainPurple-dark mt-[20px]">
                        Success!{" "}
                        <span className="text-[16px] text-flychainPurple-dark font-semibold">
                            {email}
                        </span>{" "}
                        has been invited to join{" "}
                        <span className="text-[16px] text-flychainPurple-dark font-semibold">
                            {provider.legal_name}
                        </span>
                        .
                    </div>
                    <div className="text-[16px] text-flychainPurple-dark">
                        They will receive an email with a link to sign up.
                    </div>
                </>
            );
        }

        return (
            <>
                <div className="text-[16px] text-flychainPurple-dark">
                    Invite a user to join{" "}
                    <span className="text-[16px] text-flychainPurple-dark font-semibold">
                        {provider.legal_name}
                    </span>
                    .
                </div>
                <div className="flex flex-row gap-x-[20px] w-full items-center py-[10px]">
                    <SharedFormTextInput
                        name="email"
                        label="Email"
                        control={control}
                        error={errors.email}
                        className="grow"
                    />
                    <SharedFormSelectInput
                        name="role"
                        variant="outlined"
                        size="small"
                        className="w-[110px]"
                        options={getRoleOptions()}
                        control={control}
                        error={errors.role}
                    />
                </div>
                {isError && (
                    <div className="text-center text-[12px] text-flychainError text-wrap">
                        An unexpected error occurred. Please try again later.
                    </div>
                )}
                <SharedButton
                    onClick={triggerInviteUser}
                    isLoading={isLoading}
                    loadingText={isLoading ? "Inviting user..." : undefined}
                >
                    Invite User
                </SharedButton>
            </>
        );
    };

    return (
        <SharedModal
            cardClassName="gap-y-[20px] min-w-[450px] justify-center items-center text-center pb-[50px]"
            onClose={() => {
                setShowInviteUserModal(false);
            }}
        >
            <img
                src={FlychainLogoSvg}
                alt="Flychain Logo"
                className="w-[160px] mb-[5px]"
            />
            {renderModalContent()}
        </SharedModal>
    );
}

InviteUserModal.propTypes = {
    providerId: PropTypes.string.isRequired,
    setShowInviteUserModal: PropTypes.func.isRequired,
};
