import React from "react";
import MainBenefitsCard from "../../MainBenefitsCard";
import InvoicingCardGraphicSvg from "./InvoicingCardGraphic.svg";

function Invoicing() {
    return (
        <MainBenefitsCard
            imageSrc={InvoicingCardGraphicSvg}
            title="Effortless Invoicing"
            body="Send polished invoices, track what you're owed, and get paid the way you want - all from where you manage your banking."
        />
    );
}

export default Invoicing;
