import React from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import Report from "./Report";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";
import useGetProviderId from "../../../hooks/useGetProviderId";

function BalanceSheet() {
    const providerId = useGetProviderId();
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });

    if (!businessEntities) return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <Report />
        </div>
    );
}

export default BalanceSheet;
