import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import useGetGraphPeriodOptions from "../../../hooks/useGetGraphPeriodOptions";
import { GRAPH_PERIOD } from "../constants";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetCashFlowGraph from "./NetCashFlowGraph";
import Report from "./Report";
import { SharedSectionTitle } from "../../shared/Text";
import { SharedStateSelectInput } from "../../shared/Inputs";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";

function CashFlow() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const [netCashFlowGraphPeriod, setNetCashFlowGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });
    const {
        data: graphPeriodOptions,
        isFetching: isFetchingGraphPeriodOptions,
    } = useGetGraphPeriodOptions({
        providerId,
        businessEntityId,
    });

    if (
        !businessEntities ||
        !graphPeriodOptions ||
        isFetchingGraphPeriodOptions
    )
        return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <div className="flex flex-row justify-between items-center">
                <SharedSectionTitle>Net Cash Flow by Month</SharedSectionTitle>
                <div className="mt-[5px]">
                    <SharedStateSelectInput
                        name="Net Cash Flow Period Selector"
                        label="Period"
                        variant="outlined"
                        size="small"
                        options={graphPeriodOptions}
                        currentValue={netCashFlowGraphPeriod}
                        onChange={(value) => {
                            setNetCashFlowGraphPeriod(value);
                        }}
                    />
                </div>
            </div>
            <NetCashFlowGraph period={netCashFlowGraphPeriod} />
            <div className="mt-[10px]">
                <Report />
            </div>
        </div>
    );
}

export default CashFlow;
