import React from "react";
import { useNavigate } from "react-router-dom";
import MainBenefitsCard from "../../MainBenefitsCard";
import { SharedButton } from "../../../../shared";
import BillPayCardGraphicSvg from "./BillPayCardGraphic.svg";

function BillPay() {
    const navigate = useNavigate();
    return (
        <MainBenefitsCard
            imageSrc={BillPayCardGraphicSvg}
            title={<div>Simplified Bill Pay</div>}
            body="Leverage Mercury’s sophisticated bill pay solution to stay on top of bill management, process bills faster, and avoid duplicate bills."
            action={
                <div className="flex flex-row justify-center mt-[16px]">
                    <SharedButton
                        color="secondary"
                        onClick={() => {
                            navigate("billPay");
                        }}
                    >
                        Learn More
                    </SharedButton>
                </div>
            }
        />
    );
}

export default BillPay;
