import React from "react";
import PropTypes from "prop-types";

function TaxesSvg({ fill, height, width }) {
    return (
        <svg
            viewBox="0 0 22 19"
            fill="none"
            height={height}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 18C19.5304 18 20.0391 17.7893 20.4142 17.4142C20.7893 17.0391 21 16.5304 21 16V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H11.1C10.7655 4.00328 10.4355 3.92261 10.1403 3.76538C9.84505 3.60815 9.59396 3.37938 9.41 3.1L8.6 1.9C8.41789 1.62347 8.16997 1.39648 7.8785 1.2394C7.58702 1.08231 7.26111 1.00005 6.93 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H19Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 11L10 13L14 9"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

TaxesSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

TaxesSvg.defaultProps = {
    height: undefined,
    width: undefined,
};
export default TaxesSvg;
