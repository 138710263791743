import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import LeftArrowSvg from "../../../../assets/LeftArrow";
import RightArrowSvg from "../../../../assets/RightArrow";

function YearPicker({ year, onChange, maxDate, minDate }) {
    const parsedMaxDate = dayjs(maxDate);
    const parsedMinDate = dayjs(minDate);

    const disableRightArrow = year === parsedMaxDate.year();
    const disableLeftArrow = year === parsedMinDate.year();

    const onRightClick = () => {
        if (!disableRightArrow) {
            onChange(year + 1);
        }
    };

    const onLeftClick = () => {
        if (!disableLeftArrow) {
            onChange(year - 1);
        }
    };

    return (
        <div className="flex flex-row justify-between items-center w-[150px] p-[10px] shadow-md rounded-xl bg-white">
            <div
                className={`w-[20px] h-[20px] select-none ${
                    disableLeftArrow ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={onLeftClick}
            >
                <LeftArrowSvg fill={disableLeftArrow ? "#F0F0F0" : "#8D58FF"} />
            </div>
            <div className="text-base font-semibold text-flychainPurple-light">
                {year}
            </div>
            <div
                className={`w-[20px] h-[20px] select-none ${
                    disableRightArrow ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={onRightClick}
            >
                <RightArrowSvg
                    fill={disableRightArrow ? "#F0F0F0" : "#8D58FF"}
                />
            </div>
        </div>
    );
}

YearPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    minDate: PropTypes.string.isRequired,
};

export default YearPicker;
