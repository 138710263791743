import React from "react";
import { Outlet, useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import Content from "../../Content";
import SideBar from "../../SideBar";
import NavigationTab from "../../SideBar/NavigationTab";
import CapitalSvg from "../../../assets/Capital";
import FinancialReportsSvg from "../../../assets/FinancialReports";
import SavingsSvg from "../../../assets/Savings";
import ValuationSvg from "../../../assets/Valuation";
import GearSvg from "../../../assets/Gear";
import HandshakeSvg from "../../../assets/Handshake";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";
import {
    partnerServicesSubtabs,
    financialReportsSubtabs,
    capitalSubtabs,
} from "../../SideBar/constants";

function SideBarAndContent() {
    const providerId = useGetProviderId();
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });

    businessEntityId =
        businessEntityId ||
        (businessEntities &&
            businessEntities.length > 0 &&
            businessEntities[0].id);

    return (
        <>
            <SideBar>
                {businessEntityId && (
                    <NavigationTab
                        label="Financials"
                        path={`/businessEntity/${businessEntityId}/financialReports`}
                        icon={<FinancialReportsSvg fill="white" />}
                        subtabs={financialReportsSubtabs}
                    />
                )}
                <NavigationTab
                    label="Capital"
                    path="/capital"
                    icon={<CapitalSvg fill="white" />}
                    subtabs={capitalSubtabs}
                />
                <NavigationTab
                    label="Valuation"
                    path="/valuation"
                    icon={<ValuationSvg fill="white" />}
                />
                <NavigationTab
                    label="Savings"
                    path="/savings"
                    icon={<SavingsSvg fill="white" />}
                />
                <NavigationTab
                    label="Partner Services"
                    path="/partnerServices"
                    icon={<HandshakeSvg fill="white" />}
                    subtabs={partnerServicesSubtabs}
                />
                <NavigationTab
                    label="Settings"
                    path="/settings"
                    icon={<GearSvg fill="white" />}
                />
            </SideBar>
            <Content>
                <Outlet />
            </Content>
        </>
    );
}

export default SideBarAndContent;
