import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useGetProvidersQuery } from "../../../../redux/slices/apiV2";
import NoProvidersFound from "../NoProvidersFound";

function ProvidersConnections({ searchTerm, toggleScroll }) {
    const navigate = useNavigate();
    const [filteredProviders, setFilteredProviders] = useState([]);
    const { data: providersDict } = useGetProvidersQuery({ params: {} });

    // Filters the providers based on the search term
    useEffect(() => {
        if (!providersDict) return;

        const filtered = Object.values(providersDict).filter(
            (provider) =>
                provider.legal_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                (provider.dba &&
                    provider.dba
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()))
        );

        setFilteredProviders(filtered);
        toggleScroll();
    }, [searchTerm, providersDict, toggleScroll]);

    if (!providersDict) return null;

    if (filteredProviders.length === 0) return <NoProvidersFound />;

    return filteredProviders.map((provider) => {
        const onClick = () => {
            navigate(`/provider/${provider.id}/settings?tab=connections`);
        };
        return (
            <div
                key={provider.id}
                onClick={onClick}
                className="flex flex-col cursor-pointer rounded-xl border-[1px] border-flychainGray px-[10px] py-[5px] w-fit hover:bg-flychainPurple-light hover:text-white group"
            >
                {provider.legal_name}
                <div className="text-sm text-flychainGray group-hover:text-white">
                    NPI: {provider.npi}
                </div>
            </div>
        );
    });
}

ProvidersConnections.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    toggleScroll: PropTypes.func.isRequired,
};

export default ProvidersConnections;
