import { createApi } from "@reduxjs/toolkit/query/react";
import createBaseQuery from "../apiUtils";
import {
    getAllUsersQueryParams,
    getProvidersQueryParams,
    transformGetProvidersResponse,
    getPlaidLinkTokenQueryParams,
    getPlaidItemsQueryParams,
    getPlaidItemsForAllProvidersQueryParams,
    getBusinessEntitiesQueryParams,
    getLedgerLinesQueryParams,
    getFinancialSummaryQueryParams,
    getIncomeStatementQueryParams,
    getCashFlowReportQueryParams,
    getBalanceSheetQueryParams,
    getRevenueByCategoryQueryParams,
    getTopExpensesQueryParams,
    getCollectionsLastPaymentCheckForProviderQueryParams,
} from "./utils";
import { OfferStatus } from "../../../constants";

export const apiV2Slice = createApi({
    reducerPath: "apiV2",
    baseQuery: createBaseQuery(),
    tagTypes: [
        "users",
        "invites",
        "provider",
        "providers",
        "plaidItems",
        "businessEntity",
        "businessEntities",
        "allBusinessEntities",
        "entriesStart",
        "validUpUntil",
        "tealInstances",
        "plaidAccountMappings",
        "offers",
    ],
    endpoints: (builder) => ({
        // User & Provider endpoints
        sendUserCreatedEmail: builder.mutation({
            query: ({ body }) => ({
                url: "/v2/users",
                method: "POST",
                body,
            }),
            invalidatesTags: ["users", "invites"],
        }),
        deleteUser: builder.mutation({
            query: ({ userId }) => ({
                url: `/v2/user/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["users"],
        }),
        updateUserRole: builder.mutation({
            query: ({ providerId, userId, body }) => ({
                url: `/v2/provider/${providerId}/user/${userId}/role`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: ["users"],
        }),
        removeUserFromProviderOrg: builder.mutation({
            query: ({ providerId, userId }) => ({
                url: `/v2/provider/${providerId}/user/${userId}/remove_from_org`,
                method: "DELETE",
            }),
            invalidatesTags: ["users"],
        }),
        inviteUserToProviderOrg: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/users/invites`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, { providerId }) => [
                { type: "invites", providerId },
            ],
        }),
        getPendingInvitesForProviderOrg: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/users/invites`,
            providesTags: (result, error, { providerId }) => [
                { type: "invites", providerId },
            ],
        }),
        revokePendingInviteForProviderOrg: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/users/invites`,
                method: "DELETE",
                body,
            }),
            invalidatesTags: (result, error, { providerId }) => [
                { type: "invites", providerId },
            ],
        }),
        getAllUsers: builder.query({
            query: ({ params }) => `/v2/users${getAllUsersQueryParams(params)}`,
            providesTags: (result, error, { params }) => [
                { type: "users", testUsers: params.testUsers || false },
            ],
        }),
        getUsersForProvider: builder.query({
            query: ({ providerId }) => `/v2/provider/${providerId}/users`,
            providesTags: (result, error, { providerId }) => [
                { type: "users", providerId },
            ],
        }),
        createProvider: builder.mutation({
            query: ({ body }) => ({
                url: "/v2/provider",
                method: "POST",
                body,
            }),
            invalidatesTags: ["providers"],
        }),
        addProviderBankAccount: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/bank_account`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, { providerId }) => [
                { type: "provider", providerId },
                "providers",
            ],
        }),
        submitSignedSaasAgreementForProvider: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/signed_saas_agreement`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error, { providerId }) => [
                { type: "provider", providerId },
                "providers",
            ],
        }),
        updateProviderPlatformState: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/platform_state`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error, { providerId }) => [
                { type: "provider", providerId },
                "providers",
            ],
        }),
        getProvider: builder.query({
            query: ({ providerId }) => `/v2/provider/${providerId}`,
            providesTags: (result, error, { providerId }) => [
                { type: "provider", providerId },
            ],
        }),
        getProviders: builder.query({
            query: ({ params }) =>
                `/v2/providers${getProvidersQueryParams(params)}`,
            transformResponse: (data) => transformGetProvidersResponse(data),
            providesTags: (result, error, { params }) => [
                {
                    type: "providers",
                    onboardedToTeal: params.onboardedToTeal || false,
                },
            ],
        }),
        // Plaid endpoints
        getPlaidLinkToken: builder.query({
            query: ({ providerId, params }) =>
                `/v2/provider/${providerId}/plaid/link${getPlaidLinkTokenQueryParams(
                    params
                )}`,
        }),
        createPlaidItem: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/plaid/link`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        updatePlaidItem: builder.mutation({
            query: ({ providerId, internalPlaidItemId }) => ({
                url: `/v2/provider/${providerId}/plaid/item/${internalPlaidItemId}`,
                method: "PATCH",
            }),
            invalidatesTags: ["plaidItems"],
        }),
        breakPlaidItem: builder.mutation({
            query: ({ providerId, internalPlaidItemId }) => ({
                url: `/v2/provider/${providerId}/plaid/item/${internalPlaidItemId}/break_item`,
                method: "PATCH",
            }),
            invalidatesTags: ["plaidItems"],
        }),
        getPlaidItems: builder.query({
            query: ({ providerId, params }) =>
                `/v2/provider/${providerId}/plaid/items${getPlaidItemsQueryParams(
                    params
                )}`,
            providesTags: (result, error, { providerId, params }) => [
                {
                    type: "plaidItems",
                    providerId,
                    onlyCheckingAccounts: params.onlyCheckingAccounts || false,
                },
            ],
        }),
        getPlaidItemsForAllProviders: builder.query({
            query: ({ params }) =>
                `/v2/plaid/items${getPlaidItemsForAllProvidersQueryParams(
                    params
                )}`,
            providesTags: ["plaidItems"],
        }),
        updateProviderPlatformStateToPlaidConnected: builder.mutation({
            query: ({ providerId }) => ({
                url: `/v2/provider/${providerId}/plaid/items/platform_state`,
                method: "PATCH",
            }),
            invalidatesTags: (result, error, { providerId }) => [
                { type: "provider", providerId },
            ],
        }),
        // Business entity endpoints
        getAllBusinessEntities: builder.query({
            query: () => "/v2/business_entities",
            providesTags: ["allBusinessEntities"],
        }),
        getBusinessEntities: builder.query({
            query: ({ providerId, params }) =>
                `/v2/provider/${providerId}/business_entities${getBusinessEntitiesQueryParams(
                    params
                )}`,
            providesTags: (result, error, { providerId, params }) => [
                {
                    type: "businessEntities",
                    providerId,
                    includeIncomplete: params.includeIncomplete || false,
                },
            ],
        }),
        getBusinessEntity: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}`,
            providesTags: (result, error, { businessEntityId }) => [
                { type: "businessEntity", businessEntityId },
            ],
        }),
        createBusinessEntity: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["businessEntities", "allBusinessEntities"],
        }),
        updateBusinessEntity: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error, { businessEntityId }) => [
                "businessEntities",
                "allBusinessEntities",
                { type: "businessEntity", businessEntityId },
            ],
        }),
        // Financial reports endpoints
        getFinancialSummary: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/summary${getFinancialSummaryQueryParams(
                    params
                )}`,
        }),
        getIncomeStatement: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/income_statement${getIncomeStatementQueryParams(
                    params
                )}`,
        }),
        getCashFlowReport: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/cash_flow_report${getCashFlowReportQueryParams(
                    params
                )}`,
        }),
        getBalanceSheet: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/balance_sheet${getBalanceSheetQueryParams(
                    params
                )}`,
        }),
        getRevenueByCategory: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/revenue_by_category${getRevenueByCategoryQueryParams(
                    params
                )}`,
        }),
        getTopExpenses: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/top_expenses${getTopExpensesQueryParams(
                    params
                )}`,
        }),
        getExpensesByMerchant: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/expenses_by_merchant`,
        }),
        getLedgerLines: builder.query({
            query: ({ providerId, businessEntityId, ledgerId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/ledger_lines/${ledgerId}${getLedgerLinesQueryParams(
                    params
                )}`,
        }),
        // Financial reports graph data endpoints
        getNetProfitGraphData: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/net_profit_graph?period=${params.period}`,
        }),
        getNetCashFlowGraphData: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/net_cash_flow_graph?period=${params.period}`,
        }),
        getCashBalanceGraphData: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/cash_balance_graph?period=${params.period}`,
        }),
        getExpensesByCategoryGraphData: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/expenses_by_category_graph?period=${params.period}`,
            keepUnusedDataFor: 600, // Keep data in cache for 10 minutes
        }),
        getRevenueByCategoryGraphData: builder.query({
            query: ({ providerId, businessEntityId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/revenue_by_category_graph?period=${params.period}`,
            keepUnusedDataFor: 600, // Keep data in cache for 10 minutes
        }),
        getLedgerTotalsGraphData: builder.query({
            query: ({ providerId, businessEntityId, ledgerId, params }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/ledger_totals_graph/${ledgerId}?period=${params.period}`,
        }),
        // Financial reports export endpoints
        exportIncomeStatement: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/income_statement/export`,
                method: "POST",
                body,
            }),
        }),
        exportCashFlowReport: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/cash_flow_report/export`,
                method: "POST",
                body,
            }),
        }),
        exportBalanceSheet: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/balance_sheet/export`,
                method: "POST",
                body,
            }),
        }),
        exportGeneralLedger: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/general_ledger/export`,
                method: "POST",
                body,
            }),
        }),
        // Financial reports dates endpoints
        getFinancialReportsEntriesStart: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/entries_start`,
            providesTags: (result, error, { businessEntityId }) => [
                { type: "entriesStart", businessEntityId },
            ],
        }),
        getFinancialReportsValidUpUntil: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/valid_up_until`,
            providesTags: (result, error, { businessEntityId }) => [
                { type: "validUpUntil", businessEntityId },
            ],
        }),
        updateFinancialReportsEntriesStart: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/entries_start`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error, { businessEntityId }) => [
                { type: "entriesStart", businessEntityId },
            ],
        }),
        updateFinancialReportsValidUpUntil: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/financial_reports/valid_up_until`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error, { businessEntityId }) => [
                { type: "validUpUntil", businessEntityId },
            ],
        }),
        // Teal onboarding endpoints
        getTealInstances: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/teal/instances`,
            providesTags: ["tealInstances"],
        }),
        createTealInstance: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/teal/instance`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["tealInstances"],
        }),
        getPlaidAccountMappings: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/teal/onboarding/plaid_account_mappings`,
            providesTags: ["plaidAccountMappings"],
        }),
        updatePlaidAccountMapping: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/teal/onboarding/plaid_account_mapping`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: ["plaidAccountMappings"],
        }),
        linkPlaidAccountToTeal: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/teal/onboarding/plaid_account`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidAccountMappings"],
        }),
        onboardProviderToTeal: builder.mutation({
            query: ({ body }) => ({
                url: `/v2/teal/onboarding`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidAccountMappings"],
        }),
        // Offer & funding endpoints
        getOffers: builder.query({
            query: ({ status }) => `/v2/offers?status=${status}`,
            providesTags: (result, error, { status }) => [
                { type: "offers", status },
            ],
        }),
        getOffer: builder.query({
            query: ({ providerId, businessEntityId, offerId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/offer/${offerId}`,
        }),
        getOatfiBusinessBalance: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/oatfi/business_balance`,
        }),
        createOffer: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/offer`,
                method: "POST",
                body,
            }),
            invalidatesTags: [
                { type: "offers", status: OfferStatus.UNCONFIRMED },
            ],
        }),
        confirmOffer: builder.mutation({
            query: ({ providerId, businessEntityId, offerId }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/offer/${offerId}/confirm`,
                method: "PATCH",
            }),
            invalidatesTags: [
                { type: "offers", status: OfferStatus.UNCONFIRMED },
                { type: "offers", status: OfferStatus.CONFIRMED },
            ],
        }),
        createDrawAndOatfiOffer: builder.mutation({
            query: ({ providerId, businessEntityId, offerId }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/oatfi/loan/offer/${offerId}`,
                method: "POST",
            }),
            invalidatesTags: [
                { type: "offers", status: OfferStatus.CONFIRMED },
                { type: "offers", status: OfferStatus.UNFUNDED },
            ],
        }),
        fundOffer: builder.mutation({
            query: ({ providerId, businessEntityId, drawId }) => ({
                url: `/v2/provider/${providerId}/business_entity/${businessEntityId}/oatfi/loan/funding/${drawId}`,
                method: "POST",
            }),
            invalidatesTags: [
                { type: "offers", status: OfferStatus.UNFUNDED },
                { type: "offers", status: OfferStatus.FUNDED },
            ],
        }),
        // Capital endpoints
        getAdvancedPaymentOnClaimsDataForBusinessEntity: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/capital/advanced_payment_on_claims`,
        }),
        getAdvancedPaymentOnClaimsLineForBusinessEntity: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/capital/advanced_payment_on_claims/line`,
        }),
        // Collections endpoints
        getCollectionsAllUncollectedPayments: builder.query({
            query: () => `/v2/capital/collections/uncollected_payments`,
        }),
        getCollectionsFinanceReportForProvider: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/capital/collections/finance_report`,
        }),
        getCollectionsDrawPaymentCrosscheckForProvider: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/capital/collections/draw_payment_crosscheck`,
        }),
        getCollectionsLastPaymentsCheckForProvider: builder.query({
            query: ({ providerId, params }) =>
                `/v2/provider/${providerId}/capital/collections/last_payments_check${getCollectionsLastPaymentCheckForProviderQueryParams(
                    params
                )}`,
        }),
        updateCollectionsLastPaymentsCheckForProvider: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/capital/collections/last_payments_check`,
                method: "PATCH",
                body,
            }),
        }),
        getCollectionsBalanceCheckDataForProvider: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/capital/collections/balance_check`,
        }),
        collectPaymentForProvider: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/capital/collections/collect_payment`,
                method: "POST",
                body,
            }),
        }),
        // Miscellaneous endpoints
        getSavingsForBusinessEntity: builder.query({
            query: ({ providerId, businessEntityId }) =>
                `/v2/provider/${providerId}/business_entity/${businessEntityId}/savings`,
        }),
        getPartnerForProvider: builder.query({
            query: ({ providerId }) => `/v2/provider/${providerId}/partner`,
        }),
        getValuationForProvider: builder.query({
            query: ({ providerId }) => `/v2/provider/${providerId}/valuation`,
            keepUnusedDataFor: 600, // Keep data in cache for 10 minutes
        }),
        getPlatformSubscription: builder.query({
            query: ({ providerId }) =>
                `/v2/provider/${providerId}/subscription`,
        }),
        getMercurySignupLinkForProvider: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/v2/provider/${providerId}/banking/mercury_signup_link`,
                method: "POST",
                body,
            }),
        }),
        getActiveBookkeeperSubscriptions: builder.query({
            query: () => "/v2/bookkeeper_subscriptions",
        }),
    }),
});

export const {
    // User & Provider hooks
    useSendUserCreatedEmailMutation,
    useDeleteUserMutation,
    useUpdateUserRoleMutation,
    useRemoveUserFromProviderOrgMutation,
    useInviteUserToProviderOrgMutation,
    useGetPendingInvitesForProviderOrgQuery,
    useRevokePendingInviteForProviderOrgMutation,
    useGetAllUsersQuery,
    useGetUsersForProviderQuery,
    useGetProviderQuery,
    useCreateProviderMutation,
    useAddProviderBankAccountMutation,
    useGetProvidersQuery,
    useUpdateProviderPlatformStateMutation,
    useSubmitSignedSaasAgreementForProviderMutation,
    // Plaid hooks
    useLazyGetPlaidLinkTokenQuery,
    useCreatePlaidItemMutation,
    useUpdatePlaidItemMutation,
    useBreakPlaidItemMutation,
    useGetPlaidItemsQuery,
    useGetPlaidItemsForAllProvidersQuery,
    useUpdateProviderPlatformStateToPlaidConnectedMutation,
    // Business entity hooks
    useGetAllBusinessEntitiesQuery,
    useGetBusinessEntitiesQuery,
    useGetBusinessEntityQuery,
    useCreateBusinessEntityMutation,
    useUpdateBusinessEntityMutation,
    // Financial reports hooks
    useGetFinancialSummaryQuery,
    useGetIncomeStatementQuery,
    useGetCashFlowReportQuery,
    useGetBalanceSheetQuery,
    useGetRevenueByCategoryQuery,
    useGetTopExpensesQuery,
    useGetExpensesByMerchantQuery,
    useGetLedgerLinesQuery,
    // Financial reports graph data hooks
    useGetNetProfitGraphDataQuery,
    useGetNetCashFlowGraphDataQuery,
    useGetCashBalanceGraphDataQuery,
    useGetExpensesByCategoryGraphDataQuery,
    useGetRevenueByCategoryGraphDataQuery,
    useGetLedgerTotalsGraphDataQuery,
    // Financial reports export hooks
    useExportIncomeStatementMutation,
    useExportCashFlowReportMutation,
    useExportBalanceSheetMutation,
    useExportGeneralLedgerMutation,
    // Financial reports dates hooks
    useGetFinancialReportsEntriesStartQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useUpdateFinancialReportsEntriesStartMutation,
    useUpdateFinancialReportsValidUpUntilMutation,
    // Teal onboarding hooks
    useGetTealInstancesQuery,
    useCreateTealInstanceMutation,
    useGetPlaidAccountMappingsQuery,
    useUpdatePlaidAccountMappingMutation,
    useLinkPlaidAccountToTealMutation,
    useOnboardProviderToTealMutation,
    // Offer & funding hooks
    useGetOffersQuery,
    useGetOfferQuery,
    useGetOatfiBusinessBalanceQuery,
    useCreateOfferMutation,
    useConfirmOfferMutation,
    useCreateDrawAndOatfiOfferMutation,
    useFundOfferMutation,
    // Capital hooks
    useGetAdvancedPaymentOnClaimsDataForBusinessEntityQuery,
    useGetAdvancedPaymentOnClaimsLineForBusinessEntityQuery,
    // Collections hooks
    useGetCollectionsAllUncollectedPaymentsQuery,
    useGetCollectionsFinanceReportForProviderQuery,
    useGetCollectionsDrawPaymentCrosscheckForProviderQuery,
    useGetCollectionsLastPaymentsCheckForProviderQuery,
    useUpdateCollectionsLastPaymentsCheckForProviderMutation,
    useGetCollectionsBalanceCheckDataForProviderQuery,
    useCollectPaymentForProviderMutation,
    // Miscellaneous hooks
    useGetSavingsForBusinessEntityQuery,
    useGetPartnerForProviderQuery,
    useGetValuationForProviderQuery,
    useGetPlatformSubscriptionQuery,
    useGetMercurySignupLinkForProviderMutation,
    useGetActiveBookkeeperSubscriptionsQuery,
} = apiV2Slice;

export const { getFinancialReportsValidUpUntil } = apiV2Slice.endpoints;
