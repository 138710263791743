export const STATES = {
    SCHEDULE_CALL: "SCHEDULE_CALL",
    CONFIRM_SUCCESSFUL_SCHEDULE: "CONFIRM_SUCCESSFUL_SCHEDULE",
};

export const CALENDLY_PARENT_ELEMENT_ID = "CALENDLY_EMBED";

export const CALENDLY_URLS = {
    FLYCHAIN_TEAM:
        "https://calendly.com/d/3sy-qcc-tgz/flychain-team-30-minutes",
    FUNDING_CALL: "https://calendly.com/jaimedeverall/funding-call",
    SAVINGS_CALL: "https://calendly.com/fundedbuy/call-with-beth-1",
    TREASURY_CALL: "https://calendly.com/juliastefani/treasure-30-min",
    RIVET_TAX_CALL: "https://calendly.com/nick-rivet/30min",
    UPRISE_TAX_CALL:
        "https://calendly.com/patrick-uprise/flychain-uprise-financial-advising-call",
};
