import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { SharedButton } from "../../shared";
import {
    SharedFormTextInput,
    SharedFormNumericInput,
} from "../../shared/Inputs";
import { useExportGeneralLedgerMutation } from "../../../redux/slices/apiV2";
import Checkmark from "../../../assets/Checkmark.svg";

const defaultValues = {
    year: "",
    email: "",
};

const schema = yup.object({
    year: yup
        .number()
        .min(1900, "Year must be greater than 1900")
        .max(2030, "Year must be less than 2030")
        .required(),
    email: yup.string().email().required(),
});

function DownloadInterface({ businessEntity, provider }) {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const year = watch("year");
    const email = watch("email");

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [trigger] = useExportGeneralLedgerMutation();

    const handleFormSubmit = async (formData) => {
        try {
            setIsModalVisible(false);

            await trigger({
                providerId: provider.id,
                businessEntityId: businessEntity.id,
                body: {
                    year: formData.year.toString(),
                    email: formData.email,
                },
            });

            setIsModalVisible(true);
        } catch (error) {
            console.error("Error downloading report:", error);
            setIsModalVisible(false);
        }
    };

    return (
        <div className="flex flex-row items-center">
            <div className="flex flex-row gap-x-[15px]">
                <SharedFormNumericInput
                    name="year"
                    label="Report Year"
                    control={control}
                    error={year !== "" ? errors.year : undefined}
                />
                <SharedFormTextInput
                    name="email"
                    label="Send to Email"
                    control={control}
                    error={email !== "" ? errors.email : undefined}
                />
            </div>
            <div className="ml-[10px]">
                <SharedButton
                    className="rounded-full w-fit px-[10px] py-[5px]"
                    onClick={handleSubmit(handleFormSubmit)}
                    disabled={!isValid}
                >
                    Download
                </SharedButton>
            </div>
            {isModalVisible && (
                <div
                    className="flex fixed inset-0 justify-center items-center bg-black bg-opacity-50"
                    onClick={() => setIsModalVisible(false)}
                >
                    <div className="bg-white p-6 rounded shadow-lg w-[300px] h-[200px] flex flex-col justify-center items-center">
                        <img
                            className="w-[20px] mb-[8px]"
                            src={Checkmark}
                            alt="Checkmark"
                        />
                        <div className="flex flex-col text-center">
                            <p className="text-[14px] font-bold text-flychainPurple-light">
                                The report will be sent to your email shortly!
                            </p>
                            <p className="text-[14px] font-medium text-flychainPurple-dark mt-[4px]">
                                If you don&apos;t receive an email within 5
                                minutes, please try downloading the report
                                again.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

DownloadInterface.propTypes = {
    businessEntity: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
};

export default DownloadInterface;
