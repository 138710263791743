/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    user: null,
    accessToken: null,
    activeOrg: null,
    authErrorOccurred: false,
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuth: (state, action) => {
            const { user, accessToken, activeOrg } = action.payload;
            state.isAuthenticated = true;
            state.user = user;
            state.accessToken = accessToken;
            state.activeOrg = activeOrg;
            state.authErrorOccurred = false; // Reset auth error occurred flag
        },
        clearAuth: () => initialState,
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setActiveOrg: (state, action) => {
            state.activeOrg = action.payload;
        },
        setAuthErrorOccurred: (state) => {
            state.authErrorOccurred = true;
        },
    },
});

export const selectIsAuthenticated = (state) =>
    state.authentication.isAuthenticated;
export const selectUser = (state) => state.authentication.user;
export const selectAccessToken = (state) => state.authentication.accessToken;
export const selectActiveOrg = (state) => state.authentication.activeOrg;
export const selectAuthErrorOccurred = (state) =>
    state.authentication.authErrorOccurred;

export const {
    setAuth,
    clearAuth,
    setUser,
    setActiveOrg,
    setAuthErrorOccurred,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
