import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import { SharedCard, SharedButton } from "../shared";
import FlychainLogoSvg from "../../assets/FlychainLogo.svg";
import CircleCheckSvg from "../../assets/CircleCheck";
import { AuthPageEnums } from "../../constants";

function ForgotPassword({
    setAuthPage,
    confirmationEmail,
    setConfirmationEmail,
}) {
    const { sendForgotPasswordEmail } = useAuthFrontendApis();
    const [isLoading, setIsLoading] = useState(false);
    const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);
    const [resetError, setResetError] = useState(false);

    const handleSendForgotPasswordEmail = async () => {
        setIsLoading(true);
        setResetError(false);
        const response = await sendForgotPasswordEmail({
            email: confirmationEmail,
        });
        response.handle({
            success: () => {
                setEmailSentSuccessfully(true);
                setIsLoading(false);
            },
            badRequest: () => {
                setResetError(true);
                setIsLoading(false);
            },
            unexpectedOrUnhandled: () => {
                setResetError(true);
                setIsLoading(false);
            },
        });
    };

    const renderActions = () => {
        const goToLogIn = (
            <div
                className="underline cursor-pointer text-flychainPurple-light"
                onClick={() => {
                    setConfirmationEmail("");
                    setAuthPage(AuthPageEnums.LOG_IN);
                }}
            >
                Go to Log In
            </div>
        );

        if (emailSentSuccessfully) {
            return (
                <div className="flex flex-row items-center mt-[15px] gap-x-[5px]">
                    <CircleCheckSvg width={15} height={15} fill="#00B894" />
                    <div className="text-flychainGray">
                        Email sent successfully. Please check your email to
                        reset your password.
                    </div>
                    {goToLogIn}
                </div>
            );
        }
        return (
            <>
                <SharedButton
                    onClick={handleSendForgotPasswordEmail}
                    disabled={isLoading}
                    loadingText={isLoading ? "Sending..." : undefined}
                >
                    Send Reset Password Link
                </SharedButton>
                <div className="flex flex-row items-center gap-x-[8px] mt-[10px]">
                    <div className="text-[14px] cursor-default text-flychainGray">
                        Not your email?
                    </div>
                    {goToLogIn}
                </div>
            </>
        );
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <SharedCard className="max-w-[60%] bg-white">
                <div className="flex flex-col justify-center items-center gap-y-[25px] ">
                    <img
                        src={FlychainLogoSvg}
                        alt="Flychain Logo"
                        className="w-[160px]"
                    />
                    <div className="flex flex-col gap-y-[10px] text-center">
                        <div className="text-[16px] text-flychainPurple-dark">
                            Forgot your password? We&apos;ll send a password
                            reset link to{" "}
                            <span className="font-semibold">
                                {confirmationEmail}
                            </span>
                            .
                        </div>
                    </div>
                    {resetError && (
                        <div className="text-flychainError">
                            An error occurred. Please try again.
                        </div>
                    )}
                    {renderActions()}
                </div>
            </SharedCard>
        </div>
    );
}

ForgotPassword.propTypes = {
    setAuthPage: PropTypes.func.isRequired,
    confirmationEmail: PropTypes.string.isRequired,
    setConfirmationEmail: PropTypes.func.isRequired,
};

export default ForgotPassword;
