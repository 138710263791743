import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import * as Sentry from "@sentry/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import { useAuthInfo } from "@propelauth/react";
import { useForm } from "react-hook-form";
import { SharedCard, SharedButton } from "../../shared";
import {
    SharedFormTextInput,
    SharedRequiredMessage,
} from "../../shared/Inputs";
import SignUpError from "./SignUpError";
import {
    PropelAuthSignUpErrorType,
    AuthPageEnums,
    PropelAuthLoginState,
    PropelAuthSignUpBadRequestErrors,
} from "../../../constants";
import FlychainLogoSvg from "../../../assets/FlychainLogo.svg";
import { useSendUserCreatedEmailMutation } from "../../../redux/slices/apiV2";

const defaultValues = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
};

const schema = yup.object({
    email: yup.string().email().required(SharedRequiredMessage),
    password: yup
        .string()
        .required(SharedRequiredMessage)
        .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
        .string()
        .required(SharedRequiredMessage)
        .oneOf([yup.ref("password")], "Passwords must match"),
    firstName: yup.string().required(SharedRequiredMessage),
    lastName: yup.string().required(SharedRequiredMessage),
});

function SignUp({ setAuthPage, setConfirmationEmail, inviteToken }) {
    const authInfo = useAuthInfo();
    const { signup } = useAuthFrontendApis();
    const [sendUserCreatedEmail] = useSendUserCreatedEmailMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorType, setErrorType] = useState(null);

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const email = watch("email");
    const firstName = watch("firstName");
    const lastName = watch("lastName");

    const getTokenData = (token) => {
        // Decode the invite token header
        const decoded = atob(token.replace(/-/g, "+").replace(/_/g, "/"));
        // Return the decoded token as parsed JSON
        return JSON.parse(decoded);
    };

    useEffect(() => {
        if (inviteToken) {
            const tokenData = getTokenData(inviteToken);
            setValue("email", tokenData.email); // Set the email value
        }
    }, [inviteToken]);

    const handleSendUserCreatedEmail = async (userId, loginState) => {
        let orgId = null;
        if (inviteToken) {
            const tokenData = getTokenData(inviteToken);
            orgId = tokenData.org_id;
        }
        const body = orgId
            ? {
                  user_id: userId,
                  email,
                  first_name: firstName,
                  last_name: lastName,
                  login_state: loginState,
                  org_id: orgId,
              }
            : {
                  user_id: userId,
                  email,
                  first_name: firstName,
                  last_name: lastName,
                  login_state: loginState,
              };
        await sendUserCreatedEmail({ body });
    };

    const handleSuccessResponse = async (response) => {
        await handleSendUserCreatedEmail(
            response.data.user_id,
            response.data.login_state
        );
        if (
            response.data.login_state ===
            PropelAuthLoginState.CONFIRM_EMAIL_REQUIRED
        ) {
            setConfirmationEmail(email);
            setAuthPage(AuthPageEnums.EMAIL_CONFIRMATION);
        } else if (
            response.data.login_state === PropelAuthLoginState.LOGIN_REQUIRED
        ) {
            setAuthPage(AuthPageEnums.LOGIN_REQUIRED);
        } else if (
            [
                PropelAuthLoginState.LOGGED_IN,
                PropelAuthLoginState.FINISHED,
            ].includes(response.data.login_state)
        ) {
            await authInfo.refreshAuthInfo();
        } else {
            throw new Error(`Unexpected signup response: ${response}`);
        }
    };

    const handleErrorResponse = (newErrorType, error) => {
        Sentry.captureException(new Error(JSON.stringify(error)));
        setErrorType(newErrorType);
    };

    const handleBadRequest = (error) => {
        if (
            error.field_errors.password ===
            PropelAuthSignUpBadRequestErrors.COMMON_PASSWORD
        ) {
            setError("password", {
                message: error.user_facing_errors.password,
            });
        }
        if (
            error.field_errors.email ===
            PropelAuthSignUpBadRequestErrors.EMAIL_TAKEN
        ) {
            setError("email", {
                message:
                    "This email is already in use. Try logging in instead.",
            });
        }
        if (!error.field_errors.password && !error.field_errors.email) {
            handleErrorResponse(PropelAuthSignUpErrorType.BAD_REQUEST, error);
        }
    };

    const onSubmit = async (formData) => {
        setIsLoading(true);
        setErrorType(null);
        const signupBody = inviteToken
            ? {
                  email: formData.email,
                  password: formData.password,
                  first_name: formData.firstName,
                  last_name: formData.lastName,
                  invite_token: inviteToken,
              }
            : {
                  email: formData.email,
                  password: formData.password,
                  first_name: formData.firstName,
                  last_name: formData.lastName,
              };
        const response = await signup(signupBody);
        response.handle({
            success: async () => {
                await handleSuccessResponse(response);
                setIsLoading(false);
            },
            signupDisabled(error) {
                handleErrorResponse(
                    PropelAuthSignUpErrorType.SIGNUP_DISABLED,
                    error
                );
                setIsLoading(false);
            },
            badRequest(error) {
                handleBadRequest(error);
                setIsLoading(false);
            },
            unexpectedOrUnhandled(error) {
                handleErrorResponse(
                    PropelAuthSignUpErrorType.UNEXPECTED_OR_UNHANDLED,
                    error
                );
                setIsLoading(false);
            },
        });
    };

    const triggerSignUp = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <SharedCard className="w-[400px] bg-white">
                <div className="flex flex-col justify-center items-center gap-y-[20px]">
                    <img
                        src={FlychainLogoSvg}
                        alt="Flychain Logo"
                        className="w-[160px]"
                    />
                    <div className="flex flex-col gap-y-[10px] items-center w-full">
                        <SharedFormTextInput
                            className="w-full"
                            name="email"
                            label="Email"
                            control={control}
                            error={errors.email}
                            disabled={!!inviteToken} // If there is an invite token, the email input is disabled because it's already set
                        />
                        <SharedFormTextInput
                            className="w-full"
                            name="password"
                            label="Password"
                            type="password"
                            control={control}
                            error={errors.password}
                        />
                        <SharedFormTextInput
                            className="w-full"
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            control={control}
                            error={errors.confirmPassword}
                        />
                        <SharedFormTextInput
                            className="w-full"
                            name="firstName"
                            label="First Name"
                            control={control}
                            error={errors.firstName}
                        />
                        <SharedFormTextInput
                            className="w-full"
                            name="lastName"
                            label="Last Name"
                            control={control}
                            error={errors.lastName}
                        />
                    </div>
                    {errorType && <SignUpError errorType={errorType} />}
                    <SharedButton
                        onClick={triggerSignUp}
                        disabled={isLoading}
                        loadingText={isLoading ? "Signing up..." : undefined}
                    >
                        Sign Up
                    </SharedButton>
                    <div className="flex flex-row items-center gap-x-[8px] mt-[10px]">
                        <div className="text-[16px] cursor-default text-flychainGray">
                            Already have an account?
                        </div>
                        <div
                            onClick={() => setAuthPage(AuthPageEnums.LOG_IN)}
                            className="underline text-[16px] cursor-pointer text-flychainPurple-light font-semibold"
                        >
                            Log In
                        </div>
                    </div>
                </div>
            </SharedCard>
        </div>
    );
}

SignUp.propTypes = {
    setAuthPage: PropTypes.func.isRequired,
    setConfirmationEmail: PropTypes.func.isRequired,
    inviteToken: PropTypes.string,
};

SignUp.defaultProps = {
    inviteToken: undefined,
};

export default SignUp;
