import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { selectActiveOrg } from "../../../redux/slices/authentication";
import { useGetProviderQuery } from "../../../redux/slices/apiV2";
import OrgSelectorMenuItem from "./OrgSelectorMenuItem";
import RightCaret from "../../../assets/RightCaret.svg";
import DownCaret from "../../../assets/DownCaret.svg";
import Building2Svg from "../../../assets/Building2";

export default function SideBarOrgSelector({ userOrgs }) {
    const [showOrgMenu, setShowOrgMenu] = useState(false);
    const activeOrg = useSelector(selectActiveOrg);
    const { data: provider } = useGetProviderQuery({
        providerId: activeOrg.legacyOrgId,
    });

    if (!provider) {
        return null;
    }

    return (
        <div className="flex flex-col w-[280px] mb-[30px] mt-[10px] mx-[20px] border rounded-lg bg-flychainPurple-dark">
            <div
                className="flex flex-row w-full py-[10px] px-[20px] gap-x-[10px] items-center justify-between cursor-pointer"
                onClick={() => setShowOrgMenu(!showOrgMenu)}
            >
                <div className="flex flex-row items-center gap-x-[10px] min-w-0 overflow-hidden">
                    <div className="flex-shrink-0">
                        <Building2Svg fill="white" height={20} width={20} />
                    </div>
                    <Tooltip title={provider.legal_name} placement="top">
                        <div className="text-white text-[16px] font-semibold truncate overflow-hidden">
                            {provider.legal_name}
                        </div>
                    </Tooltip>
                </div>
                <img
                    src={showOrgMenu ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[14px] h-[14px] brightness-0 invert flex-shrink-0"
                />
            </div>
            {showOrgMenu && (
                <div className="flex flex-col px-[15px] py-[10px] border-t">
                    {userOrgs
                        .filter((org) => org.orgId !== activeOrg.orgId)
                        .sort((a, b) => a.orgName.localeCompare(b.orgName))
                        .map((org, index) => (
                            <OrgSelectorMenuItem
                                key={org.orgId}
                                org={org}
                                setShowOrgMenu={setShowOrgMenu}
                                isFirst={index === 0}
                                isLast={index === userOrgs.length - 2} // -2 because the last item is the active org
                            />
                        ))}
                </div>
            )}
        </div>
    );
}

SideBarOrgSelector.propTypes = {
    userOrgs: PropTypes.array.isRequired,
};
