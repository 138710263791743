import React from "react";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import NavigationTab from "../../../SideBar/NavigationTab";
import CapitalSvg from "../../../../assets/Capital";
import FinancialReportsSvg from "../../../../assets/FinancialReports";
import ConnectionsSvg from "../../../../assets/Connections";
import HandshakeSvg from "../../../../assets/Handshake";
import SavingsSvg from "../../../../assets/Savings";
import ValuationSvg from "../../../../assets/Valuation";
import GearSvg from "../../../../assets/Gear";
import { useGetBusinessEntitiesQuery } from "../../../../redux/slices/apiV2";
import {
    financialReportsSubtabs,
    partnerServicesSubtabs,
    onboardingFlowSubtabs,
    capitalSubtabs,
} from "../../../SideBar/constants";

// TODO: change specific icons for each tab.
function ProviderSelected() {
    const providerId = useGetProviderId();
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });

    businessEntityId =
        businessEntityId ||
        (businessEntities &&
            businessEntities.length > 0 &&
            businessEntities[0].id);

    return (
        <>
            {businessEntityId && (
                <NavigationTab
                    label="Financials"
                    path={`/provider/${providerId}/businessEntity/${businessEntityId}/financialReports`}
                    icon={<FinancialReportsSvg fill="white" />}
                    subtabs={financialReportsSubtabs}
                />
            )}
            <NavigationTab
                label="Capital"
                path={`/provider/${providerId}/capital`}
                icon={<CapitalSvg fill="white" />}
                subtabs={capitalSubtabs}
            />
            <NavigationTab
                label="Valuation"
                path={`/provider/${providerId}/valuation`}
                icon={<ValuationSvg fill="white" />}
            />
            <NavigationTab
                label="Savings"
                path={`/provider/${providerId}/savings`}
                icon={<SavingsSvg fill="white" />}
            />
            <NavigationTab
                label="Partner Services"
                path={`/provider/${providerId}/partnerServices`}
                icon={<HandshakeSvg fill="white" />}
                subtabs={partnerServicesSubtabs}
            />
            <NavigationTab
                label="Onboarding"
                path={`/provider/${providerId}/onboard`}
                icon={<ConnectionsSvg fill="white" />}
                subtabs={onboardingFlowSubtabs}
            />
            <NavigationTab
                label="Settings"
                path={`/provider/${providerId}/settings`}
                icon={<GearSvg fill="white" />}
            />
        </>
    );
}

export default ProviderSelected;
