/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import Line from "./Line";
import DrawsTable from "./DrawsTable";
import DrawModal from "./DrawModal";
import { SharedPageTitle, SharedSectionTitle } from "../../../shared/Text";
import { useGetAdvancedPaymentOnClaimsDataForBusinessEntityQuery } from "../../../../redux/slices/apiV2";

function LiveAdvancedPaymentOnClaimsLanding({ businessEntityId }) {
    const providerId = useGetProviderId();
    const { data: financingReport } =
        useGetAdvancedPaymentOnClaimsDataForBusinessEntityQuery({
            providerId,
            businessEntityId,
        });

    const [showDrawModal, setShowDrawModal] = useState(false);

    const [drawIndex, setDrawIndex] = useState(null);

    const drawsTableOnClick = (i) => {
        setDrawIndex(i);
        setShowDrawModal(true);
    };

    const drawModalOnClose = () => {
        setShowDrawModal(false);
    };

    if (!financingReport) return null;

    const {
        line_outstanding_balance_cents,
        line_available_balance_cents,
        draws_for_display,
    } = financingReport;

    return (
        <div className="flex flex-col justify-start p-[60px] gap-y-[40px]">
            <SharedPageTitle>Advanced Payment on Claims</SharedPageTitle>
            <Line
                outstandingBalanceCents={line_outstanding_balance_cents}
                availableBalanceCents={line_available_balance_cents}
            />
            <div className="flex flex-col gap-y-[30px] mt-[10px]">
                <SharedSectionTitle>Your draws</SharedSectionTitle>
                <DrawsTable
                    draws={draws_for_display}
                    onClick={drawsTableOnClick}
                    includeSeeRepaymentsColumn
                />
            </div>
            {showDrawModal && (
                <DrawModal
                    draw={draws_for_display[drawIndex]}
                    onClose={drawModalOnClose}
                />
            )}
        </div>
    );
}

LiveAdvancedPaymentOnClaimsLanding.propTypes = {
    businessEntityId: PropTypes.string.isRequired,
};

export default LiveAdvancedPaymentOnClaimsLanding;
