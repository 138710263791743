/* eslint-disable camelcase */
import React from "react";
import useGetProviderId from "../../hooks/useGetProviderId";
import ImproveFinancialHealth from "./ImproveFinancialHealth";
import ValuationEstimate from "./ValuationEstimate";
import ValuationCall from "./ValuationCall";
import ValuationExplanation from "./ValuationExplanation";
import { useGetValuationForProviderQuery } from "../../redux/slices/apiV2";

function Valuation() {
    const providerId = useGetProviderId();
    const { data: valuationData } = useGetValuationForProviderQuery({
        providerId,
    });

    if (!valuationData) return null;

    return (
        <div className="flex flex-col justify-start">
            <ValuationEstimate />
            {valuationData.valuations.length > 0 && <ValuationExplanation />}
            <ValuationCall />
            <ImproveFinancialHealth />
        </div>
    );
}

export default Valuation;
