import React from "react";
import PropTypes from "prop-types";
import { PropelAuthChangePasswordErrorType } from "../../../../constants";

export default function ChangePasswordError({ errorType, setContactSupport }) {
    if (errorType === PropelAuthChangePasswordErrorType.USER_ACCOUNT_LOCKED) {
        return (
            <div className="text-[12px] text-flychainError text-wrap">
                Your account is locked. Please{" "}
                <span
                    className="underline cursor-pointer text-flychainPurple-light"
                    onClick={() => setContactSupport(true)}
                >
                    contact support
                </span>
                .
            </div>
        );
    }

    // BAD_REQUEST, UNEXPECTED_OR_UNHANDLED
    return (
        <div className="text-[12px] text-flychainError text-wrap">
            An unexpected error occurred. Please try again later.
        </div>
    );
}

ChangePasswordError.propTypes = {
    errorType: PropTypes.oneOf(Object.values(PropelAuthChangePasswordErrorType))
        .isRequired,
    setContactSupport: PropTypes.func.isRequired,
};
