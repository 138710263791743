import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
    getButtonClassName,
    handleButtonMouseEnter,
    handleButtonMouseLeave,
} from "../utils";

function DayButton({
    year,
    month,
    day,
    onClick,
    maxDate,
    minDate,
    validUpUntil = maxDate,
    setHoverUnvalidatedToggle,
}) {
    const parsedMaxDate = dayjs(maxDate);
    const parsedMinDate = dayjs(minDate);
    const parsedValidUpUntil = dayjs(validUpUntil);
    const parsedDate = dayjs().year(year).month(month).date(day);

    const isYearExceeding = year > parsedMaxDate.year();
    const isMonthExceeding =
        year === parsedMaxDate.year() && month > parsedMaxDate.month();
    const isDayExceeding =
        year === parsedMaxDate.year() &&
        month === parsedMaxDate.month() &&
        day > parsedMaxDate.date();

    const isYearBelow = year < parsedMinDate.year();
    const isMonthBelow =
        year === parsedMinDate.year() && month < parsedMinDate.month();
    const isDayBelow =
        year === parsedMinDate.year() &&
        month === parsedMinDate.month() &&
        day < parsedMinDate.date();

    const disabled =
        isYearExceeding ||
        isMonthExceeding ||
        isDayExceeding ||
        isYearBelow ||
        isMonthBelow ||
        isDayBelow;

    const unvalidated = parsedDate.isBetween(
        parsedValidUpUntil,
        parsedMaxDate,
        "day",
        "(]" // exclusive of the start date and inclusive of the end date
    );

    const handleClick = () => {
        if (!disabled) {
            onClick(day);
        }
    };

    return (
        <div
            className={`w-[38px] text-[14px] font-medium text-center px-[10px] py-[8px] shadow-[0px_2px_8px_0px_#0000001f] rounded-xl ${getButtonClassName(
                disabled,
                unvalidated
            )}`}
            onClick={handleClick}
            onMouseEnter={() =>
                handleButtonMouseEnter(
                    disabled,
                    unvalidated,
                    setHoverUnvalidatedToggle
                )
            }
            onMouseLeave={() =>
                handleButtonMouseLeave(
                    disabled,
                    unvalidated,
                    setHoverUnvalidatedToggle
                )
            }
        >
            {day}
        </div>
    );
}

DayButton.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    day: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    minDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
    setHoverUnvalidatedToggle: PropTypes.func.isRequired,
};

DayButton.defaultProps = {
    validUpUntil: undefined,
};

export default DayButton;
