import React, { useState } from "react";
import PropTypes from "prop-types";
import { SharedButton } from "../../../../../shared";
import ValidUpUntilPicker from "./ValidUpUntilPicker";
import { useUpdateFinancialReportsValidUpUntilMutation } from "../../../../../../redux/slices/apiV2";

function UpdateValidUpUntil({
    entriesStart,
    validUpUntil,
    businessEntity,
    provider,
    setEmailModalData,
}) {
    const [validUpUntilDate, setValidUpUntilDate] = useState(validUpUntil);

    const onChange = (newValidUpUntilDate) => {
        setValidUpUntilDate(newValidUpUntilDate);
    };

    const [updateFinancialReportsValidUpUntil] =
        useUpdateFinancialReportsValidUpUntilMutation();

    const onClick = async () => {
        try {
            await updateFinancialReportsValidUpUntil({
                providerId: businessEntity.provider_id,
                businessEntityId: businessEntity.id,
                body: {
                    valid_up_until: validUpUntilDate,
                },
            });
            setEmailModalData({
                validUpUntil: validUpUntilDate,
                entriesStart,
                provider,
                businessEntity,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-row items-center">
            <ValidUpUntilPicker
                value={validUpUntilDate}
                onChange={onChange}
                // If the provider is Westfork then allow any day to be selected
                // TODO: remove this at a later date.
                onlyAllowLastDayOfMonth={
                    businessEntity.provider_id !==
                    "48a9f4aa-a29a-4abe-a451-db9c84023f65"
                }
            />
            <div className="ml-[10px]">
                <SharedButton
                    className="rounded-full w-fit px-[10px] py-[5px]"
                    onClick={onClick}
                    disabled={validUpUntil === validUpUntilDate}
                >
                    Update
                </SharedButton>
            </div>
        </div>
    );
}

UpdateValidUpUntil.propTypes = {
    entriesStart: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string.isRequired,
    businessEntity: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    setEmailModalData: PropTypes.func.isRequired,
};

export default UpdateValidUpUntil;
