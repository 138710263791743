import dayjs from "dayjs";
import { GRAPH_PERIOD } from "../../components/FinancialReports/constants";
import { useGetFinancialReportsEntriesStartQuery } from "../../redux/slices/apiV2";

function useGetGraphPeriodOptions({ providerId, businessEntityId }) {
    const { data, isFetching } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });

    if (!data || isFetching) return { data: undefined, isFetching };

    const currentYear = dayjs().year();
    const entriesStartYear = dayjs(data.entries_start).year();
    const validYears = [];
    for (let year = entriesStartYear; year <= currentYear; year += 1) {
        validYears.push({
            value: String(year),
            label: String(year),
        });
    }

    const periodOptions = [
        {
            value: GRAPH_PERIOD.LAST_12_MONTHS,
            label: "Last 12 Months",
        },
        ...validYears.reverse(),
    ];

    return {
        data: periodOptions,
        isFetching,
    };
}

export default useGetGraphPeriodOptions;
