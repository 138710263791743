import React from "react";
import { PropTypes } from "prop-types";

const getBackgroundColor = (color, disabled) => {
    if (color === "secondary") {
        return disabled ? "bg-[#D3D3D3] opacity-[80%]" : "bg-[#D3D3D3]";
    }

    if (color === "error") {
        return disabled ? "bg-flychainError opacity-[60%]" : "bg-flychainError";
    }

    return disabled
        ? "bg-flychainPurple-light opacity-[60%]"
        : "bg-flychainPurple-light";
};

const getTextColor = (color, disabled) => {
    if (color === "secondary") {
        return disabled ? "text-[#757575]" : "text-[#000000]";
    }
    return "text-white";
};

const getLoadingColor = (color) => {
    if (color === "secondary") {
        return "border-flychainPurple-light";
    }
    return "border-white";
};

function Button({
    onClick,
    disabled,
    children,
    className,
    color,
    loadingText,
}) {
    const handleClick = (event) => {
        if (!disabled && onClick) {
            onClick(event);
        }
    };

    const baseClassName =
        className ||
        "inline-block rounded-full px-[16px] py-[12px] w-fit h-fit text-center";

    const renderButtonContent = () => {
        if (loadingText) {
            return (
                <div className="flex flex-row justify-center items-center gap-x-[10px]">
                    <div
                        className={`text-sm font-medium whitespace-nowrap ${getTextColor(
                            color,
                            disabled
                        )}`}
                    >
                        {loadingText}
                    </div>
                    <div
                        className={`rounded-full animate-spin w-[20px] h-[20px] border-[6px] ${getLoadingColor(
                            color
                        )} border-t-transparent border-b-transparent`}
                    />
                </div>
            );
        }
        return (
            <div
                className={`text-sm font-medium whitespace-nowrap ${getTextColor(
                    color,
                    disabled
                )}`}
            >
                {children}
            </div>
        );
    };

    return (
        <div
            onClick={handleClick}
            className={`${baseClassName} ${getBackgroundColor(
                color,
                disabled
            )} ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
        >
            {renderButtonContent()}
        </div>
    );
}

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    loadingText: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    className: "",
    color: "primary",
    onClick: undefined,
    loadingText: undefined,
};

export default Button;
