import React from "react";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import MonthlyGraph from "../../../Graphs/MonthlyGraph";
import Summary from "./Summary";
import Table from "./Table";
import { SharedBackIconButton } from "../../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../../shared/Text";
import { useGetExpensesByMerchantQuery } from "../../../../redux/slices/apiV2";

function MerchantDetailView() {
    const providerId = useGetProviderId();
    const { businessEntityId, merchantId } = useParams();

    const { data: expensesByMerchantData } = useGetExpensesByMerchantQuery({
        providerId,
        businessEntityId,
    });

    if (!expensesByMerchantData) return null;

    const merchantDetail = expensesByMerchantData.find(
        (merchant) => merchant.id === merchantId
    );

    if (!merchantDetail) {
        throw new Error(`No merchant with id ${merchantId} found`);
    }

    return (
        <div className="flex flex-col gap-y-[30px]">
            <div className="flex flex-row items-center gap-x-[20px] pt-[60px] ml-[30px]">
                <SharedBackIconButton />
                <SharedPageTitle>{merchantDetail.expense_name}</SharedPageTitle>
            </div>
            <div className="flex flex-col gap-y-[30px] px-[60px] pb-[60px]">
                <Summary merchantDetail={merchantDetail} />
                <SharedSectionTitle>Total Spend Per Month</SharedSectionTitle>
                <MonthlyGraph data={merchantDetail.monthly_totals} />
                <div className="w-4/5">
                    <Table transactions={merchantDetail.transactions} />
                </div>
            </div>
        </div>
    );
}

export default MerchantDetailView;
