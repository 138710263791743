/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import { SharedButton } from "../../../shared";
import ChevronDownSvg from "../../../../assets/ChevronDown";
import BreakConnectionMenuItem from "./BreakConnectionMenuItem";
import RestoreConnectionMenuItem from "./RestoreConnectionMenuItem";

const getActions = (plaidItem, handleClose) => {
    const { is_admin_linked, plaid_error, internal_plaid_item_id } = plaidItem;
    const actions = [
        <BreakConnectionMenuItem
            key="break_connection"
            internalPlaidItemId={internal_plaid_item_id}
            closeMenu={handleClose}
        />,
    ];
    if (plaid_error && plaid_error.error_code && is_admin_linked) {
        actions.push(
            <RestoreConnectionMenuItem
                key="restore_connection"
                internalPlaidItemId={internal_plaid_item_id}
                closeMenu={handleClose}
            />
        );
    }

    return actions;
};

function AdminActions({ plaidItem }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        if (event && event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <SharedButton
                className="rounded-[6px] px-[10px] py-[8px]"
                onClick={handleClick}
            >
                <div className="flex flex-row items-center gap-[5px] ">
                    Admin Actions
                    <ChevronDownSvg fill="#FFFFFF" width={20} height={20} />
                </div>
            </SharedButton>
            <Menu
                id="admin-actions"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "&:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
            >
                {getActions(plaidItem, handleClose)}
            </Menu>
        </>
    );
}

AdminActions.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default AdminActions;
