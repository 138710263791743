import React from "react";
import useGetProviderId from "../../hooks/useGetProviderId";
import { useGetProviderQuery } from "../../redux/slices/apiV2";
import PLATFORM_STATES from "./states";
import ConnectPlaidItems from "./ProviderSetupFlow/ConnectPlaidItems";
import SelectBankAccount from "./ProviderSetupFlow/SelectBankAccount";
import ProviderSetupFlowComplete from "./ProviderSetupFlow/ProviderSetupFlowComplete";
import ProviderSetupTabs from "./ProviderSetupFlow/ProviderSetupTabs";
import ProviderDashboard from "../ProviderDashboard";
import SideBar from "../SideBar";
import Content from "../Content";

function renderProviderSetupFlow(provider) {
    switch (provider.platform_state) {
        case PLATFORM_STATES.PROVIDER_CREATED:
            return <ConnectPlaidItems />;
        case PLATFORM_STATES.PLAID_CONNECTED:
            return <SelectBankAccount />;
        case PLATFORM_STATES.PLATFORM_PENDING:
            return <ProviderSetupFlowComplete />;
        default:
            throw new Error("No content for provider status");
    }
}

function ProviderContent() {
    const providerId = useGetProviderId();
    const { data: provider } = useGetProviderQuery({
        providerId,
    });

    if (!provider) {
        return null;
    }

    if (provider.platform_state === PLATFORM_STATES.PLATFORM_ACTIVE) {
        return <ProviderDashboard />;
    }

    return (
        <>
            <SideBar>
                <ProviderSetupTabs state={provider.platform_state} />
            </SideBar>
            <Content>{renderProviderSetupFlow(provider)}</Content>
        </>
    );
}

export default ProviderContent;
