import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";
import LedgerTable from "../../LedgerTable";
import { SharedUnverifiedDataWarningBanner } from "../../../shared";
import { SharedDateRangePicker } from "../../../shared/Dates";
import {
    selectDateRange,
    updateDateRange,
} from "../../../../redux/slices/dateRange";
import {
    useGetTopExpensesQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../../redux/slices/apiV2";
import useValidateDateRange from "../../../../hooks/useValidateDateRange";
import useResetDateRangeIfInvalid from "../../../../hooks/useResetDateRangeIfInvalid";

const HEADERS = ["Category", "Percentage of Spend", "Total Spend", ""];

function ExpensesByCategory() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    const { data: topExpensesData } = useGetTopExpensesQuery({
        providerId,
        businessEntityId,
        params: { ...dateRange },
    });
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    useResetDateRangeIfInvalid(entriesStartData, validUpUntilData);

    if (!topExpensesData || !validUpUntilData || !entriesStartData) return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    return (
        <div className="flex flex-col gap-y-[20px]">
            <div className="flex flex-row justify-between pr-[20px]">
                <SharedDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                    minDate={entriesStartData.entries_start}
                    validUpUntil={validUpUntilData.valid_up_until}
                />
            </div>
            {dateRangeUnvalidated && (
                <SharedUnverifiedDataWarningBanner
                    validUpUntil={validUpUntilData.valid_up_until}
                />
            )}
            <LedgerTable data={topExpensesData} headers={HEADERS} />
        </div>
    );
}

export default ExpensesByCategory;
