import React from "react";
import useGetProviderId from "../../../hooks/useGetProviderId";
import { SharedCard } from "../../shared";
import OrgMemberTable from "./OrgMemberTable";
import PendingInvitesTable from "./PendingInvitesTable";
import { useGetProviderQuery } from "../../../redux/slices/apiV2";

export default function OrganizationSettings() {
    const providerId = useGetProviderId();

    const { data: provider } = useGetProviderQuery({
        providerId,
    });

    if (!provider) return null;

    return (
        <SharedCard>
            <div className="flex flex-col">
                <div className="text-2xl font-bold pb-[20px]">
                    {provider.legal_name}
                </div>
                <OrgMemberTable />
                <PendingInvitesTable />
            </div>
        </SharedCard>
    );
}
