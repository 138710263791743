import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { useGetNetCashFlowGraphDataQuery } from "../../../../redux/slices/apiV2";
import SharedNetCashFlowGraph from "../../../Graphs/NetCashFlowGraph";

function NetCashFlowGraph({ period }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: netCashFlowGraphData } = useGetNetCashFlowGraphDataQuery({
        providerId,
        businessEntityId,
        params: { period },
    });

    if (!netCashFlowGraphData) {
        return null;
    }

    return <SharedNetCashFlowGraph data={netCashFlowGraphData} />;
}

NetCashFlowGraph.propTypes = {
    period: PropTypes.string.isRequired,
};

export default NetCashFlowGraph;
