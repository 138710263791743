import React, { useState } from "react";
import CalendlyModal from "../../CalendlyModal";
import { SharedButton } from "../../shared";
import { SharedPageTitle } from "../../shared/Text";
import TreasuryGraphic from "../../../assets/TreasuryGraphic.svg";
import FlychainLogoSvg from "../../../assets/FlychainLogo.svg";
import TreasureLogoPng from "../../../assets/TreasureLogo.png";
import { CALENDLY_URLS } from "../../CalendlyModal/constants";

function TreasuryHeaderSection() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    const onClickSignUp = () => {
        window.open("https://app.treasurefi.com/sign-up/Flychain", "_blank");
    };

    const onClickLogin = () => {
        window.open("https://app.treasurefi.com/sign-in", "_blank");
    };

    return (
        <div className="flex flex-row p-[60px] bg-gradient-to-tr from-flychainPurple-white to-white">
            <div className="flex flex-col flex-1 gap-y-[30px]">
                <div className="flex flex-row gap-x-[30px] items-center">
                    <img
                        src={FlychainLogoSvg}
                        alt="Flychain Logo"
                        className="h-[35px]"
                    />
                    <span className="text-flychainGray text-[30px] font-medium">
                        x
                    </span>
                    <img
                        src={TreasureLogoPng}
                        alt="Treasure Logo"
                        className="h-[30px]"
                    />
                </div>
                <SharedPageTitle>
                    Institutional Treasury Management
                </SharedPageTitle>
                <div className="text-[14px]">
                    Make your money work for you by investing in low risk asset
                    classes such as money market funds, treasuries and corporate
                    bonds.
                </div>
                <div className="flex flex-row gap-x-[10px]">
                    <SharedButton onClick={() => setShowCalendlyModal(true)}>
                        Learn More
                    </SharedButton>
                    <SharedButton onClick={onClickSignUp} color="secondary">
                        Sign Up
                    </SharedButton>
                    <SharedButton onClick={onClickLogin} color="secondary">
                        Login
                    </SharedButton>
                </div>
            </div>
            <div className="flex flex-row flex-1 justify-center items-center">
                <img
                    className="w-[25vw]"
                    src={TreasuryGraphic}
                    alt="TreasurySvg"
                />
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.TREASURY_CALL}
                />
            )}
        </div>
    );
}

export default TreasuryHeaderSection;
