/* eslint-disable react/jsx-filename-extension */
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { AuthProvider } from "@propelauth/react";
import store from "./redux/store";
import "./index.css";
import App from "./components/App";
import "./mixpanel";
import "./sentry";
import "./styles/variables.css";
import AuthWrapper from "./components/AuthWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL}>
        <ReduxProvider store={store}>
            <AuthWrapper>
                <App />
            </AuthWrapper>
        </ReduxProvider>
    </AuthProvider>
);
