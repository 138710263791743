import { useEffect } from "react";
import { CALENDLY_PARENT_ELEMENT_ID } from "./constants";

const initInlineWidget = (url, prefill) => {
    // If there is an existing Calendly iframe then we don't want to load another one.
    const iframes = document.querySelectorAll(`iframe[src*="${url}"]`);
    if (iframes.length > 0) {
        return;
    }

    // eslint-disable-next-line no-undef
    Calendly.initInlineWidget({
        url,
        parentElement: document.getElementById(CALENDLY_PARENT_ELEMENT_ID),
        prefill,
    });
};

export const useInitInlineWidget = (
    user,
    calendlyUrl,
    customAnswersPrefill
) => {
    useEffect(() => {
        const prefill = {
            name: "",
            email: user.email,
            customAnswers: customAnswersPrefill,
        };
        initInlineWidget(calendlyUrl, prefill);
    }, [user, calendlyUrl, customAnswersPrefill]);
};

const isCalendlyEvent = (e) =>
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.") === 0;

export const useOnCalendlyEventScheduled = (onCalendlyEventScheduled) => {
    useEffect(() => {
        const handleMessage = (e) => {
            if (isCalendlyEvent(e)) {
                if (e.data.event === "calendly.event_scheduled") {
                    onCalendlyEventScheduled();
                }
            }
        };

        window.addEventListener("message", handleMessage);

        // Clean up function to remove event listener
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [onCalendlyEventScheduled]);
};
