import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import * as Sentry from "@sentry/react";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectUser } from "../../../../redux/slices/authentication";
import { PropelAuthChangeEmailErrorType } from "../../../../constants";
import { SharedButton, SharedSupportModal } from "../../../shared";
import {
    SharedFormTextInput,
    SharedRequiredMessage,
} from "../../../shared/Inputs";
import ChangeEmailError from "./ChangeEmailError";
import ChangeEmailSuccessModal from "./ChangeEmailSuccessModal";
import DownCaret from "../../../../assets/DownCaret.svg";
import RightCaret from "../../../../assets/RightCaret.svg";

const defaultValues = {
    newEmail: "",
    password: "",
};

const schema = yup.object({
    newEmail: yup.string().email().required(SharedRequiredMessage),
    password: yup.string().required(SharedRequiredMessage),
});

export default function ChangeEmail() {
    const [showContent, setShowContent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorType, setErrorType] = useState(null);
    const [contactSupport, setContactSupport] = useState(false);
    const { updateEmail } = useAuthFrontendApis();
    const currentUser = useSelector(selectUser);

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const newEmail = watch("newEmail");
    const password = watch("password");

    const handleErrorResponse = (newErrorType, error) => {
        Sentry.captureException(new Error(JSON.stringify(error)));
        setErrorType(newErrorType);
    };

    const onEmailSubmit = async (formData) => {
        setIsLoading(true);
        setErrorType(null);
        const response = await updateEmail({
            new_email: formData.newEmail,
            password: formData.password,
        });
        response.handle({
            success: () => {
                setIsSuccess(true);
                setIsLoading(false);
            },
            badRequest: (error) => {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.BAD_REQUEST,
                    error
                );
                setIsLoading(false);
            },
            failedToSendEmail: async (error) => {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.FAILED_TO_SEND_EMAIL,
                    error
                );
                setIsLoading(false);
            },
            incorrectPassword: () => {
                setError("password", {
                    message: "The password is incorrect",
                });
                setIsLoading(false);
            },
            userAccountLocked: (error) => {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.USER_ACCOUNT_LOCKED,
                    error
                );
                setIsLoading(false);
            },
            rateLimit: (error) => {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.RATE_LIMIT,
                    error
                );
                setIsLoading(false);
            },
            emailChangeDisabled: (error) => {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.EMAIL_CHANGE_DISABLED,
                    error
                );
                setIsLoading(false);
            },
            cannotChangeEmailDueToOrgMembership: (error) => {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.ORG_MEMBERSHIP,
                    error
                );
                setIsLoading(false);
            },
            unexpectedOrUnhandled(error) {
                handleErrorResponse(
                    PropelAuthChangeEmailErrorType.UNEXPECTED_OR_UNHANDLED,
                    error
                );
                setIsLoading(false);
            },
        });
    };

    const triggerEmailUpdate = () => {
        handleSubmit(onEmailSubmit)();
    };

    return (
        <div className="flex flex-col border-b py-[16px] gap-y-[20px]">
            <div
                className="flex flex-row items-center cursor-pointer gap-x-[8px]"
                onClick={() => setShowContent(!showContent)}
            >
                <div className="text-[18px] font-semibold">Change Email</div>
                <img
                    src={showContent ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[12px] h-[12px] brightness-0"
                />
            </div>
            {showContent && (
                <>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-x-[12px]">
                            <div className="text-sm text-flychainGray">
                                Current email:
                            </div>
                            <div>{currentUser.email}</div>
                        </div>
                        <div
                            className={`focus-within:mt-[12px] ${
                                newEmail && "mt-[12px]"
                            }`}
                        >
                            <SharedFormTextInput
                                className="w-[250px]"
                                name="newEmail"
                                label="New email"
                                control={control}
                                error={errors.newEmail}
                            />
                        </div>
                        <div
                            className={`focus-within:mt-[12px] ${
                                password && "mt-[12px]"
                            }`}
                        >
                            <SharedFormTextInput
                                className="w-[250px]"
                                type="password"
                                name="password"
                                label="Password"
                                control={control}
                                error={errors.password}
                            />
                        </div>
                    </div>
                    {errorType && (
                        <ChangeEmailError
                            errorType={errorType}
                            setContactSupport={setContactSupport}
                        />
                    )}
                    <SharedButton
                        onClick={triggerEmailUpdate}
                        disabled={
                            isLoading ||
                            [
                                PropelAuthChangeEmailErrorType.USER_ACCOUNT_LOCKED,
                                PropelAuthChangeEmailErrorType.EMAIL_CHANGE_DISABLED,
                                PropelAuthChangeEmailErrorType.ORG_MEMBERSHIP,
                            ].includes(errorType)
                        }
                        loadingText={
                            isLoading ? "Updating email..." : undefined
                        }
                    >
                        Update email
                    </SharedButton>
                </>
            )}
            {isSuccess && (
                <ChangeEmailSuccessModal
                    oldEmail={currentUser.email}
                    newEmail={newEmail}
                />
            )}
            {contactSupport && (
                <SharedSupportModal onClose={() => setContactSupport(false)} />
            )}
        </div>
    );
}
