import { isRejected } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import raiseError from "../slices/error/thunks/raiseError";

const convertResponseToError = (response) =>
    new Error(
        `Response error: ${response.status} ${response.statusText} at ${response.url}`
    );

const convertToError = (thing) => {
    if (thing instanceof Error) {
        return thing;
    }
    if (thing instanceof Response) {
        return convertResponseToError(thing);
    }
    if (typeof thing === "string") {
        return new Error(thing);
    }
    if (thing instanceof Object) {
        return new Error(JSON.stringify(thing));
    }
    const error = Error("Error of unknown type");
    error.data = thing;
    return error;
};

const asyncThunkErrorMonitor = (store) => (next) => (action) => {
    // bypass global error handling for collectPayment on Admin Collection Flow
    if (
        action.type.startsWith("adminApi") &&
        action.meta?.arg?.endpointName === "collectPayment"
    ) {
        return next(action);
    }
    if (isRejected(action)) {
        const response = action.meta?.baseQueryMeta?.response;
        if (response) {
            // 426 Upgrade Required triggers a full page reload and cache clear
            if (response.status === 426) {
                window.location.href = `${
                    window.location.origin
                }/?v=${dayjs().valueOf()}/${window.location.hash}`;
            } else {
                store.dispatch(raiseError(convertToError(response)));
            }
        } else {
            store.dispatch(raiseError(convertToError(action)));
        }
    }
    return next(action);
};

export default asyncThunkErrorMonitor;
