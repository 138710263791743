import React from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";
import ExportReportButton from "../../ExportReportButton";
import Summary from "./Summary";
import Section from "./Section";
import { SharedUnverifiedDataWarningBanner } from "../../../shared";
import { SharedSectionTitle } from "../../../shared/Text";
import { SharedDateRangePicker } from "../../../shared/Dates";
import {
    updateDateRange,
    selectDateRange,
} from "../../../../redux/slices/dateRange";
import {
    useGetCashFlowReportQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../../redux/slices/apiV2";
import useValidateDateRange from "../../../../hooks/useValidateDateRange";
import useResetDateRangeIfInvalid from "../../../../hooks/useResetDateRangeIfInvalid";
import useGetProviderId from "../../../../hooks/useGetProviderId";

function Report() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    const { data: cashFlowReportData } = useGetCashFlowReportQuery({
        providerId,
        businessEntityId,
        params: { ...dateRange },
    });
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    useResetDateRangeIfInvalid(entriesStartData, validUpUntilData);

    if (!cashFlowReportData || !validUpUntilData || !entriesStartData)
        return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    const inflows = cashFlowReportData.inflows.record;
    const outflows = cashFlowReportData.outflows.record;

    return (
        <div>
            <div className="mb-[20px]">
                <SharedSectionTitle>Cash Flow Report</SharedSectionTitle>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-row justify-between pr-[20px] items-center">
                    <SharedDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                        minDate={entriesStartData.entries_start}
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                    <ExportReportButton />
                </div>
                {dateRangeUnvalidated && (
                    <SharedUnverifiedDataWarningBanner
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                )}
                <Summary data={cashFlowReportData} />
                <div className="flex flex-col gap-y-[20px]">
                    <Section
                        name={inflows.name}
                        totalAmountCents={inflows.total_amount_cents}
                        isInflow
                    >
                        {inflows.children}
                    </Section>
                    <Section
                        name={outflows.name}
                        totalAmountCents={outflows.total_amount_cents}
                        isInflow={false}
                    >
                        {outflows.children}
                    </Section>
                </div>
            </div>
        </div>
    );
}

export default Report;
