import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Line,
    Legend,
    ResponsiveContainer,
} from "recharts";
import useGetProviderId from "../../../hooks/useGetProviderId";
import { getCurrencyString } from "../../../utils";
import {
    formatMonthYearStringForXAxis,
    tickStyle,
    isAfterValidDate,
    splitMetric,
    VALID_DATE_FORMATS,
} from "../utils";
import SharedDefsContextProvider, {
    useSharedDefs,
} from "../SharedDefsContextProvider";
import NetProfitGraphTooltip from "./NetProfitGraphTooltip";
import NetProfitGraphLegend from "./NetProfitGraphLegend";
import { useGetFinancialReportsValidUpUntilQuery } from "../../../redux/slices/apiV2";

function NetProfitGraphContent({ data }) {
    const defs = useSharedDefs();
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });

    const dataPoints = useMemo(() => {
        if (data.length <= 12) {
            return data;
        }
        return data.slice(-12);
    }, [data]);

    if (!validUpUntilData) return null;

    const validUpUntil = validUpUntilData.valid_up_until;

    const splitDataPoints = dataPoints.map((point, index) => {
        const revenue = splitMetric(
            point.revenue_cents,
            point.month,
            VALID_DATE_FORMATS.MMM_YYYY,
            validUpUntil
        );
        const expense = splitMetric(
            point.expense_cents,
            point.month,
            VALID_DATE_FORMATS.MMM_YYYY,
            validUpUntil
        );
        const netProfit = splitMetric(
            point.net_profit_cents,
            point.month,
            VALID_DATE_FORMATS.MMM_YYYY,
            validUpUntil
        );

        // If the net profit is verified and the next month is after the valid up until date, set the unverified net profit to the verified net profit
        // This is to ensure that the graph is not showing a gap in the line chart
        if (
            netProfit.verified &&
            index < dataPoints.length - 1 && // Check if there is a next data point
            isAfterValidDate(
                dataPoints[index + 1].month,
                VALID_DATE_FORMATS.MMM_YYYY,
                validUpUntil
            ) // Check if the next datapoint is after the valid up until date
        ) {
            netProfit.unverified = netProfit.verified;
        }

        return {
            ...point,
            revenue_cents: revenue.verified,
            unverified_revenue_cents: revenue.unverified,
            expense_cents: expense.verified,
            unverified_expense_cents: expense.unverified,
            net_profit_cents: netProfit.verified,
            unverified_net_profit_cents: netProfit.unverified,
        };
    });

    return (
        <ResponsiveContainer width="100%" height={200}>
            <ComposedChart data={splitDataPoints} stackOffset="sign">
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip
                    content={
                        <NetProfitGraphTooltip validUpUntil={validUpUntil} />
                    }
                />
                <Legend content={<NetProfitGraphLegend />} />
                {defs}
                <Bar
                    dataKey="revenue_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                />
                <Bar
                    dataKey="unverified_revenue_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                    fillOpacity={0.4}
                    stroke="#8D58FF"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                />
                <Bar
                    dataKey="expense_cents"
                    stackId="stack"
                    barSize={24}
                    fill="#CCCCCC"
                />
                <Bar
                    dataKey="unverified_expense_cents"
                    stackId="stack"
                    barSize={24}
                    fill="#CCCCCC"
                    fillOpacity={0.4}
                    stroke="#8D58FF"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                />
                <Line
                    type="monotone"
                    dataKey="net_profit_cents"
                    stroke="#F8CE39"
                    strokeWidth={1}
                    dot={false}
                />
                <Line
                    type="monotone"
                    dataKey="unverified_net_profit_cents"
                    stroke="#F8CE39"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                    dot={false}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        revenue_cents: PropTypes.number.isRequired,
        expense_cents: PropTypes.number.isRequired,
        net_profit_cents: PropTypes.number.isRequired,
    }).isRequired
);

NetProfitGraphContent.propTypes = {
    data: dataProps.isRequired,
};

function NetProfitGraph({ data }) {
    return (
        <SharedDefsContextProvider>
            <NetProfitGraphContent data={data} />
        </SharedDefsContextProvider>
    );
}

NetProfitGraph.propTypes = {
    data: dataProps.isRequired,
};

export default NetProfitGraph;
