import React from "react";
import PropTypes from "prop-types";

function Badge({ color, children }) {
    const getBackgroundColor = () => {
        switch (color) {
            case "green":
                return "bg-flychainGreen";
            case "gray":
                return "bg-flychainGray";
            case "red":
                return "bg-flychainError";
            default:
                throw new Error("Invalid color specified");
        }
    };

    return (
        <div
            className={`inline-block rounded-full ${getBackgroundColor()} px-[8px] py-[4px]`}
        >
            {children}
        </div>
    );
}

Badge.propTypes = {
    color: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
};

export default Badge;
