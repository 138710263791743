import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import { useAuthInfo } from "@propelauth/react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/authentication";
import {
    PropelAuthChangeNameResultType,
    PropelAuthNameChangeBadRequestErrors,
} from "../../../../constants";
import { SharedModal, SharedButton } from "../../../shared";
import {
    SharedFormTextInput,
    SharedRequiredMessage,
} from "../../../shared/Inputs";
import { SharedSectionTitle } from "../../../shared/Text";
import ChangeNameResultMessage from "./ChangeNameResultMessage";

export default function ChangeNameModal({ setEditingName }) {
    const [isLoading, setIsLoading] = useState(false);
    const [changeNameResult, setChangeNameResult] = useState(null);
    const { updateUserMetadata } = useAuthFrontendApis();
    const user = useSelector(selectUser);
    const authInfo = useAuthInfo();

    const defaultValues = {
        firstName: user.firstName,
        lastName: user.lastName,
    };

    const schema = yup.object({
        firstName: yup.string().required(SharedRequiredMessage),
        lastName: yup.string().required(SharedRequiredMessage),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        watch,
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const firstName = watch("firstName");
    const lastName = watch("lastName");

    const handleErrorResponse = (newErrorType, error) => {
        Sentry.captureException(new Error(JSON.stringify(error)));
        setChangeNameResult(newErrorType);
    };

    const handleBadRequest = (error) => {
        if (
            error.field_errors.first_name ===
            PropelAuthNameChangeBadRequestErrors.FIRST_NAME_LENGTH
        ) {
            setError("firstName", {
                message: error.user_facing_errors.first_name,
            });
        }
        if (
            error.field_errors.last_name ===
            PropelAuthNameChangeBadRequestErrors.LAST_NAME_LENGTH
        ) {
            setError("lastName", {
                message: error.user_facing_errors.last_name,
            });
        }
        if (!error.field_errors.first_name && !error.field_errors.last_name) {
            handleErrorResponse(
                PropelAuthChangeNameResultType.BAD_REQUEST,
                error
            );
        }
    };

    const onNameSubmit = async (formData) => {
        setChangeNameResult(null);
        setIsLoading(true);
        const response = await updateUserMetadata({
            first_name: formData.firstName.trim(),
            last_name: formData.lastName.trim(),
        });
        response.handle({
            success: async () => {
                await authInfo.refreshAuthInfo();
                setChangeNameResult(PropelAuthChangeNameResultType.SUCCESS);
                setIsLoading(false);
            },
            badRequest: (error) => {
                handleBadRequest(error);
                setIsLoading(false);
            },
            unexpectedOrUnhandled: (error) => {
                handleErrorResponse(
                    PropelAuthChangeNameResultType.UNEXPECTED_OR_UNHANDLED,
                    error
                );
                setIsLoading(false);
            },
        });
    };

    const triggerNameUpdate = () => {
        handleSubmit(onNameSubmit)();
    };

    const canUpdateName =
        !isLoading &&
        !errors.firstName &&
        !errors.lastName &&
        (user.firstName.trim() !== firstName.trim() ||
            user.lastName.trim() !== lastName.trim());

    return (
        <SharedModal
            cardClassName="gap-y-[20px] max-w-[60%]"
            onClose={() => {
                setEditingName(false);
            }}
        >
            <SharedSectionTitle>Change your name</SharedSectionTitle>
            <SharedFormTextInput
                className="w-[300px]"
                name="firstName"
                label="First Name"
                control={control}
                error={errors.firstName}
            />
            <SharedFormTextInput
                className="w-[300px]"
                name="lastName"
                label="Last Name"
                control={control}
                error={errors.lastName}
            />
            {changeNameResult && (
                <ChangeNameResultMessage changeNameResult={changeNameResult} />
            )}
            <div className="flex flex-row justify-center">
                <SharedButton
                    onClick={triggerNameUpdate}
                    disabled={!canUpdateName}
                    loadingText={isLoading ? "Updating name..." : undefined}
                >
                    Update name
                </SharedButton>
            </div>
        </SharedModal>
    );
}

ChangeNameModal.propTypes = {
    setEditingName: PropTypes.func.isRequired,
};
