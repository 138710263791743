import React from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function StateSelectInput({
    name,
    label,
    options,
    disableOption,
    currentValue,
    onChange,
    error,
    className,
    disabled,
    size,
    variant,
}) {
    const labelId = `${name}-label`;

    const menuItems = options.map((option) => {
        const value = typeof option === "string" ? option : option.value;
        const itemLabel = typeof option === "string" ? option : option.label;

        return (
            <MenuItem
                key={value}
                value={value}
                disabled={disableOption && disableOption(value)}
            >
                {itemLabel}
            </MenuItem>
        );
    });

    return (
        <FormControl
            name={name}
            className={className}
            variant={variant}
            size={size}
            error={!!error}
        >
            {label && (
                <InputLabel
                    id={labelId}
                    shrink={!!currentValue}
                    sx={{
                        "&.Mui-focused": { color: "#8D58FF" },
                        zIndex: 0,
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Select
                labelId={labelId}
                id={name}
                label={label}
                disabled={disabled}
                onChange={(event) => onChange(event.target.value)}
                value={currentValue}
                sx={{
                    "&:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
            >
                {menuItems}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

StateSelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ]).isRequired
    ).isRequired,
    disableOption: PropTypes.func,
    currentValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
};

StateSelectInput.defaultProps = {
    label: undefined,
    disableOption: undefined,
    className: "",
    disabled: false,
    error: "",
    size: undefined,
    variant: "standard",
};

export default StateSelectInput;
