import React from "react";
import PropTypes from "prop-types";
import { SharedModal } from "../../../shared";
import FlychainLogoSvg from "../../../../assets/FlychainLogo.svg";

export default function ChangeEmailSuccessModal({ newEmail }) {
    return (
        <SharedModal
            cardClassName="gap-y-[20px] max-w-[60%] justify-center items-center text-center"
            onClose={() => {
                window.location.reload();
            }}
        >
            <img
                src={FlychainLogoSvg}
                alt="Flychain Logo"
                className="w-[160px] mb-[5px]"
            />
            <div className="text-[16px] text-flychainPurple-dark">
                A confirmation email has been sent to{" "}
                <span className="text-[16px] text-flychainPurple-dark font-semibold">
                    {newEmail}
                </span>
                .
            </div>
            <div className="text-[16px] text-flychainPurple-dark">
                Please check your email and click the &quot;Update your
                email&quot; button to complete the email change.
            </div>
            <div className="text-[16px] text-flychainPurple-dark">
                If do not want to change your email to{" "}
                <span className="text-[16px] text-flychainPurple-dark font-semibold">
                    {newEmail}
                </span>
                , you can disregard the confirmation email.
            </div>
        </SharedModal>
    );
}

ChangeEmailSuccessModal.propTypes = {
    newEmail: PropTypes.string.isRequired,
};
