import React from "react";
import PropTypes from "prop-types";
import {
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../../../redux/slices/apiV2";
import UpdateValidUpUntil from "./UpdateValidUpUntil";
import UpdateEntriesStart from "./UpdateEntriesStart";

function BusinessEntity({ businessEntity, provider, setEmailModalData }) {
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId: businessEntity.provider_id,
        businessEntityId: businessEntity.id,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId: businessEntity.provider_id,
        businessEntityId: businessEntity.id,
    });

    return (
        <div className="flex flex-row justify-between items-center h-fit pt-[2px] gap-x-[10px]">
            <div className="flex flex-col h-fit mb-[3px]">
                <div className="text-[16px]">{businessEntity.legal_name}</div>
                <div className="text-sm text-flychainGray">
                    {businessEntity.id}
                </div>
            </div>
            <div className="flex flex-row gap-x-[15px] items-center h-fit shrink-0">
                {entriesStartData && validUpUntilData && (
                    <>
                        <UpdateEntriesStart
                            entriesStart={entriesStartData.entries_start}
                            validUpUntil={validUpUntilData.valid_up_until}
                            businessEntity={businessEntity}
                            provider={provider}
                            setEmailModalData={setEmailModalData}
                        />
                        <UpdateValidUpUntil
                            validUpUntil={validUpUntilData.valid_up_until}
                            entriesStart={entriesStartData.entries_start}
                            businessEntity={businessEntity}
                            provider={provider}
                            setEmailModalData={setEmailModalData}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

BusinessEntity.propTypes = {
    businessEntity: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    setEmailModalData: PropTypes.func.isRequired,
};

export default BusinessEntity;
