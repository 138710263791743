import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import ExportReportButton from "../../ExportReportButton";
import Section from "../../Section";
import { SharedSectionTitle, SharedLabel } from "../../../shared/Text";
import { SharedDatePicker } from "../../../shared/Dates";
import { updateEndDate, selectEndDate } from "../../../../redux/slices/endDate";
import {
    useGetBalanceSheetQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../../redux/slices/apiV2";
import useResetEndDateIfInvalid from "../../../../hooks/useResetEndDateIfInvalid";

function Report() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const endDate = useSelector(selectEndDate);
    const dispatch = useDispatch();
    const setEndDate = (newEndDate) => {
        dispatch(updateEndDate(newEndDate));
    };

    const { data: balanceSheetData } = useGetBalanceSheetQuery({
        providerId,
        businessEntityId,
        params: { endDate },
    });
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });

    useResetEndDateIfInvalid(entriesStartData, validUpUntilData);

    if (!balanceSheetData || !entriesStartData || !validUpUntilData)
        return null;

    const asset = balanceSheetData.asset.record;

    const liability = balanceSheetData.liability.record;

    const equity = balanceSheetData.equity.record;

    return (
        <div>
            <div className="mb-[20px]">
                <SharedSectionTitle>Balance Sheet</SharedSectionTitle>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-row justify-between pr-[20px] items-center">
                    <div className="flex flex-col gap-y-[3px]">
                        <SharedLabel>End Date</SharedLabel>
                        <SharedDatePicker
                            date={endDate}
                            onChange={setEndDate}
                            maxDate={validUpUntilData.valid_up_until}
                            minDate={entriesStartData.entries_start}
                        />
                    </div>
                    <ExportReportButton />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <Section
                        name={asset.name}
                        totalAmountCents={asset.total_amount_cents}
                    >
                        {asset.children}
                    </Section>
                    <Section
                        name={liability.name}
                        totalAmountCents={liability.total_amount_cents}
                    >
                        {liability.children}
                    </Section>
                    <Section
                        name={equity.name}
                        totalAmountCents={equity.total_amount_cents}
                    >
                        {equity.children}
                    </Section>
                </div>
            </div>
        </div>
    );
}

export default Report;
