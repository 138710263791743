import React from "react";
import BusinessEntityOverview from "../BusinessEntity/BusinessEntityOverview";
import Connections from "../FinancialReports/Connections";
import AccountSettings from "./AccountSettings";
import OrganizationSettings from "./OrganizationSettings";
import { SharedHorizontalTabs } from "../shared";
import { SharedPageTitle } from "../shared/Text";

// An array of the tabs to be displayed
const TABS = [
    { key: "connections", label: "Connections" },
    { key: "businessEntities", label: "Business Entities" },
    { key: "account", label: "Account" },
    { key: "organization", label: "Organization" },
];

// Render the content for the active tab
function renderSettingsContent(currentTabIndex) {
    switch (currentTabIndex) {
        case 0:
            return <Connections />;
        case 1:
            return <BusinessEntityOverview />;
        case 2:
            return <AccountSettings />;
        case 3:
            return <OrganizationSettings />;
        default:
            return <Connections />;
    }
}

function Settings() {
    const { currentTabIndex, setSearchParams } =
        SharedHorizontalTabs.useGetTabIndex(TABS);

    // Tab change handler to be passed to the HorizontalTabs component
    const handleTabChange = (index) => {
        setSearchParams({ tab: TABS[index].key }, { replace: true });
    };

    return (
        <div className="flex flex-col p-[60px] gap-y-[20px]">
            <SharedPageTitle>Settings</SharedPageTitle>
            <SharedHorizontalTabs.Component
                tabs={TABS.map((tab) => tab.label)}
                initialIndex={currentTabIndex}
                onChange={handleTabChange}
                className="mb-[25px]"
            />
            {renderSettingsContent(currentTabIndex)}
        </div>
    );
}

export default Settings;
