import React from "react";
import { useNavigate } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import BusinessEntityCard from "./BusinessEntityCard";
import { SharedButton } from "../../shared";
import useGetProviderPath from "../../../hooks/useGetProviderPath";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";

function BusinessEntityOverview() {
    const navigate = useNavigate();
    const providerPath = useGetProviderPath();
    const providerId = useGetProviderId();
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: true },
    });

    if (!businessEntities) return null;

    return (
        <>
            <SharedButton
                onClick={() =>
                    navigate(`${providerPath}/settings/createEntity`)
                }
            >
                Add Business Entity
            </SharedButton>
            <div className="flex flex-col gap-y-[20px]">
                {businessEntities.map((businessEntity) => (
                    <BusinessEntityCard
                        key={businessEntity.id}
                        businessEntity={businessEntity}
                    />
                ))}
            </div>
        </>
    );
}

export default BusinessEntityOverview;
