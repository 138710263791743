import React from "react";
import WomanWorkingPng from "../../../assets/WomanWorking.png";
import GettingStartedBullet from "./GettingStartedBullet";

const bullets = [
    {
        number: 1,
        description:
            "Schedule a free intro call with the tax partner of your choice",
    },
    {
        number: 2,
        description:
            "Flychain coordinates directly with the tax partner to share your year end financials and any other required documents",
    },
    {
        number: 3,
        description:
            "Your taxes are filed seamlessly to minimize the work on your end while maximizing your tax savings",
    },
];

function GettingStarted() {
    return (
        <div className="flex flex-row px-[100px] py-[80px] gap-x-[60px] bg-flychainPurple-dark items-center">
            <div className="flex flex-row flex-[2] justify-center items-start">
                <img
                    className="rounded-full aspect-square w-[80%] h-[80%]"
                    src={WomanWorkingPng}
                    alt="WomanWorking"
                />
            </div>
            <div className="flex flex-col flex-[3] justify-center gap-y-[30px]">
                <div className="text-white text-[36px]">How to get started</div>
                {bullets.map((bullet) => (
                    <GettingStartedBullet
                        key={bullet.number}
                        number={bullet.number}
                        description={bullet.description}
                    />
                ))}
            </div>
        </div>
    );
}

export default GettingStarted;
