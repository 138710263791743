import React from "react";
import PropTypes from "prop-types";
import { SharedCard, SharedButton } from "../shared";
import FlychainLogoSvg from "../../assets/FlychainLogo.svg";
import { AuthPageEnums } from "../../constants";

function LoginRequired({ setAuthPage }) {
    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <SharedCard className="max-w-[60%] bg-white">
                <div className="flex flex-col justify-center items-center gap-y-[25px] ">
                    <img
                        src={FlychainLogoSvg}
                        alt="Flychain Logo"
                        className="w-[160px]"
                    />
                    <div className="flex flex-col gap-y-[10px] text-center">
                        <div className="text-[16px] text-flychainPurple-dark">
                            Your account has been created. Please log in to
                            continue.
                        </div>
                    </div>
                    <SharedButton
                        onClick={() => {
                            setAuthPage(AuthPageEnums.LOG_IN);
                        }}
                    >
                        Go to Log In
                    </SharedButton>
                </div>
            </SharedCard>
        </div>
    );
}

LoginRequired.propTypes = {
    setAuthPage: PropTypes.func.isRequired,
};

export default LoginRequired;
