import { useSelector } from "react-redux";

function useGlobalLoading() {
    const isLoading = useSelector((state) => {
        const queriesAndMutations = {
            ...state.apiV2.queries,
            ...state.apiV2.mutations,
        };

        const isQueryOrMutationLoading = Object.values(
            queriesAndMutations
        ).some((queryOrMutation) => queryOrMutation.status === "pending");

        return isQueryOrMutationLoading;
    });
    return isLoading;
}

export default useGlobalLoading;
