import React from "react";
import PropTypes from "prop-types";
import { PropelAuthSignUpErrorType } from "../../../constants";

export default function SignUpError({ errorType }) {
    if (errorType === PropelAuthSignUpErrorType.SIGNUP_DISABLED) {
        return (
            <div className="text-center text-[12px] text-flychainError text-wrap">
                Sign up is disabled. Please contact support.
            </div>
        );
    }

    // BAD_REQUEST, UNEXPECTED_OR_UNHANDLED
    return (
        <div className="text-center text-[12px] text-flychainError text-wrap">
            An unexpected error occurred. Please try again later.
        </div>
    );
}

SignUpError.propTypes = {
    errorType: PropTypes.oneOf(Object.values(PropelAuthSignUpErrorType))
        .isRequired,
};
