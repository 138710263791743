import React from "react";
import PropTypes from "prop-types";

function TaxPartnerSubCard({ icon, title, description }) {
    return (
        <div className="flex flex-col justify-center items-center w-full">
            <div className="flex flex-col gap-y-[10px] justify-center text-center items-center w-4/5">
                {icon}
                <div className="text-[18px] font-semibold">{title}</div>
                <div className="text-[16px] text-flychainGray">
                    {description}
                </div>
            </div>
        </div>
    );
}

TaxPartnerSubCard.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default TaxPartnerSubCard;
