import REPORT_TYPE from "./constants";

const getReadableReportType = (reportType) => {
    switch (reportType) {
        case REPORT_TYPE.INCOME_STATEMENT:
            return "Income Statement";
        case REPORT_TYPE.BALANCE_SHEET:
            return "Balance Sheet";
        case REPORT_TYPE.CASH_FLOW_REPORT:
            return "Cash Flow Report";
        case REPORT_TYPE.EXPENSES_BY_CATEGORY:
            return "Expenses By Category";
        case REPORT_TYPE.REVENUE_BY_CATEGORY:
            return "Revenue By Category";
        case REPORT_TYPE.TRIAL_BALANCE:
            return "Trial Balance";
        case REPORT_TYPE.GENERAL_LEDGER:
            return "General Ledger";
        default:
            throw new Error(`Unknown report type: ${reportType}`);
    }
};

export default getReadableReportType;
