export const getAllUsersQueryParams = ({ testUsers }) =>
    testUsers ? `?test_users=true` : "";

export const getProvidersQueryParams = ({ onboardedToTeal }) =>
    onboardedToTeal ? `?onboarded_to_teal=${onboardedToTeal}` : "";

export const transformGetProvidersResponse = (data) =>
    data.reduce((providerIdToProvider, provider) => {
        // eslint-disable-next-line no-param-reassign
        providerIdToProvider[provider.id] = provider;
        return providerIdToProvider;
    }, {});

export const getPlaidLinkTokenQueryParams = ({ internalPlaidItemId }) =>
    internalPlaidItemId ? `?internal_plaid_item_id=${internalPlaidItemId}` : "";

export const getPlaidItemsQueryParams = ({ onlyCheckingAccounts }) =>
    onlyCheckingAccounts ? "?only_checking_accounts=true" : "";

export const getPlaidItemsForAllProvidersQueryParams = ({ onlyErrorItems }) =>
    onlyErrorItems ? "?only_error_items=true" : "";

export const getBusinessEntitiesQueryParams = ({ includeIncomplete }) =>
    includeIncomplete ? "?include_incomplete=true" : "";

export const getFinancialSummaryQueryParams = ({ startDate, endDate }) =>
    `?start_date=${startDate}&end_date=${endDate}`;

export const getIncomeStatementQueryParams = ({ startDate, endDate }) =>
    `?start_date=${startDate}&end_date=${endDate}`;

export const getCashFlowReportQueryParams = ({ startDate, endDate }) =>
    `?start_date=${startDate}&end_date=${endDate}`;

export const getBalanceSheetQueryParams = ({ endDate }) =>
    `?end_date=${endDate}`;

export const getRevenueByCategoryQueryParams = ({ startDate, endDate }) =>
    `?start_date=${startDate}&end_date=${endDate}`;

export const getTopExpensesQueryParams = ({ startDate, endDate }) =>
    `?start_date=${startDate}&end_date=${endDate}`;

export const getLedgerLinesQueryParams = ({
    reportType,
    isInflow,
    startDate,
    endDate,
}) =>
    `?report_type=${reportType}&start_date=${startDate}&end_date=${endDate}${
        isInflow !== undefined ? `&is_inflow=${isInflow}` : ""
    }`;

export const getCollectionsLastPaymentCheckForProviderQueryParams = ({
    paymentIds,
}) => `?payment_ids=${paymentIds.join(",")}`;
