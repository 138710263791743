import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuthInfo } from "@propelauth/react";
import { useSelector } from "react-redux";
import {
    selectUser,
    selectActiveOrg,
} from "../../../../../redux/slices/authentication";
import useIsAdminDashboard from "../../../../../hooks/useIsAdminDashboard";
import {
    PropelAuthOrgRoles,
    PropelAuthOrgPermissions,
} from "../../../../../constants";
import { useUpdateUserRoleMutation } from "../../../../../redux/slices/apiV2";
import { SharedBadge, SharedButton } from "../../../../shared";
import { SharedStateSelectInput } from "../../../../shared/Inputs";
import RemoveUserModal from "./RemoveUserModal";

export default function OrgMemberCard({
    propelOrgId,
    providerId,
    orgMember,
    isLast,
}) {
    const isAdminDashboard = useIsAdminDashboard();
    const authInfo = useAuthInfo();
    const currentUser = useSelector(selectUser);
    const activeOrg = useSelector(selectActiveOrg);
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const [updateUserRole] = useUpdateUserRoleMutation();

    const canChangeRoles = () => {
        // Platform Admins can change roles of any user from any org, including Owners
        if (isAdminDashboard) return true;

        // Org Members lack the PropelAuthOrgPermissions.CAN_CHANGE_ROLES permission
        // and cannot change anyone's role
        if (
            !activeOrg.userPermissions.includes(
                PropelAuthOrgPermissions.CAN_CHANGE_ROLES
            ) ||
            activeOrg.userAssignedRole === PropelAuthOrgRoles.MEMBER
        ) {
            return false;
        }

        // If the user whose role is being changed is currently an Owner, only another org Owner can change their role
        if (
            orgMember.org_id_to_org_info[propelOrgId].user_role ===
            PropelAuthOrgRoles.OWNER
        ) {
            return activeOrg.userAssignedRole === PropelAuthOrgRoles.OWNER;
        }

        // Org Admins can change roles of any Admin or Member from their org (to Admin or Member)
        return true;
    };

    const canRemoveUser = () => {
        // Platform Admins can remove any user from any org, including Owners
        if (isAdminDashboard) return true;

        // Org Members lack the PropelAuthOrgPermissions.CAN_REMOVE_USERS permission
        // and cannot remove anyone
        if (
            !activeOrg.userPermissions.includes(
                PropelAuthOrgPermissions.CAN_REMOVE_USERS
            ) ||
            activeOrg.userAssignedRole === PropelAuthOrgRoles.MEMBER
        ) {
            return false;
        }

        // If the user being removed is an Owner, only another org Owner can remove them
        if (
            orgMember.org_id_to_org_info[propelOrgId].user_role ===
            PropelAuthOrgRoles.OWNER
        ) {
            return activeOrg.userAssignedRole === PropelAuthOrgRoles.OWNER;
        }

        // Org Admins can remove any Admin or Member from their org
        return true;
    };

    const disableOption = (option) => {
        if (isAdminDashboard) return false;

        switch (option) {
            case PropelAuthOrgRoles.OWNER:
                return activeOrg.userAssignedRole !== PropelAuthOrgRoles.OWNER;
            case PropelAuthOrgRoles.ADMIN:
                return ![
                    PropelAuthOrgRoles.OWNER,
                    PropelAuthOrgRoles.ADMIN,
                ].includes(activeOrg.userAssignedRole);
            default:
                return false;
        }
    };

    const updateRole = async (value) => {
        await updateUserRole({
            providerId,
            userId: orgMember.user_id,
            body: {
                role: value,
            },
        });
        await authInfo.refreshAuthInfo();
    };

    return (
        <div
            className={`grid grid-cols-[2fr_2fr_1fr_1fr_1fr] gap-x-[10px] text-[14px] items-center border-gray-200 py-[6px] ${
                !isLast && "border-b"
            }`}
        >
            <div className="flex flex-row items-center gap-x-[5px]">
                <div className="font-semibold text-[16px] items-center">
                    {orgMember.first_name} {orgMember.last_name}
                </div>
                {currentUser.userId === orgMember.user_id && (
                    <span className="text-[14px] ml-[8px] text-flychainGray">
                        (You)
                    </span>
                )}
            </div>
            <div>{orgMember.email}</div>
            <div className="flex justify-center">
                <SharedBadge color={orgMember.enabled ? "green" : "red"}>
                    <div className="text-white w-fit text-[16px] px-[2px]">
                        {orgMember.enabled ? "Active" : "Inactive"}
                    </div>
                </SharedBadge>
            </div>
            <div className="flex justify-center items-center w-full pt-[5px]">
                <SharedStateSelectInput
                    disabled={!canChangeRoles()}
                    variant="outlined"
                    size="small"
                    className="w-fit"
                    name="Role"
                    options={[
                        PropelAuthOrgRoles.OWNER,
                        PropelAuthOrgRoles.ADMIN,
                        PropelAuthOrgRoles.MEMBER,
                    ]}
                    disableOption={disableOption}
                    currentValue={
                        orgMember.org_id_to_org_info[propelOrgId].user_role
                    }
                    onChange={(value) => updateRole(value)}
                />
            </div>
            <div className="flex justify-center">
                <SharedButton
                    className="rounded-full w-fit px-[12px] py-[8px]"
                    color="error"
                    disabled={!canRemoveUser()}
                    onClick={() => {
                        setShowRemoveUserModal(true);
                    }}
                >
                    Remove User
                </SharedButton>
            </div>
            {showRemoveUserModal && (
                <RemoveUserModal
                    propelOrgId={propelOrgId}
                    orgMember={orgMember}
                    setShowRemoveUserModal={setShowRemoveUserModal}
                />
            )}
        </div>
    );
}

OrgMemberCard.propTypes = {
    propelOrgId: PropTypes.string.isRequired,
    providerId: PropTypes.string.isRequired,
    orgMember: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired,
};
