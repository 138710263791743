import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { useAuthInfo } from "@propelauth/react";
import useIsAdminDashboard from "../../hooks/useIsAdminDashboard";
import FlychainLogoSvg from "../../assets/FlychainLogo2.svg";
import SideBarOrgSelector from "./SideBarOrgSelector";
import SideBarBottomMenu from "./SideBarBottomMenu";

function SideBar({ children, showLogo }) {
    const isAdminDashboard = useIsAdminDashboard();
    const authInfo = useAuthInfo();

    let userOrgs = []; // Default to an empty array which will prevent the SideBarOrgSelector from rendering
    if (authInfo.orgHelper) {
        userOrgs = authInfo.orgHelper.getOrgs();
    } else {
        // authInfo.orgHelper == null error was encountered by a user in the SideBar component in
        // production; however we haven't been able to reproduce it in development or production.
        // Thus, we're capturing an exception here to alert the team if it occurs again and default
        // to an empty array of orgs.
        Sentry.captureException(
            new Error(
                `No org helper found on authInfo in SideBar: ${JSON.stringify(
                    authInfo
                )}`
            )
        );
    }

    return (
        <div className="flex flex-col h-full bg-flychainPurple-dark w-[320px]">
            {showLogo && (
                <div className="flex flex-row my-[20px] ml-[20px]">
                    <img className="w-4/5" src={FlychainLogoSvg} alt="Logo" />
                </div>
            )}
            {userOrgs.length > 1 && !isAdminDashboard && (
                <SideBarOrgSelector userOrgs={userOrgs} />
            )}
            <div className="flex overflow-y-auto flex-col flex-grow scrollbar scrollbar-thumb-flychainPurple-light scrollbar-track-flychainPurple-dark">
                {children}
            </div>
            <div className="mt-auto">
                <SideBarBottomMenu />
            </div>
        </div>
    );
}

SideBar.propTypes = {
    children: PropTypes.node,
    showLogo: PropTypes.bool,
};

SideBar.defaultProps = {
    children: undefined,
    showLogo: true,
};

export default SideBar;
