import React, { useState } from "react";
import TaxesHeaderSection from "./TaxesHeaderSection";
import GettingStarted from "./GettingStarted";
import CalendlyModal from "../CalendlyModal";
import TaxPartners from "./TaxPartners";

function Taxes() {
    const [taxCallModalUrl, setTaxCallModalUrl] = useState(null);

    return (
        <div className="flex flex-col">
            <TaxesHeaderSection />
            <TaxPartners setTaxCallModalUrl={setTaxCallModalUrl} />
            <GettingStarted />
            {taxCallModalUrl && (
                <CalendlyModal
                    onClose={() => setTaxCallModalUrl(null)}
                    calendlyUrl={taxCallModalUrl}
                />
            )}
        </div>
    );
}

export default Taxes;
