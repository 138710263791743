import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import useGetGraphPeriodOptions from "../../../hooks/useGetGraphPeriodOptions";
import { GRAPH_PERIOD } from "../constants";
import NetProfitGraph from "../IncomeStatement/NetProfitGraph";
import NetCashFlowGraph from "../CashFlow/NetCashFlowGraph";
import CashBalanceGraph from "../CashFlow/CashBalanceGraph";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import { SharedSectionTitle } from "../../shared/Text";
import { SharedStateSelectInput } from "../../shared/Inputs";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";

function FinancialReportsHome() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const [netProfitGraphPeriod, setNetProfitGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );
    const [netCashFlowGraphPeriod, setNetCashFlowGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );
    const [cashBalanceGraphPeriod, setCashBalanceGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );

    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });
    const {
        data: graphPeriodOptions,
        isFetching: isFetchingGraphPeriodOptions,
    } = useGetGraphPeriodOptions({
        providerId,
        businessEntityId,
    });

    if (
        !businessEntities ||
        !graphPeriodOptions ||
        isFetchingGraphPeriodOptions
    )
        return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px]">
            <div className="flex flex-col gap-y-[30px]">
                {multipleBusinessEntities ? (
                    <div className="flex flex-row gap-x-[30px]">
                        <ValidUpUntil />
                        <BusinessEntitySelect inline />
                    </div>
                ) : (
                    <ValidUpUntil />
                )}
                <div className="flex flex-col gap-y-[60px]">
                    <div className="flex flex-col gap-y-[20px]">
                        <div className="flex flex-row justify-between items-center">
                            <SharedSectionTitle>
                                Net Profit by Month
                            </SharedSectionTitle>
                            <div className="mt-[5px]">
                                <SharedStateSelectInput
                                    name="Net Profit Period Selector"
                                    label="Period"
                                    variant="outlined"
                                    size="small"
                                    options={graphPeriodOptions}
                                    currentValue={netProfitGraphPeriod}
                                    onChange={(value) => {
                                        setNetProfitGraphPeriod(value);
                                    }}
                                />
                            </div>
                        </div>
                        <NetProfitGraph period={netProfitGraphPeriod} />
                    </div>
                    <div className="flex flex-col gap-y-[20px]">
                        <div className="flex flex-row justify-between items-center">
                            <SharedSectionTitle>
                                Net Cash Flow by Month
                            </SharedSectionTitle>
                            <div className="mt-[5px]">
                                <SharedStateSelectInput
                                    name="Net Cash Flow Period Selector"
                                    label="Period"
                                    variant="outlined"
                                    size="small"
                                    options={graphPeriodOptions}
                                    currentValue={netCashFlowGraphPeriod}
                                    onChange={(value) => {
                                        setNetCashFlowGraphPeriod(value);
                                    }}
                                />
                            </div>
                        </div>
                        <NetCashFlowGraph period={netCashFlowGraphPeriod} />
                    </div>
                    <div className="flex flex-col gap-y-[20px]">
                        <div className="flex flex-row justify-between items-center">
                            <SharedSectionTitle>
                                Daily Cash Balance
                            </SharedSectionTitle>
                            <div className="mt-[5px]">
                                <SharedStateSelectInput
                                    name="Cash Balance Period Selector"
                                    label="Period"
                                    variant="outlined"
                                    size="small"
                                    options={graphPeriodOptions}
                                    currentValue={cashBalanceGraphPeriod}
                                    onChange={(value) => {
                                        setCashBalanceGraphPeriod(value);
                                    }}
                                />
                            </div>
                        </div>
                        <CashBalanceGraph period={cashBalanceGraphPeriod} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinancialReportsHome;
