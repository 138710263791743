import React from "react";
import { SharedPageTitle } from "../shared/Text";
import TaxServicesGraphicSvg from "../../assets/TaxServicesGraphic.svg";

function TaxesHeaderSection() {
    return (
        <div className="flex flex-row p-[60px] bg-gradient-to-tr from-flychainPurple-white to-white gap-x-[100px] items-center">
            <div className="flex flex-col jusify-center flex-[2] gap-[30px]">
                <SharedPageTitle>
                    File Your Taxes With Confidence
                </SharedPageTitle>
                <div className="text-[14px] font-[400]">
                    Our two tax partners provide comprehensive federal and state
                    tax filing for both individuals and businesses (LLCs and S
                    Corps), ensuring a seamless, stress-free tax season. With
                    expert guidance, our tax partners help you stay fully
                    compliant while identifying opportunities for tax savings
                    and deductions.
                </div>
            </div>
            <img src={TaxServicesGraphicSvg} alt="Tax Services Graphic" />
        </div>
    );
}

export default TaxesHeaderSection;
