import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
} from "recharts";
import { getCurrencyString } from "../../../utils";
import {
    formatMonthYearStringForXAxis,
    tickStyle,
    splitMetric,
    isAfterValidDate,
    VALID_DATE_FORMATS,
} from "../utils";
import SharedDefsContextProvider, {
    useSharedDefs,
} from "../SharedDefsContextProvider";
import NetCashFlowGraphTooltip from "./NetCashFlowGraphTooltip";
import NetCashFlowGraphLegend from "./NetCashFlowGraphLegend";
import { useGetFinancialReportsValidUpUntilQuery } from "../../../redux/slices/apiV2";
import useGetProviderId from "../../../hooks/useGetProviderId";

function NetCashFlowGraphContent({ data }) {
    const defs = useSharedDefs();
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });

    const dataPoints = useMemo(() => {
        if (data.length <= 12) {
            return data;
        }
        return data.slice(-12);
    }, [data]);

    if (!validUpUntilData) return null;

    const validUpUntil = validUpUntilData.valid_up_until;

    const validBars = dataPoints.map((point, index) => {
        const inflows = splitMetric(
            point.inflows_cents,
            point.month,
            VALID_DATE_FORMATS.MMM_YYYY,
            validUpUntil
        );
        const outflows = splitMetric(
            point.outflows_cents,
            point.month,
            VALID_DATE_FORMATS.MMM_YYYY,
            validUpUntil
        );
        const netCashFlows = splitMetric(
            point.net_cash_flow_cents,
            point.month,
            VALID_DATE_FORMATS.MMM_YYYY,
            validUpUntil
        );

        // If the net cash flow is verified and the next month is after the valid up until date, set the unverified net cash flow to the verified net cash flow
        // This is to ensure that the graph is not showing a gap in the line chart
        if (
            netCashFlows.verified &&
            index < dataPoints.length - 1 && // Check if there is a next data point
            isAfterValidDate(
                dataPoints[index + 1].month,
                VALID_DATE_FORMATS.MMM_YYYY,
                validUpUntil
            ) // Check if the next datapoint is after the valid up until date
        ) {
            netCashFlows.unverified = netCashFlows.verified;
        }

        return {
            ...point,
            inflows_cents: inflows.verified,
            unverified_inflows_cents: inflows.unverified,
            outflows_cents: outflows.verified,
            unverified_outflows_cents: outflows.unverified,
            net_cash_flow_cents: netCashFlows.verified,
            unverified_net_cash_flow_cents: netCashFlows.unverified,
        };
    });

    return (
        <ResponsiveContainer width="100%" height={200}>
            <ComposedChart data={validBars} stackOffset="sign">
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip
                    content={
                        <NetCashFlowGraphTooltip validUpUntil={validUpUntil} />
                    }
                />
                <Legend content={<NetCashFlowGraphLegend />} />
                {defs}
                <Bar
                    dataKey="inflows_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                />
                <Bar
                    dataKey="unverified_inflows_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                    fillOpacity={0.4}
                    stroke="#8D58FF"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                />
                <Bar
                    dataKey="outflows_cents"
                    stackId="stack"
                    barSize={24}
                    fill="#CCCCCC"
                />
                <Bar
                    dataKey="unverified_outflows_cents"
                    stackId="stack"
                    barSize={24}
                    fill="#CCCCCC"
                    fillOpacity={0.4}
                    stroke="#8D58FF"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                />
                <Line
                    type="monotone"
                    dataKey="net_cash_flow_cents"
                    stroke="#F8CE39"
                    strokeWidth={1}
                    dot={false}
                />
                <Line
                    type="monotone"
                    dataKey="unverified_net_cash_flow_cents"
                    stroke="#F8CE39"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                    dot={false}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        net_cash_flow_cents: PropTypes.number.isRequired,
        inflows_cents: PropTypes.number.isRequired,
        outflows_cents: PropTypes.number.isRequired,
    }).isRequired
);

NetCashFlowGraphContent.propTypes = {
    data: dataProps.isRequired,
};

function NetCashFlowGraph({ data }) {
    return (
        <SharedDefsContextProvider>
            <NetCashFlowGraphContent data={data} />
        </SharedDefsContextProvider>
    );
}

NetCashFlowGraph.propTypes = {
    data: dataProps.isRequired,
};

export default NetCashFlowGraph;
