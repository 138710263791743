import React from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "../AdminDashboard";
import ProviderCreation from "../ProviderContent/ProviderCreation";
import ProviderContent from "../ProviderContent";
import { selectActiveOrg } from "../../redux/slices/authentication";

function AppContent() {
    const activeOrg = useSelector(selectActiveOrg);

    // If the active org is a platform admin, render the admin dashboard
    if (activeOrg && activeOrg.orgMetadata.platform_admin === "true") {
        return <AdminDashboard />;
    }

    // Handle user without org
    if (activeOrg === null) {
        return <ProviderCreation />;
    }

    return <ProviderContent />;
}

export default AppContent;
