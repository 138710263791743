import React from "react";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { useGetExpensesByMerchantQuery } from "../../../../redux/slices/apiV2";
import Table from "./Table";

function ExpensesByMerchant() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();

    const { data: expensesByMerchantData } = useGetExpensesByMerchantQuery({
        providerId,
        businessEntityId,
    });

    if (!expensesByMerchantData) return null;

    return <Table data={expensesByMerchantData} />;
}

export default ExpensesByMerchant;
