import React from "react";
import { SharedModal } from "../../../shared";
import FlychainLogoSvg from "../../../../assets/FlychainLogo.svg";

export default function ChangePasswordSuccessModal() {
    return (
        <SharedModal
            cardClassName="gap-y-[20px] max-w-[60%] justify-center items-center text-center"
            onClose={() => {
                window.location.reload();
            }}
        >
            <img
                src={FlychainLogoSvg}
                alt="Flychain Logo"
                className="w-[160px] mb-[5px]"
            />
            <div className="text-[16px] text-flychainPurple-dark">
                Your password has been updated successfully.
            </div>
        </SharedModal>
    );
}
