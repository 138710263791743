import React from "react";
import { useNavigate } from "react-router-dom";
import SelectedProvider from "../FinancialReports/SelectedProvider";
import { SharedAlert, SharedButton, SharedCard } from "../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../shared/Text";
import TriangleAlertSvg from "../../../assets/TriangleAlert";
import { useGetPlaidItemsQuery } from "../../../redux/slices/apiV2";
import useGetProviderId from "../../../hooks/useGetProviderId";
import useGetProviderPath from "../../../hooks/useGetProviderPath";

function OnboardingPreconditions() {
    const navigate = useNavigate();
    const providerId = useGetProviderId();
    const providerPath = useGetProviderPath();
    const { data: plaidItems } = useGetPlaidItemsQuery({
        providerId,
        params: {},
    });

    if (!plaidItems) {
        return null;
    }

    const navigateToConnections = () => {
        navigate(`${providerPath}/settings?tab=connections`);
    };

    const navigateToOnboardingBusinessEntities = () => {
        navigate("businessEntities");
    };

    const renderAccountTableRows = () =>
        plaidItems.map((plaidItem) =>
            plaidItem.accounts.map((account) => (
                <div
                    key={account.account_id}
                    className="grid grid-cols-[1.5fr_1fr_1fr_1.5fr_1fr_1fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{account.name}</div>
                    <div className="flex justify-center w-full">
                        (••••{account.mask})
                    </div>
                    <div>{account.type.toUpperCase()}</div>
                    <div className="flex flex-row items-center gap-x-[10px]">
                        {plaidItem.item.institution?.logo && (
                            <img
                                className="w-[30px] h-[30px]"
                                src={`data:image/png;base64,${plaidItem.item.institution.logo}`}
                                alt="Instituton Logo"
                            />
                        )}
                        <div>
                            {plaidItem.item.institution?.name ||
                                "Unknown Institution"}
                        </div>
                    </div>
                    {!plaidItem.plaid_error ||
                    !plaidItem.plaid_error.error_code ? (
                        <div className="flex justify-center w-full font-semibold text-flychainGreen">
                            Connected
                        </div>
                    ) : (
                        <div className="flex justify-center w-full font-semibold text-flychainError">
                            Broken
                        </div>
                    )}
                    {plaidItem.is_admin_linked ? (
                        <div className="flex justify-center w-full font-semibold text-flychainGreen">
                            TRUE
                        </div>
                    ) : (
                        <div className="flex justify-center w-full font-semibold text-flychainError">
                            FALSE
                        </div>
                    )}
                </div>
            ))
        );

    return (
        <div>
            <SelectedProvider providerId={providerId} />
            <div className="flex flex-col px-[60px] pb-[60px] pt-[40px] gap-y-[30px]">
                <SharedPageTitle>Onboarding Preconditions</SharedPageTitle>
                <SharedCard>
                    <div className="flex flex-col gap-y-[20px]">
                        <SharedSectionTitle>
                            1. Complete discovery call with bookkeeper
                        </SharedSectionTitle>
                        <div>
                            Please have a copy of the spreadsheet listing out
                            all entities, accounts and their relationships
                            before proceeding.
                        </div>
                        {/* TODO: one day can have google drive wired up here */}
                    </div>
                </SharedCard>
                <SharedCard>
                    <div className="flex flex-col gap-y-[25px]">
                        <SharedSectionTitle>
                            2. Confirm all expected Plaid accounts are connected
                        </SharedSectionTitle>
                        <div className="flex flex-row items-center gap-x-[30px]">
                            <SharedAlert
                                icon={
                                    <TriangleAlertSvg
                                        fill="#8D58FF"
                                        width={18}
                                        height={18}
                                    />
                                }
                                message="If any accounts are missing, please
                                        navigate to the connections settings tab to add
                                        them."
                                borderColor="border-flychainPurple-light"
                            />
                            <SharedButton
                                color="secondary"
                                onClick={navigateToConnections}
                            >
                                Provider Connections
                            </SharedButton>
                        </div>
                        <div className="flex flex-col">
                            <div className="grid grid-cols-[1.5fr_1fr_1fr_1.5fr_1fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                                <div>Name</div>
                                <div className="flex justify-center w-full">
                                    Mask
                                </div>
                                <div>Type</div>
                                <div>Institution</div>
                                <div className="flex justify-center w-full">
                                    Status
                                </div>
                                <div className="flex justify-center w-full">
                                    Admin Linked
                                </div>
                            </div>
                            {renderAccountTableRows()}
                        </div>
                    </div>
                </SharedCard>
                <SharedAlert
                    icon={
                        <TriangleAlertSvg
                            fill="#8D58FF"
                            width={18}
                            height={18}
                        />
                    }
                    message="Please ensure the checks above are complete before continuing the onboarding process."
                    borderColor="border-flychainPurple-light"
                />
                <SharedButton onClick={navigateToOnboardingBusinessEntities}>
                    Proceed to Business Entities
                </SharedButton>
            </div>
        </div>
    );
}

export default OnboardingPreconditions;
