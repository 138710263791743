import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import { selectDateRange } from "../../../redux/slices/dateRange";
import { selectEndDate } from "../../../redux/slices/endDate";
import { useGetFinancialReportsValidUpUntilQuery } from "../../../redux/slices/apiV2";

function DateRangeAndEndDateWrapper({ children }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { startDate: rangeStartDate, endDate: rangeEndDate } =
        useSelector(selectDateRange);
    const endDate = useSelector(selectEndDate);
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });

    return (
        validUpUntilData &&
        rangeStartDate &&
        rangeEndDate &&
        endDate &&
        children
    );
}

DateRangeAndEndDateWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DateRangeAndEndDateWrapper;
