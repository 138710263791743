import React from "react";
import PropTypes from "prop-types";
import SignUpFlowTab, { TAB_STATES } from "./SignUpFlowTab";
import PLATFORM_STATES from "../../states";

const TAB_TEXT = {
    PROVIDE_BUSINESS_DETAILS: "Provide business details",
    CONNECT_BANK_ACCOUNTS: "Connect bank accounts",
    SELECT_BANK_ACCOUNT: "Select bank account",
    COMPLETE: "Complete",
    REVIEW_OFFER: "Review offer",
    ACCEPT_TERMS: "Accept terms",
};

function SignUpFlowTabs({ state }) {
    switch (state) {
        case PLATFORM_STATES.USER_CREATED:
            return (
                <>
                    <SignUpFlowTab state={TAB_STATES.CURRENT}>
                        {TAB_TEXT.PROVIDE_BUSINESS_DETAILS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.CONNECT_BANK_ACCOUNTS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.SELECT_BANK_ACCOUNT}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.COMPLETE}
                    </SignUpFlowTab>
                </>
            );
        case PLATFORM_STATES.PROVIDER_CREATED:
            return (
                <>
                    <SignUpFlowTab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.PROVIDE_BUSINESS_DETAILS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.CURRENT}>
                        {TAB_TEXT.CONNECT_BANK_ACCOUNTS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.SELECT_BANK_ACCOUNT}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.COMPLETE}
                    </SignUpFlowTab>
                </>
            );
        case PLATFORM_STATES.PLAID_CONNECTED:
            return (
                <>
                    <SignUpFlowTab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.PROVIDE_BUSINESS_DETAILS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.CONNECT_BANK_ACCOUNTS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.CURRENT}>
                        {TAB_TEXT.SELECT_BANK_ACCOUNT}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.COMPLETE}
                    </SignUpFlowTab>
                </>
            );
        case PLATFORM_STATES.PLATFORM_PENDING:
            return (
                <>
                    <SignUpFlowTab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.PROVIDE_BUSINESS_DETAILS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.CONNECT_BANK_ACCOUNTS}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.SELECT_BANK_ACCOUNT}
                    </SignUpFlowTab>
                    <SignUpFlowTab state={TAB_STATES.LAST}>
                        {TAB_TEXT.COMPLETE}
                    </SignUpFlowTab>
                </>
            );
        default:
            throw new Error("Invalid state");
    }
}

SignUpFlowTabs.propTypes = {
    state: PropTypes.oneOf(Object.values(PLATFORM_STATES)).isRequired,
};

export default SignUpFlowTabs;
