import React from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import REPORT_TYPE from "../constants";
import LedgerLinesMonthlyGraph from "./LedgerLinesMonthlyGraph";
import Lines from "./Lines";
import ValidUpUntil from "../ValidUpUntil";
import { SharedBackIconButton } from "../../shared";
import { SharedPageTitle } from "../../shared/Text";
import { useGetLedgerLinesQuery } from "../../../redux/slices/apiV2";
import useGetReportType from "../../../hooks/useGetReportType";
import { selectDateRange } from "../../../redux/slices/dateRange";
import { selectEndDate } from "../../../redux/slices/endDate";

function LedgerLines() {
    const providerId = useGetProviderId();
    const { businessEntityId, ledgerId } = useParams();
    const [searchParams] = useSearchParams();
    const selectedDateRange = useSelector(selectDateRange);
    const selectedEndDate = useSelector(selectEndDate);

    const reportType = useGetReportType();

    const dateRange =
        reportType === REPORT_TYPE.BALANCE_SHEET
            ? {
                  startDate: "2000-01-01",
                  endDate: selectedEndDate,
              }
            : selectedDateRange;

    let isInflow = searchParams.get("isInflow") || undefined;
    if (isInflow !== undefined) {
        isInflow = isInflow === "true";
    }

    const { data: ledgerLinesData } = useGetLedgerLinesQuery({
        providerId,
        businessEntityId,
        ledgerId,
        params: { reportType, isInflow, ...dateRange },
    });

    const reportTypes = [
        REPORT_TYPE.REVENUE_BY_CATEGORY,
        REPORT_TYPE.EXPENSES_BY_CATEGORY,
        REPORT_TYPE.INCOME_STATEMENT,
    ];

    if (!ledgerLinesData) return null;

    return (
        <div className="flex flex-col gap-y-[25px]">
            <div className="flex flex-row items-center gap-x-[20px] pt-[60px] ml-[30px]">
                <SharedBackIconButton />
                <SharedPageTitle>{ledgerLinesData.ledger_name}</SharedPageTitle>
            </div>
            <div className="flex flex-col gap-y-[30px] px-[60px] pb-[60px]">
                <ValidUpUntil />
                {reportTypes.includes(reportType) && (
                    <LedgerLinesMonthlyGraph
                        ledgerName={ledgerLinesData.ledger_name}
                    />
                )}
                <Lines ledgerLinesData={ledgerLinesData} />
            </div>
        </div>
    );
}

export default LedgerLines;
