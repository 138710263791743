import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import Mobile from "../Mobile";
import MobileModal from "../Mobile/MobileModal";
import ErrorBoundary from "../ErrorBoundary";
import Loading from "../Loading";
import AppContent from "./AppContent";
import LogInAndSignUp from "../LogInAndSignUp";
import { selectIsAuthenticated } from "../../redux/slices/authentication";

function App() {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    if (isMobile) {
        return <Mobile />;
    }

    return (
        <ErrorBoundary>
            <div className="flex flex-row w-[100vw] h-[100vh]">
                {isAuthenticated ? <AppContent /> : <LogInAndSignUp />}
                <MobileModal />
                <Loading />
            </div>
        </ErrorBoundary>
    );
}

export default App;
