import dayjs from "dayjs";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";

const adjustEndDate = (startDate, endDate, maxDate) => {
    const parsedMaxDate = dayjs(maxDate);
    return parsedMaxDate.isBetween(startDate, endDate, "day", "[]")
        ? parsedMaxDate
        : endDate;
};

const adjustStartDate = (startDate, endDate, minDate) => {
    const parsedMinDate = dayjs(minDate);
    return parsedMinDate.isBetween(startDate, endDate, "day", "[]")
        ? parsedMinDate
        : startDate;
};

const getDateRange = (startDate, endDate, maxDate, minDate) => ({
    startDate: adjustStartDate(startDate, endDate, minDate).format(
        YYYY_MM_DD_FORMAT
    ),
    endDate: adjustEndDate(startDate, endDate, maxDate).format(
        YYYY_MM_DD_FORMAT
    ),
});

const getQuarterDates = (year, quarter) => {
    const startMonth = quarter * 3;
    const startDate = dayjs(
        `${year}-${String(startMonth + 1).padStart(2, "0")}-01`
    );
    const endDate = startDate.add(2, "month").endOf("month");

    return { startDate, endDate };
};

export const getDateRangeForAnnual = (year, maxDate, minDate) => {
    const startDate = dayjs().year(year).startOf("year");
    const endDate = startDate.endOf("year");
    return getDateRange(startDate, endDate, maxDate, minDate);
};

export const getDateRangeForMonth = (year, month, maxDate, minDate) => {
    const startDate = dayjs().year(year).month(month).startOf("month");
    const endDate = startDate.endOf("month");
    return getDateRange(startDate, endDate, maxDate, minDate);
};

export const getDateRangeForQuarter = (year, quarter, maxDate, minDate) => {
    const { startDate, endDate } = getQuarterDates(year, quarter);
    return getDateRange(startDate, endDate, maxDate, minDate);
};
