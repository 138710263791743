import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import { YYYY_MM_DD_FORMAT } from "../../../utils";
import { GRAPH_PERIOD } from "../constants";
import LedgerTable from "../LedgerTable";
import BusinessEntitySelect from "../BusinessEntitySelect";
import RevenueByCategoryGraph from "./RevenueByCategoryGraph";
import ValidUpUntil from "../ValidUpUntil";
import { SharedUnverifiedDataWarningBanner } from "../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../shared/Text";
import { SharedStateSelectInput } from "../../shared/Inputs";
import { SharedDateRangePicker } from "../../shared/Dates";
import {
    useGetBusinessEntitiesQuery,
    useGetRevenueByCategoryQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useGetFinancialReportsEntriesStartQuery,
} from "../../../redux/slices/apiV2";
import useValidateDateRange from "../../../hooks/useValidateDateRange";
import useResetDateRangeIfInvalid from "../../../hooks/useResetDateRangeIfInvalid";
import useGetGraphPeriodOptions from "../../../hooks/useGetGraphPeriodOptions";
import {
    selectDateRange,
    updateDateRange,
} from "../../../redux/slices/dateRange";

const HEADERS = ["Category", "Percentage of Revenue", "Total Revenue", ""];

function Revenue() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };
    const [revenueGraphPeriod, setRevenueGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );

    const { data: revenueData } = useGetRevenueByCategoryQuery({
        providerId,
        businessEntityId,
        params: { ...dateRange },
    });
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: false },
    });
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const { data: entriesStartData } = useGetFinancialReportsEntriesStartQuery({
        providerId,
        businessEntityId,
    });
    const {
        data: graphPeriodOptions,
        isFetching: isFetchingGraphPeriodOptions,
    } = useGetGraphPeriodOptions({
        providerId,
        businessEntityId,
    });

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    useResetDateRangeIfInvalid(entriesStartData, validUpUntilData);

    if (
        !revenueData ||
        !validUpUntilData ||
        !entriesStartData ||
        !businessEntities ||
        !graphPeriodOptions ||
        isFetchingGraphPeriodOptions
    )
        return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <div className="flex flex-col gap-y-[30px]">
                <SharedPageTitle>Revenue By Category</SharedPageTitle>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex flex-row justify-between items-center">
                        <SharedSectionTitle>By Month</SharedSectionTitle>
                        <div className="mt-[5px]">
                            <SharedStateSelectInput
                                name="Revenue Period Selector"
                                label="Period"
                                variant="outlined"
                                size="small"
                                options={graphPeriodOptions}
                                currentValue={revenueGraphPeriod}
                                onChange={(value) => {
                                    setRevenueGraphPeriod(value);
                                }}
                            />
                        </div>
                    </div>
                    <RevenueByCategoryGraph period={revenueGraphPeriod} />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <SharedSectionTitle>For Date Range</SharedSectionTitle>
                    <SharedDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                        minDate={entriesStartData.entries_start}
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                    {dateRangeUnvalidated && (
                        <SharedUnverifiedDataWarningBanner
                            validUpUntil={validUpUntilData.valid_up_until}
                        />
                    )}
                    <LedgerTable data={revenueData} headers={HEADERS} />
                </div>
            </div>
        </div>
    );
}

export default Revenue;
