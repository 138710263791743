/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../utils";
import RequestFundingModal from "./RequestFundingModal";
import { SharedButton } from "../../../shared";

const getFlex = (outstandingBalanceCents, availableBalanceCents) => {
    const percentage =
        outstandingBalanceCents /
        (outstandingBalanceCents + availableBalanceCents);
    // Round to two decimal places
    const rounded = Math.round(percentage * 100) / 100;
    // Cap the value at 1
    return Math.min(rounded, 1);
};

function Line({ outstandingBalanceCents, availableBalanceCents }) {
    const [showRequestFundingModal, setShowRequestFundingModal] =
        useState(false);

    const outstandingBalanceBarWidthPercentage =
        getFlex(outstandingBalanceCents, availableBalanceCents) * 100;

    return (
        <div className="flex flex-col gap-y-[16px]">
            <div className="h-[16px] bg-flychainPurple-white border border-flychainGray rounded-lg flex flex-row">
                <div
                    className="rounded-lg bg-flychainPurple-light"
                    style={{
                        width: `${outstandingBalanceBarWidthPercentage}%`,
                    }}
                />
            </div>
            <div className="flex flex-row justify-between">
                <div>
                    <div className="text-[11px] font-normal text-flychainGray">
                        Outstanding balance
                    </div>
                    <div className="text-[19px] font-semibold">
                        {getCurrencyString(outstandingBalanceCents)}
                    </div>
                </div>
                <div className="flex flex-row items-end gap-x-[16px]">
                    <div className="text-right">
                        <div className="text-[11px] font-normal text-flychainGray">
                            Available funding
                        </div>
                        <div className="text-[19px] font-semibold">
                            {getCurrencyString(availableBalanceCents)}
                        </div>
                    </div>
                    <SharedButton
                        onClick={() => setShowRequestFundingModal(true)}
                        disabled={availableBalanceCents === 0}
                    >
                        Request funding
                    </SharedButton>
                </div>
            </div>
            {showRequestFundingModal && (
                <RequestFundingModal
                    onClose={() => setShowRequestFundingModal(false)}
                    availableBalanceCents={availableBalanceCents}
                />
            )}
        </div>
    );
}

Line.propTypes = {
    outstandingBalanceCents: PropTypes.number.isRequired,
    availableBalanceCents: PropTypes.number.isRequired,
};

export default Line;
