import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import SharedCashBalanceGraph from "../../../Graphs/CashBalanceGraph";
import { useGetCashBalanceGraphDataQuery } from "../../../../redux/slices/apiV2";

function CashBalanceGraph({ period }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: cashBalanceGraphData } = useGetCashBalanceGraphDataQuery({
        providerId,
        businessEntityId,
        params: { period },
    });

    if (!cashBalanceGraphData) {
        return null;
    }

    return <SharedCashBalanceGraph data={cashBalanceGraphData} />;
}

CashBalanceGraph.propTypes = {
    period: PropTypes.string.isRequired,
};

export default CashBalanceGraph;
