import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./slices/authentication";
import errorReducer from "./slices/error";
import { apiV2Slice } from "./slices/apiV2";
import dateRangeReducer from "./slices/dateRange";
import endDateReducer from "./slices/endDate";
import asyncThunkErrorMonitor from "./middleware/asyncThunkErrorMonitor";

export default configureStore({
    reducer: {
        authentication: authenticationReducer,
        error: errorReducer,
        dateRange: dateRangeReducer,
        endDate: endDateReducer,
        [apiV2Slice.reducerPath]: apiV2Slice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(apiV2Slice.middleware)
            .concat(asyncThunkErrorMonitor),
});
