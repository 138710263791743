import React, { useState, useEffect } from "react";
import useGetProviderId from "../../hooks/useGetProviderId";
import SelectBusinessEntity from "../SelectBusinessEntity";
import SavingsCallBanner from "./SavingsCallBanner";
import SavingsCards from "./SavingsCards";
import RealizedSavings from "./RealizedSavings";
import { SharedPageTitle } from "../shared/Text";
import { useGetBusinessEntitiesQuery } from "../../redux/slices/apiV2";

function Savings() {
    const providerId = useGetProviderId();
    const [businessEntityId, setBusinessEntityId] = useState(null);
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        params: { includeIncomplete: true },
    });

    useEffect(() => {
        if (businessEntities && businessEntities.length > 0) {
            setBusinessEntityId(businessEntities[0].id);
        }
    }, [businessEntities]);

    if (!businessEntities || !businessEntityId) {
        return null;
    }

    const onBusinessEntityChange = (option) => {
        setBusinessEntityId(option.value);
    };

    return (
        <div className="flex flex-col justify-start py-[60px]">
            {businessEntities.length > 1 && (
                <SelectBusinessEntity
                    businessEntities={businessEntities}
                    businessEntityId={businessEntityId}
                    onChange={onBusinessEntityChange}
                />
            )}
            <SharedPageTitle className="mb-[30px] px-[60px]">
                Savings
            </SharedPageTitle>
            <div className="flex flex-col gap-y-[60px]">
                <RealizedSavings businessEntityId={businessEntityId} />
                <SavingsCallBanner />
                <SavingsCards />
            </div>
        </div>
    );
}

export default Savings;
