import * as Sentry from "@sentry/react";
import { BUILD_DESTINATION, buildDestinations } from "../buildDestination";

const getSentryDsn = () => {
    switch (BUILD_DESTINATION) {
        case buildDestinations.FLYCHAIN:
            return process.env.REACT_APP_FLYCHAIN_SENTRY_DSN;
        case buildDestinations.ADMIN:
            return process.env.REACT_APP_ADMIN_SENTRY_DSN;
        case buildDestinations.STAGING:
            return process.env.REACT_APP_STAGING_SENTRY_DSN;
        default:
            throw new Error("Invalid REACT_APP_BUILD_DESTINATION");
    }
};

Sentry.init({
    dsn: getSentryDsn(),
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                "https://flychain-backend-production.herokuapp.com/api",
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function setSentryUserInfo(propelUser) {
    if (propelUser) {
        Sentry.setUser({
            id: propelUser.userId,
            email: propelUser.email,
            firstName: propelUser.firstName,
            lastName: propelUser.lastName,
        });
    } else {
        Sentry.setUser(null);
    }
}
