import React from "react";
import { createHashRouter, RouterProvider, Navigate } from "react-router-dom";
import SideBarAndContent from "./SideBarAndContent";
import RouterError from "../RouterError";
import Providers from "./Providers";
import Expenses from "../FinancialReports/Expenses";
import Revenue from "../FinancialReports/Revenue";
import MerchantDetailView from "../FinancialReports/Expenses/MerchantDetailView";
import BrokenPlaidItems from "./BrokenPlaidItems";
import BalanceSheet from "./FinancialReports/BalanceSheet";
import LedgerLines from "../FinancialReports/LedgerLines";
import IncomeStatement from "./FinancialReports/IncomeStatement";
import CashFlow from "./FinancialReports/CashFlow";
import TestUsers from "./TestUsers";
import FinancialReportsHome from "./FinancialReports/FinancialReportsHome";
import CollectionFlow from "./Financing/CollectionFlow";
import BusinessEntityWrapper from "../FinancialReports/BusinessEntityWrapper";
import DateRangeAndEndDateWrapper from "../FinancialReports/DateRangeAndEndDateWrapper";
import Capital from "../Capital";
import AdminAdvancedPaymentOnClaims from "./Capital/AdminAdvancedPaymentOnClaims";
import TermLoan from "../Capital/TermLoan";
import SBAPrograms from "../Capital/SBAPrograms";
import ReviewOffer from "../Capital/ReviewOffer";
import Banking from "../Banking";
import BillPay from "../Banking/BillPay";
import Treasury from "../Banking/Treasury";
import BookkeepingDates from "./BookkeepingDates";
import OnboardingPreconditions from "./OnboardingFlow/OnboardingPreconditions";
import OnboardingBusinessEntities from "./OnboardingFlow/OnboardingBusinessEntities";
import OnboardingAccountMapping from "./OnboardingFlow/OnboardingAccountMapping";
import OnboardingFinalSteps from "./OnboardingFlow/OnboardingFinalSteps";
import Redirect from "../FinancialReports/Redirect";
import Valuation from "../Valuation";
import AdminSavings from "./AdminSavings";
import Taxes from "../Taxes";
import Settings from "../Settings";
import BusinessEntityCreator from "../BusinessEntity/BuisnessEntityCreator";
import GeneralLedgerDownload from "./GeneralLedgerDownload";
import QBORedirect from "../QBORedirect";
import AdminDraws from "./Financing/AdminDraws";
import PartnerServices from "../PartnerServices";

const router = createHashRouter([
    {
        path: "/",
        element: <SideBarAndContent />,
        errorElement: <RouterError />,
        children: [
            {
                index: true,
                element: <Navigate to="/providers" replace />,
            },
            {
                path: "/providers",
                element: <Providers />,
            },
            {
                path: "/provider/:providerId",
                element: <Redirect />,
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports",
                element: (
                    <BusinessEntityWrapper>
                        <FinancialReportsHome />
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/incomeStatement",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <IncomeStatement />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/incomeStatement/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <LedgerLines />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/cashFlow",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <CashFlow />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/cashFlow/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <LedgerLines />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/balanceSheet",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <BalanceSheet />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/balanceSheet/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <LedgerLines />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/revenue",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <Revenue />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/revenue/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <LedgerLines />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <Expenses />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeAndEndDateWrapper>
                            <LedgerLines />
                        </DateRangeAndEndDateWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses/merchants/:merchantId",
                element: (
                    <BusinessEntityWrapper>
                        <MerchantDetailView />
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/capital",
                element: <Capital />,
            },
            {
                path: "/provider/:providerId/capital/advancedPaymentOnClaims",
                element: <AdminAdvancedPaymentOnClaims />,
            },
            {
                path: "/provider/:providerId/capital/termLoan",
                element: <TermLoan />,
            },
            {
                path: "/provider/:providerId/capital/sbaPrograms",
                element: <SBAPrograms />,
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/capital/offer/:offerId",
                element: <ReviewOffer />,
            },
            {
                path: "/provider/:providerId/valuation",
                element: <Valuation />,
            },
            {
                path: "/provider/:providerId/partnerServices",
                element: <PartnerServices />,
            },
            {
                path: "/provider/:providerId/partnerServices/taxes",
                element: <Taxes />,
            },
            {
                path: "/provider/:providerId/partnerServices/banking",
                element: <Banking />,
            },
            {
                path: "/provider/:providerId/partnerServices/banking/billPay",
                element: <BillPay />,
            },
            {
                path: "/provider/:providerId/partnerServices/treasury",
                element: <Treasury />,
            },
            {
                path: "/provider/:providerId/savings",
                element: <AdminSavings />,
            },
            {
                path: "/collectionFlow",
                element: <CollectionFlow />,
            },
            {
                path: "/draws",
                element: <AdminDraws />,
            },
            {
                path: "/brokenPlaidItems",
                element: <BrokenPlaidItems />,
            },
            {
                path: "/testUsers",
                element: <TestUsers />,
            },
            {
                path: "/bookkeepingDates",
                element: <BookkeepingDates />,
            },
            {
                path: "/generalLedgerDownload",
                element: <GeneralLedgerDownload />,
            },
            {
                path: "/provider/:providerId/onboard",
                element: <OnboardingPreconditions />, // see if provider is ready
            },
            {
                path: "/provider/:providerId/onboard/businessEntities",
                element: <OnboardingBusinessEntities />, // view entities
            },
            {
                path: "/provider/:providerId/onboard/accountMapping",
                element: <OnboardingAccountMapping />, // determine mappings between accounts and entities
            },
            {
                path: "/provider/:providerId/onboard/finalSteps",
                element: <OnboardingFinalSteps />, // automatic and manual checks to see if everything is in order
            },
            {
                path: "/provider/:providerId/settings",
                element: <Settings />,
            },
            {
                path: "/provider/:providerId/settings/createEntity",
                element: <BusinessEntityCreator />,
            },
            {
                path: "/qboRedirect",
                element: <QBORedirect />,
            },
        ],
    },
]);

function AdminDashboard() {
    return <RouterProvider router={router} />;
}

export default AdminDashboard;
