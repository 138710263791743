import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutFunction } from "@propelauth/react";
import signOut from "../../redux/slices/authentication/thunks/signOut";
import { selectIsAuthenticated } from "../../redux/slices/authentication";
import { SharedSupportModal } from "../shared";
import HelpSvg from "../../assets/Help.svg";
import SignOutSvg from "../../assets/SignOut.svg";

function SideBarBottomMenu() {
    const [showSupportModal, setShowSupportModal] = useState(false);
    const logout = useLogoutFunction();
    const openSupportModal = () => {
        setShowSupportModal(true);
    };

    const closeSupportModal = () => {
        setShowSupportModal(false);
    };

    const userIsAuthenticated = useSelector(selectIsAuthenticated);

    const dispatch = useDispatch();

    const onClickSignOut = () => {
        dispatch(signOut(logout));
    };

    return (
        <div className="flex flex-col p-[10px]">
            <div
                className="flex flex-row items-center cursor-pointer h-[30px] gap-x-[5px]"
                onClick={openSupportModal}
            >
                <img className="w-[15px]" src={HelpSvg} alt="Help" />
                <div className="text-[14px] font-[400] text-white">Help</div>
            </div>
            {showSupportModal && (
                <SharedSupportModal onClose={closeSupportModal} />
            )}
            {userIsAuthenticated ? (
                <div
                    className="flex flex-row items-center cursor-pointer h-[30px] gap-x-[5px]"
                    onClick={onClickSignOut}
                >
                    <img className="w-[15px]" src={SignOutSvg} alt="Sign Out" />
                    <div className="text-[14px] font-[400] text-white">
                        Sign Out
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default SideBarBottomMenu;
