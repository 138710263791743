import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLogoutFunction } from "@propelauth/react";
import { useSelector, useDispatch } from "react-redux";
import {
    selectActiveOrg,
    selectUser,
} from "../../../../../redux/slices/authentication";
import signOut from "../../../../../redux/slices/authentication/thunks/signOut";
import useGetProviderId from "../../../../../hooks/useGetProviderId";
import { PropelAuthOrgPermissions } from "../../../../../constants";
import {
    useGetProviderQuery,
    useRemoveUserFromProviderOrgMutation,
} from "../../../../../redux/slices/apiV2";
import {
    SharedModal,
    SharedBadge,
    SharedAlert,
    SharedButton,
} from "../../../../shared";
import FlychainLogoSvg from "../../../../../assets/FlychainLogo.svg";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";

export default function RemoveUserModal({
    propelOrgId,
    orgMember,
    setShowRemoveUserModal,
}) {
    const providerId = useGetProviderId();
    const dispatch = useDispatch();
    const logout = useLogoutFunction();
    const [isLoading, setIsLoading] = useState(false);
    const activeOrg = useSelector(selectActiveOrg);
    const currentUser = useSelector(selectUser);
    const [removeUserFromProviderOrg] = useRemoveUserFromProviderOrgMutation();
    const { data: provider } = useGetProviderQuery({
        providerId,
    });
    const canRemoveUser = activeOrg.userPermissions.includes(
        PropelAuthOrgPermissions.CAN_REMOVE_USERS
    );

    if (!provider) return null;

    const removeUser = async () => {
        setIsLoading(true);
        await removeUserFromProviderOrg({
            providerId,
            userId: orgMember.user_id,
        });
        setIsLoading(false);
        if (currentUser.userId === orgMember.user_id) {
            dispatch(signOut(logout));
        } else {
            setShowRemoveUserModal(false);
        }
    };

    return (
        <SharedModal
            cardClassName="gap-y-[20px] max-w-[60%] justify-center items-center text-center pb-[50px]"
            onClose={() => {
                setShowRemoveUserModal(false);
            }}
        >
            <img
                src={FlychainLogoSvg}
                alt="Flychain Logo"
                className="w-[160px] mb-[5px]"
            />
            <div className="text-[16px] text-flychainPurple-dark">
                The following user will be removed from{" "}
                <span className="text-[16px] text-flychainPurple-dark font-semibold">
                    {provider.legal_name}
                </span>
                .
            </div>
            <div className="flex flex-col mb-[10px]">
                <div className="grid grid-cols-[2fr_2fr_1fr_1fr] gap-x-[30px] text-[14px] text-start font-semibold border-b border-flychainGray py-[8px] w-full">
                    <div>Name</div>
                    <div>Email</div>
                    <div className="flex justify-center">Status</div>
                    <div className="flex justify-center">Role</div>
                </div>
                <div className="grid grid-cols-[2fr_2fr_1fr_1fr] gap-x-[30px] text-start items-center border-b border-gray-200 py-[6px]">
                    <div className="flex flex-row items-center">
                        <div className="font-semibold text-[16px] items-center">
                            {orgMember.first_name} {orgMember.last_name}
                        </div>
                        {currentUser.userId === orgMember.user_id && (
                            <span className="text-[14px] ml-[8px] text-flychainGray">
                                (You)
                            </span>
                        )}
                    </div>
                    <div className="text-[16px] text-flychainPurple-dark">
                        {orgMember.email}
                    </div>
                    <div className="flex justify-center">
                        <SharedBadge
                            color={orgMember.enabled ? "green" : "red"}
                        >
                            <div className="text-white w-fit text-[16px] px-[2px]">
                                {orgMember.enabled ? "Active" : "Inactive"}
                            </div>
                        </SharedBadge>
                    </div>
                    <div className="text-[16px] text-flychainPurple-dark text-center">
                        {orgMember.org_id_to_org_info[propelOrgId].user_role}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-[20px] w-full">
                <SharedAlert
                    icon={
                        <TriangleAlertSvg
                            fill="#D32F2F"
                            width={18}
                            height={18}
                        />
                    }
                    message="This action cannot be undone."
                    borderColor="border-flychainError"
                />
                <SharedButton
                    color="error"
                    disabled={!canRemoveUser || isLoading}
                    isLoading={isLoading}
                    loadingText={isLoading ? "Removing user..." : undefined}
                    onClick={() => removeUser()}
                >
                    Remove User
                </SharedButton>
            </div>

            <div className="text-[12px] text-flychainPurple-dark mt-[10px]">
                If you require assistance managing your organization, please
                reach out to{" "}
                <a
                    href="mailto:jaime@flychain.us"
                    className="underline text-flychainPurple-light"
                >
                    jaime@flychain.us
                </a>
                .
            </div>
        </SharedModal>
    );
}

RemoveUserModal.propTypes = {
    propelOrgId: PropTypes.string.isRequired,
    orgMember: PropTypes.object.isRequired,
    setShowRemoveUserModal: PropTypes.func.isRequired,
};
