import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { useGetNetProfitGraphDataQuery } from "../../../../redux/slices/apiV2";
import SharedNetProfitGraph from "../../../Graphs/NetProfitGraph";

function NetProfitGraph({ period }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: netProfitGraphData } = useGetNetProfitGraphDataQuery({
        providerId,
        businessEntityId,
        params: { period },
    });

    if (!netProfitGraphData) {
        return null;
    }

    return <SharedNetProfitGraph data={netProfitGraphData} />;
}

export default NetProfitGraph;

NetProfitGraph.propTypes = {
    period: PropTypes.string.isRequired,
};
