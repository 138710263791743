import React from "react";
import PropTypes from "prop-types";
import useGetProviderId from "../../hooks/useGetProviderId";
import PaywallContent from "./PaywallContent";
import { useGetPlatformSubscriptionQuery } from "../../redux/slices/apiV2";

function Paywall({ children }) {
    const providerId = useGetProviderId();
    const { data: platformSubscription } = useGetPlatformSubscriptionQuery({
        providerId,
    });

    if (platformSubscription === undefined) {
        return null;
    }

    if (!platformSubscription.has_platform_subscription) {
        return <PaywallContent />;
    }

    return children;
}

Paywall.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Paywall;
