import React, { useState } from "react";
import PropTypes from "prop-types";
import useClickOutside from "../../../../hooks/useClickOutside";
import ShortcutsDropdown from "./ShortcutsDropdown";
import SharedButton from "../../Button";

function ShortcutsButton({
    dateRange,
    onChange,
    maxDate,
    minDate,
    className,
    validUpUntil = maxDate,
}) {
    const [showDropdown, setShowDropdown] = useState(false);

    const onClickOutside = () => {
        setShowDropdown(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onClick = () => {
        setShowDropdown(!showDropdown);
    };

    const onDateRangeChange = (newDateRange) => {
        onChange(newDateRange);
        setShowDropdown(false);
    };

    return (
        <div
            ref={containerRef}
            className={`inline-block relative ${className}`}
        >
            <SharedButton onClick={onClick}>Shortcuts</SharedButton>
            {showDropdown && (
                <ShortcutsDropdown
                    dateRange={dateRange}
                    onChange={onDateRangeChange}
                    maxDate={maxDate}
                    minDate={minDate}
                    validUpUntil={validUpUntil}
                />
            )}
        </div>
    );
}

ShortcutsButton.propTypes = {
    dateRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    minDate: PropTypes.string.isRequired,
    className: PropTypes.string,
    validUpUntil: PropTypes.string,
};

ShortcutsButton.defaultProps = {
    className: "",
    validUpUntil: undefined,
};

export default ShortcutsButton;
