import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import RouterError from "../RouterError";
import Capital from "../Capital";
import AdvancedPaymentOnClaims from "../Capital/AdvancedPaymentOnClaims";
import TermLoan from "../Capital/TermLoan";
import SBAPrograms from "../Capital/SBAPrograms";
import ReviewOffer from "../Capital/ReviewOffer";
import SideBarAndContent from "./SideBarAndContent";
import FinancialReportsHome from "../FinancialReports/FinancialReportsHome";
import CashFlow from "../FinancialReports/CashFlow";
import IncomeStatement from "../FinancialReports/IncomeStatement";
import BalanceSheet from "../FinancialReports/BalanceSheet";
import Revenue from "../FinancialReports/Revenue";
import Expenses from "../FinancialReports/Expenses";
import Taxes from "../Taxes";
import LedgerLines from "../FinancialReports/LedgerLines";
import Savings from "../Savings";
import Valuation from "../Valuation";
import Treasury from "../Banking/Treasury";
import BillPay from "../Banking/BillPay";
import Banking from "../Banking";
import Settings from "../Settings";
import Paywall from "../Paywall";
import BusinessEntityCreator from "../BusinessEntity/BuisnessEntityCreator";
import BusinessEntityWrapper from "../FinancialReports/BusinessEntityWrapper";
import DateRangeAndEndDateWrapper from "../FinancialReports/DateRangeAndEndDateWrapper";
import Redirect from "../FinancialReports/Redirect";
import QBORedirect from "../QBORedirect";
import PartnerServices from "../PartnerServices";

const router = createHashRouter([
    {
        path: "/",
        element: <SideBarAndContent />,
        errorElement: <RouterError />,
        children: [
            {
                path: "/",
                element: <Redirect />,
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <FinancialReportsHome />
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/incomeStatement",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <IncomeStatement />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/incomeStatement/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <LedgerLines />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/cashFlow",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <CashFlow />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/cashFlow/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <LedgerLines />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/balanceSheet",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <BalanceSheet />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/balanceSheet/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <LedgerLines />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/revenue",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <Revenue />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/revenue/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <LedgerLines />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/expenses",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <Expenses />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/expenses/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeAndEndDateWrapper>
                                <LedgerLines />
                            </DateRangeAndEndDateWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/capital",
                element: <Capital />,
            },
            {
                path: "/capital/advancedPaymentOnClaims",
                element: <AdvancedPaymentOnClaims />,
            },
            {
                path: "/capital/termLoan",
                element: <TermLoan />,
            },
            {
                path: "/capital/sbaPrograms",
                element: <SBAPrograms />,
            },
            {
                path: "/businessEntity/:businessEntityId/capital/offer/:offerId",
                element: <ReviewOffer />,
            },
            {
                path: "/savings",
                element: (
                    <Paywall>
                        <Savings />
                    </Paywall>
                ),
            },
            {
                path: "/valuation",
                element: (
                    <Paywall>
                        <Valuation />
                    </Paywall>
                ),
            },
            {
                path: "/partnerServices",
                element: (
                    <Paywall>
                        <PartnerServices />
                    </Paywall>
                ),
            },
            {
                path: "/partnerServices/taxes",
                element: (
                    <Paywall>
                        <Taxes />
                    </Paywall>
                ),
            },
            {
                path: "/partnerServices/banking",
                element: (
                    <Paywall>
                        <Banking />
                    </Paywall>
                ),
            },
            {
                path: "/partnerServices/banking/billPay",
                element: (
                    <Paywall>
                        <BillPay />
                    </Paywall>
                ),
            },
            {
                path: "/partnerServices/treasury",
                element: (
                    <Paywall>
                        <Treasury />
                    </Paywall>
                ),
            },
            {
                path: "/settings",
                element: <Settings />,
            },
            {
                path: "/settings/createEntity",
                element: <BusinessEntityCreator />,
            },
            {
                path: "/qboRedirect",
                element: <QBORedirect />,
            },
        ],
    },
]);

function ProviderDashboard() {
    return <RouterProvider router={router} />;
}

export default ProviderDashboard;
