import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function Content({ children, backgroundColor }) {
    const containerRef = useRef(null);

    // Whenever the url changes i.e. user navigates to a new page, we want to scroll to the top of the container
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [window.location.href]);

    return (
        <div
            ref={containerRef}
            className={`overflow-auto flex-1 ${backgroundColor} scrollbar scrollbar-thumb-rounded scrollbar-track-transparent scrollbar-thumb-flychainPurple-light`}
        >
            {children}
        </div>
    );
}

Content.propTypes = {
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
};

Content.defaultProps = {
    children: undefined,
    backgroundColor: "bg-white",
};

export default Content;
