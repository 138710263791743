import { useLocation } from "react-router-dom";
import REPORT_TYPE from "../../components/FinancialReports/constants";

const useGetReportType = () => {
    const location = useLocation();
    const { pathname } = location;

    if (pathname.includes("/incomeStatement")) {
        return REPORT_TYPE.INCOME_STATEMENT;
    }
    if (pathname.includes("/cashFlow")) {
        return REPORT_TYPE.CASH_FLOW_REPORT;
    }
    if (pathname.includes("/balanceSheet")) {
        return REPORT_TYPE.BALANCE_SHEET;
    }
    if (pathname.includes("/expenses")) {
        return REPORT_TYPE.EXPENSES_BY_CATEGORY;
    }
    if (pathname.includes("/revenue")) {
        return REPORT_TYPE.REVENUE_BY_CATEGORY;
    }
    if (pathname.includes("/trialBalance")) {
        return REPORT_TYPE.TRIAL_BALANCE;
    }
    if (pathname.includes("/generalLedger")) {
        return REPORT_TYPE.GENERAL_LEDGER;
    }

    throw new Error(`Unknown report type for pathname ${pathname}`);
};

export default useGetReportType;
