import REPORT_TYPE from "../../components/FinancialReports/constants";
import {
    useExportIncomeStatementMutation,
    useExportCashFlowReportMutation,
    useExportBalanceSheetMutation,
} from "../../redux/slices/apiV2";
import useGetReportType from "../useGetReportType";

const useExportReportMutation = () => {
    const reportType = useGetReportType();

    switch (reportType) {
        case REPORT_TYPE.INCOME_STATEMENT:
            return useExportIncomeStatementMutation();
        case REPORT_TYPE.CASH_FLOW_REPORT:
            return useExportCashFlowReportMutation();
        case REPORT_TYPE.BALANCE_SHEET:
            return useExportBalanceSheetMutation();
        default:
            throw new Error(`Unknown report type: ${reportType}`);
    }
};

export default useExportReportMutation;
