import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import { useGetRevenueByCategoryGraphDataQuery } from "../../../redux/slices/apiV2";
import SharedStackedBarGraph from "../../Graphs/StackedBarGraph";

function RevenueByCategoryGraph({ period }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: revenueByCategoryGraphData } =
        useGetRevenueByCategoryGraphDataQuery({
            providerId,
            businessEntityId,
            params: { period },
        });

    if (!revenueByCategoryGraphData) {
        return null;
    }

    return <SharedStackedBarGraph data={revenueByCategoryGraphData} />;
}

RevenueByCategoryGraph.propTypes = {
    period: PropTypes.string.isRequired,
};

export default RevenueByCategoryGraph;
