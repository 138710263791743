import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import { useGetBusinessEntitiesQuery } from "../../../redux/slices/apiV2";

function Redirect() {
    const providerId = useGetProviderId();
    const { data: businessEntities, isFetching: isFetchingBusinessEntities } =
        useGetBusinessEntitiesQuery({
            providerId,
            params: { includeIncomplete: false },
        });

    const navigate = useNavigate();

    useEffect(() => {
        // Wait for businessEntities to load
        if (!businessEntities || isFetchingBusinessEntities) return;

        // If there is at least one Teal-enabled business entity, redirect to the financial reports page for the first one
        if (businessEntities.length > 0) {
            navigate(
                `businessEntity/${businessEntities[0].id}/financialReports`,
                {
                    replace: true,
                }
            );
        } else {
            // If there are no Teal-enabled business entities, redirect to the Advanced Payment on Claims report
            navigate("capital/advancedPaymentOnClaims", {
                replace: true,
            });
        }
    }, [businessEntities, navigate]);

    return null;
}

export default Redirect;
