import React from "react";
import { SharedPageTitle } from "../shared/Text";
import PartnerServicesGraphicSvg from "../../assets/PartnerServicesGraphic.svg";

function PartnerServicesHeaderSection() {
    return (
        <div className="flex flex-row p-[60px] bg-gradient-to-tr from-flychainPurple-white to-white gap-x-[100px] items-center">
            <div className="flex flex-col jusify-center flex-[2] gap-[30px]">
                <SharedPageTitle>Network of Partner Services</SharedPageTitle>
                <div className="text-[14px] font-[400]">
                    Leverage our vetted Partner Services network to find trusted
                    partners for essential services such as tax filing and
                    legal. Whether you need assistance with entity structuring,
                    business insurance, or developing a tax strategy, our
                    partnerships ensure you have reliable, high-quality
                    resources at your fingertips.
                </div>
            </div>
            <img
                src={PartnerServicesGraphicSvg}
                alt="Partner Services Graphic"
            />
        </div>
    );
}

export default PartnerServicesHeaderSection;
