import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import UnlinkSvg from "../../../../assets/Unlink";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { useBreakPlaidItemMutation } from "../../../../redux/slices/apiV2";

function BreakConnectionMenuItem({ internalPlaidItemId, closeMenu }) {
    const providerId = useGetProviderId();
    const [triggerBreakPlaidItemMutation] = useBreakPlaidItemMutation();

    const handleBreakConnection = () => {
        triggerBreakPlaidItemMutation({
            providerId,
            internalPlaidItemId,
        });
        closeMenu();
    };

    return (
        <MenuItem
            key="break_connection"
            value="break_connection"
            className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
            onClick={handleBreakConnection}
        >
            <UnlinkSvg fill="currentColor" width={16} height={16} />
            Break Connection
        </MenuItem>
    );
}

BreakConnectionMenuItem.propTypes = {
    internalPlaidItemId: PropTypes.string.isRequired,
    closeMenu: PropTypes.func.isRequired,
};

export default BreakConnectionMenuItem;
