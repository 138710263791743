import React from "react";
import dayjs from "dayjs";
import BusinessEntityName from "../../../../../BusinessEntity/BusinessEntityName";
import ConfirmedOfferActions from "./ConfirmedOfferActions";
import { SharedCard, SharedButton } from "../../../../../shared";
import { SharedSectionTitle } from "../../../../../shared/Text";
import RefreshCWSvg from "../../../../../../assets/RefreshCW";
import { getCurrencyString, MMM_D_YYYY_FORMAT } from "../../../../../../utils";
import { OfferStatus } from "../../../../../../constants";
import { useGetOffersQuery } from "../../../../../../redux/slices/apiV2";

function ConfirmedOffersTable() {
    const { data: confirmedOffers, refetch: refetchConfirmedOffers } =
        useGetOffersQuery({ status: OfferStatus.CONFIRMED });

    if (!confirmedOffers) return null;

    const renderTableRows = () =>
        [...confirmedOffers]
            .sort((a, b) =>
                dayjs(a.confirmed_datetime).diff(dayjs(b.confirmed_datetime))
            )
            .map((offer) => (
                <div
                    key={offer.id}
                    className="grid grid-cols-[2.5fr_2.5fr_2fr_1fr_1fr_1fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{offer.id}</div>
                    <BusinessEntityName
                        businessEntityId={offer.business_entity_id}
                        providerId={offer.provider_id}
                    />
                    <div>{offer.type}</div>
                    <div>{getCurrencyString(offer.principal_cents)}</div>
                    <div>
                        {dayjs(offer.confirmed_datetime).format(
                            MMM_D_YYYY_FORMAT
                        )}
                    </div>
                    <ConfirmedOfferActions offer={offer} />
                </div>
            ));

    return (
        <SharedCard>
            <div className="flex flex-col gap-y-[15px]">
                <div className="flex flex-row justify-between items-center">
                    <SharedSectionTitle className="text-flychainGray">
                        Confirmed Offers
                    </SharedSectionTitle>
                    <SharedButton
                        className="rounded-full w-fit px-[10px] py-[5px]"
                        color="secondary"
                        onClick={() => refetchConfirmedOffers()}
                    >
                        <div className="flex flex-row gap-x-[10px] items-center">
                            <RefreshCWSvg fill="black" width={16} height={16} />
                            Refresh
                        </div>
                    </SharedButton>
                </div>
                {confirmedOffers.length > 0 ? (
                    <div className="flex flex-col">
                        <div className="grid grid-cols-[2.5fr_2.5fr_2fr_1fr_1fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                            <div>Offer ID</div>
                            <div>Business Entity</div>
                            <div>Offer Type</div>
                            <div>Amount</div>
                            <div>Confirmed On</div>
                            <div className="flex justify-center w-full">
                                Actions
                            </div>
                        </div>
                        {renderTableRows()}
                    </div>
                ) : (
                    <div>No confirmed offers found</div>
                )}
            </div>
        </SharedCard>
    );
}

export default ConfirmedOffersTable;
