import React from "react";
import PropTypes from "prop-types";
import { PropelAuthLoginErrorType, AuthPageEnums } from "../../../constants";

export default function LogInError({
    errorType,
    email,
    setAuthPage,
    setConfirmationEmail,
}) {
    if (errorType === PropelAuthLoginErrorType.INVALID_CREDENTIALS) {
        return (
            <div className="flex flex-col items-center gap-y-[10px]">
                <div className="text-center text-[12px] text-flychainError text-wrap">
                    Your email or password is incorrect. Please try again.
                </div>
                <div
                    className="text-center text-[12px] cursor-pointer text-flychainPurple-light underline"
                    onClick={() => {
                        setConfirmationEmail(email);
                        setAuthPage(AuthPageEnums.FORGOT_PASSWORD);
                    }}
                >
                    Forgot your password?
                </div>
            </div>
        );
    }

    if (errorType === PropelAuthLoginErrorType.USER_ACCOUNT_DISABLED) {
        return (
            <div className="text-center text-[12px] text-flychainError text-wrap">
                Your account has been disabled. Please contact support.
            </div>
        );
    }

    if (errorType === PropelAuthLoginErrorType.USER_ACCOUNT_LOCKED) {
        return (
            <div className="text-center text-[12px] text-flychainError text-wrap">
                Your account has been locked. Please contact support.
            </div>
        );
    }

    // BAD_REQUEST, UNEXPECTED_OR_UNHANDLED
    return (
        <div className="text-center text-[12px] text-flychainError text-wrap">
            An unexpected error occurred. Please try again later.
        </div>
    );
}

LogInError.propTypes = {
    errorType: PropTypes.oneOf(Object.values(PropelAuthLoginErrorType))
        .isRequired,
    email: PropTypes.string.isRequired,
    setAuthPage: PropTypes.func.isRequired,
    setConfirmationEmail: PropTypes.func.isRequired,
};
