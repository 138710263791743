import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/authentication";
import { SharedCard } from "../../shared";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ChangeName from "./ChangeName";
import Pencil from "../../../assets/Pencil.svg";

export default function AccountSettings() {
    const [editingName, setEditingName] = useState(false);
    const currentUser = useSelector(selectUser);

    return (
        <SharedCard>
            <div className="flex flex-row gap-x-[20px] items-center border-b pb-[16px]">
                <img
                    src={currentUser.pictureUrl}
                    alt="avatar"
                    className="w-[64px] h-[64px] rounded-full"
                />
                <div className="flex flex-col">
                    <div className="flex flex-row gap-x-[10px] items-center">
                        <div className="text-2xl font-bold">
                            {currentUser.firstName} {currentUser.lastName}
                        </div>
                        <img
                            src={Pencil}
                            alt="Edit Display Name"
                            className="w-[15px] h-[15px] cursor-pointer"
                            onClick={() => setEditingName(true)}
                        />
                    </div>
                    <p className="text-[14px] text-flychainGray">
                        {currentUser.email}
                    </p>
                </div>
            </div>
            <ChangeEmail />
            <ChangePassword />
            {editingName && <ChangeName setEditingName={setEditingName} />}
        </SharedCard>
    );
}
