import React from "react";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { SharedPageTitle } from "../../../shared/Text";
import { useGetProviderQuery } from "../../../../redux/slices/apiV2";

function SelectedProvider() {
    const providerId = useGetProviderId();
    const { data: provider } = useGetProviderQuery({ providerId });
    return provider ? (
        <div className="flex flex-col justify-center text-center py-[20px] bg-[#f2f2f2]">
            <div className="text-base font-normal text-flychainGray">
                Currently Selected Provider
            </div>
            <SharedPageTitle>{provider.legal_name}</SharedPageTitle>
        </div>
    ) : null;
}

export default SelectedProvider;
