/* eslint-disable camelcase */
import React, { useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { getCurrencyString } from "../../../../../utils";
import { SharedTable } from "../../../../shared";

dayjs.extend(utc);

const gmtTooltipText = (
    <p>
        All records are kept in Greenwich Mean Time (GMT) for accounting
        consistency.
        <br />
        <br />
        As a result, the date displayed may be different from the date that the
        transaction occured in your local timezone.
    </p>
);

const HEADERS = [
    <Tooltip title={gmtTooltipText}>
        <p className="cursor-help">Date (GMT)</p>
    </Tooltip>,
    "Description",
    "Amount",
];

function LinesTable({ lines }) {
    const rows = useMemo(
        () =>
            lines.map((line) => [
                <div className="whitespace-nowrap">
                    {dayjs(line.datetime).utc().format("MMM D, YYYY")}
                </div>,
                line.name,
                getCurrencyString(line.amount_cents),
            ]),
        [lines]
    );

    if (!rows) {
        return null;
    }

    return (
        <div className="max-w-[80%]">
            <SharedTable headers={HEADERS} rows={rows} />
        </div>
    );
}

LinesTable.propTypes = {
    lines: PropTypes.arrayOf(
        PropTypes.shape({
            datetime: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            amount_cents: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default LinesTable;
