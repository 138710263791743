import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectActiveOrg } from "../../../../redux/slices/authentication";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import { MMM_D_YYYY_TIME_FORMAT } from "../../../../utils";
import { PropelAuthOrgPermissions } from "../../../../constants";
import { SharedButton } from "../../../shared";
import { useRevokePendingInviteForProviderOrgMutation } from "../../../../redux/slices/apiV2";

export default function PendingInviteCard({ providerId, invite, isLast }) {
    const isAdminDashboard = useIsAdminDashboard();
    const activeOrg = useSelector(selectActiveOrg);
    const [revokePendingInvite] =
        useRevokePendingInviteForProviderOrgMutation();

    // Revoking an invite requires platform admin propelauth::can_invite as there is no can_revoke_invite permission
    const canRevokeInvite =
        isAdminDashboard ||
        activeOrg.userPermissions.includes(PropelAuthOrgPermissions.CAN_INVITE);

    const handleRevokeInvite = async () => {
        await revokePendingInvite({
            providerId,
            body: {
                invitee_email: invite.invitee_email,
                org_id: invite.org_id,
            },
        });
    };

    return (
        <div
            className={`grid grid-cols-[2fr_1fr_1.5fr_1.5fr_1.5fr] gap-x-[10px] text-[14px] items-center border-gray-200 py-[6px] ${
                !isLast && "border-b"
            }`}
        >
            <div className="font-semibold text-[16px]">
                {invite.invitee_email}
            </div>
            <div>{invite.role_in_org}</div>
            <div>
                {dayjs.unix(invite.created_at).format(MMM_D_YYYY_TIME_FORMAT)}
            </div>
            <div>
                {dayjs.unix(invite.expires_at).format(MMM_D_YYYY_TIME_FORMAT)}
            </div>
            <div className="flex justify-center">
                <SharedButton
                    className="rounded-full w-fit px-[12px] py-[8px]"
                    color="error"
                    disabled={!canRevokeInvite}
                    onClick={() => {
                        handleRevokeInvite();
                    }}
                >
                    Revoke Invite
                </SharedButton>
            </div>
        </div>
    );
}

PendingInviteCard.propTypes = {
    providerId: PropTypes.string.isRequired,
    invite: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired,
};
