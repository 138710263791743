import React from "react";
import { useNavigate } from "react-router-dom";
import PartnerServicesHeaderSection from "./PartnerServicesHeaderSection";
import { SharedButton } from "../shared";
import { SharedPageTitle } from "../shared/Text";
import PartnerServicesCard from "./PartnerServicesCard";
import TaxesSvg from "../../assets/Taxes";
import LandmarkSvg from "../../assets/Landmark";
import ScaleSvg from "../../assets/Scale";
import TrendingUpSvg from "../../assets/TrendingUp";

function PartnerServices() {
    const navigate = useNavigate();

    const cards = [
        {
            title: "Tax Filing",
            body: "Our two tax partners offer expert federal and state tax filing for individuals and businesses, ensuring compliance and maximizing savings.",
            icon: <TaxesSvg fill="#8D58FF" height={28} width={28} />,
            action: (
                <SharedButton onClick={() => navigate("taxes")}>
                    Learn More
                </SharedButton>
            ),
        },
        {
            title: "Banking",
            body: "Flychain and Mercury partner to deliver exceptional banking for healthcare. Trusted by 200K+ startups of all sizes, Mercury helps providers scale effectively.",
            icon: <LandmarkSvg fill="#8D58FF" height={28} width={28} />,
            action: (
                <SharedButton onClick={() => navigate("banking")}>
                    Learn More
                </SharedButton>
            ),
        },
        {
            title: "Treasury",
            body: "Flychain and Treasure have partnered to make your money work for you by investing in low risk asset classes such a money market funds, treasuries, and corporate bonds.",
            icon: <TrendingUpSvg fill="#8D58FF" height={28} width={28} />,
            action: (
                <SharedButton onClick={() => navigate("treasury")}>
                    Learn More
                </SharedButton>
            ),
        },
        {
            title: "Legal",
            body: "Flychain’s legal partner offers expert support for healthcare providers, including entity formation, contracts, compliance, and M&A. Protect and grow your practice with tailored legal services.",
            icon: <ScaleSvg fill="#8D58FF" height={28} width={28} />,
            action: <SharedButton disabled>Coming Soon!</SharedButton>,
        },
    ];

    return (
        <div className="flex flex-col">
            <PartnerServicesHeaderSection />
            <div className="py-[60px] px-[100px]">
                <div className="text-center mb-[50px]">
                    <SharedPageTitle>Our Partner Services</SharedPageTitle>
                </div>
                <div className="flex justify-center">
                    <div className="grid grid-cols-2 gap-[40px]">
                        {cards.map((card, i) => (
                            <PartnerServicesCard
                                key={i}
                                title={card.title}
                                body={card.body}
                                icon={card.icon}
                                action={card.action}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartnerServices;
