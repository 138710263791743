import React from "react";
import PropTypes from "prop-types";
import { SharedCard } from "../shared";

function PartnerServicesCard({ title, body, icon, action }) {
    return (
        <SharedCard
            showTab={false}
            className="flex flex-col items-center gap-y-[16px]"
        >
            <div className="flex flex-col justify-between items-center h-full gap-y-[40px]">
                <div className="flex flex-col items-center">
                    <div className="flex flex-row items-center gap-x-[16px] justify-center ">
                        {icon}
                        <div className="text-xl font-semibold">{title}</div>
                    </div>
                    <div className="text-base font-normal text-center text-flychainGray mt-[20px]">
                        {body}
                    </div>
                </div>
                {action}
            </div>
        </SharedCard>
    );
}

PartnerServicesCard.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    action: PropTypes.node.isRequired,
};

export default PartnerServicesCard;
