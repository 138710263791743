import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Tooltip from "@mui/material/Tooltip";
import { SharedButton } from "../../../../shared";
import CircleCheckSvg from "../../../../../assets/CircleCheck";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";
import { useCollectPaymentForProviderMutation } from "../../../../../redux/slices/apiV2";

dayjs.extend(utc);

function CollectionTable({
    selectedPayments,
    setSelectedPayments,
    collectedPaymentIds,
    setCollectedPaymentIds,
}) {
    const [collectPayment] = useCollectPaymentForProviderMutation();

    const paymentIsCollected = (paymentId) =>
        collectedPaymentIds.includes(paymentId);

    const onClick = (paymentId, providerId) => {
        const paymentBody = {
            payment_id: paymentId,
        };
        collectPayment({
            body: paymentBody,
            providerId,
        })
            .unwrap()
            .then((response) => {
                setSelectedPayments(
                    selectedPayments.map((prevPayment) => {
                        if (prevPayment.id === paymentId) {
                            return { ...prevPayment, response };
                        }
                        return prevPayment;
                    })
                );
                setCollectedPaymentIds((prevPaymentIds) => [
                    ...prevPaymentIds,
                    paymentId,
                ]);
            })
            .catch((collectPaymentError) => {
                setSelectedPayments(
                    selectedPayments.map((prevPayment) => {
                        if (prevPayment.id === paymentId) {
                            return {
                                ...prevPayment,
                                response: collectPaymentError,
                            };
                        }
                        return prevPayment;
                    })
                );
            });
    };

    const renderTableRows = () =>
        selectedPayments.map((payment) => (
            <div
                key={payment.id}
                className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr_1fr_1fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
            >
                <div>{payment.id}</div>
                <div>{getCurrencyString(payment.payment_amount)}</div>
                <div>
                    {dayjs(payment.earliest_payment_date)
                        .utc()
                        .format(MMMM_D_YYYY_FORMAT)}
                </div>
                <div>{payment.draw_id}</div>
                <div className="flex justify-center w-full">
                    {paymentIsCollected(payment.id) ? (
                        <div className="flex flex-row items-center gap-x-[10px]">
                            <CircleCheckSvg
                                fill="#00B894"
                                width={18}
                                height={18}
                            />
                            Collected
                        </div>
                    ) : (
                        <SharedButton
                            className="rounded-full w-fit px-[10px] py-[5px]"
                            onClick={() =>
                                onClick(payment.id, payment.provider_id)
                            }
                        >
                            Collect Payment
                        </SharedButton>
                    )}
                </div>
                <div className="flex justify-center w-full">
                    {payment.response ? (
                        <Tooltip
                            title={
                                <p>
                                    Collection Response:
                                    <br />
                                    <br />
                                    {JSON.stringify(payment.response)}
                                </p>
                            }
                        >
                            <div
                                className={`text-sm font-semibold underline cursor-help w-fit ${
                                    paymentIsCollected(payment.id)
                                        ? "text-flychainGreen"
                                        : "text-flychainError"
                                }`}
                            >
                                View Response
                            </div>
                        </Tooltip>
                    ) : (
                        "No response yet"
                    )}
                </div>
            </div>
        ));

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr_1fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                <div>Payment ID</div>
                <div>Amount</div>
                <div>Earliest Payment Date</div>
                <div>Draw ID</div>
                <div className="flex flex-row justify-center">
                    Collect Payment
                </div>
                <div className="flex flex-row justify-center">Response</div>
            </div>
            {renderTableRows()}
        </div>
    );
}

CollectionTable.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setSelectedPayments: PropTypes.func.isRequired,
    collectedPaymentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setCollectedPaymentIds: PropTypes.func.isRequired,
};

export default CollectionTable;
