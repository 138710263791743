import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
    Legend,
} from "recharts";
import { updateDateRange } from "../../../redux/slices/dateRange";
import { getCurrencyString, YYYY_MM_DD_FORMAT } from "../../../utils";
import {
    tickStyle,
    formatMonthYearStringForXAxis,
    splitMetric,
    VALID_DATE_FORMATS,
} from "../utils";
import StackedBarGraphTooltip from "./StackedBarGraphTooltip";
import StackedBarGraphLegend from "./StackedBarGraphLegend";
import SharedDefsContextProvider, {
    useSharedDefs,
} from "../SharedDefsContextProvider";
import COLORS from "./colors";
import { useGetFinancialReportsValidUpUntilQuery } from "../../../redux/slices/apiV2";
import useGetProviderId from "../../../hooks/useGetProviderId";

const getDataPoints = (data, validUpUntil) =>
    data.slice(-12).map((monthData) => {
        const ledgersObject = monthData.ledgers.reduce(
            (acc, ledger) => {
                const metric = splitMetric(
                    ledger.amount_cents,
                    monthData.month,
                    VALID_DATE_FORMATS.MMMM_YYYY,
                    validUpUntil
                );
                const proportion = splitMetric(
                    ledger.proportion,
                    monthData.month,
                    VALID_DATE_FORMATS.MMMM_YYYY,
                    validUpUntil
                );

                acc.totalUnverified += metric.unverified || 0;

                return {
                    ...acc,
                    [ledger.ledger_id]: metric.verified,
                    [`${ledger.ledger_id} Unverified`]: metric.unverified,
                    [`${ledger.ledger_id} Name`]: ledger.ledger_name,
                    [`${ledger.ledger_id} Proportion`]: proportion.verified,
                    [`${ledger.ledger_id} Proportion Unverified`]:
                        proportion.unverified,
                };
            },
            { totalUnverified: 0 }
        );
        return {
            month: monthData.month,
            ...ledgersObject,
        };
    });

const getOrderedLedgerIds = (data) => {
    const ledgerTotals = new Map();

    data.forEach((monthData) => {
        monthData.ledgers.forEach((ledger) => {
            if (ledgerTotals.has(ledger.ledger_id)) {
                ledgerTotals.set(
                    ledger.ledger_id,
                    ledgerTotals.get(ledger.ledger_id) + ledger.amount_cents
                );
            } else {
                ledgerTotals.set(ledger.ledger_id, ledger.amount_cents);
            }
        });
    });

    const orderedLedgerIds = Array.from(ledgerTotals.entries())
        .sort((a, b) => b[1] - a[1]) // Sort by total amount_cents
        .map(([ledgerId]) => ledgerId); // Extract ledger ids

    return orderedLedgerIds;
};

const getLedgerColorMap = (ledgerIds) =>
    ledgerIds.reduce((acc, ledgerId, index) => {
        acc[ledgerId] = COLORS[index % COLORS.length]; // Cycle through colors if more ledgers than colors
        return acc;
    }, {});

function StackedBarGraphContent({ data }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId,
        businessEntityId,
    });
    const defs = useSharedDefs();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    if (!validUpUntilData) return null;

    const validUpUntil = validUpUntilData.valid_up_until;

    const dataPoints = getDataPoints(data, validUpUntil);
    const ledgerIds = getOrderedLedgerIds(data);
    const ledgerColorMap = getLedgerColorMap(ledgerIds);

    const handleClick = (graphData, ledgerId) => {
        const { month } = graphData;
        const date = dayjs(month, "MMMM YYYY");

        setDateRange({
            startDate: date.startOf("month").format(YYYY_MM_DD_FORMAT),
            endDate: date.endOf("month").format(YYYY_MM_DD_FORMAT),
        });

        navigate(`ledgers/${ledgerId}`);
    };

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dataPoints} barGap={-24}>
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                    domain={[0, "dataMax"]}
                />
                <Tooltip
                    content={
                        <StackedBarGraphTooltip validUpUntil={validUpUntil} />
                    }
                    shared={false}
                />
                <Legend content={<StackedBarGraphLegend />} />
                {defs}
                {ledgerIds.map((ledgerId) => (
                    <Bar
                        key={ledgerId}
                        dataKey={ledgerId}
                        stackId="stack"
                        barSize={24}
                        fill={ledgerColorMap[ledgerId]}
                        onClick={(graphData) =>
                            handleClick(graphData, ledgerId)
                        }
                        style={{ cursor: "pointer" }}
                    />
                ))}
                {ledgerIds.map((ledgerId) => (
                    <Bar
                        key={`${ledgerId} Unverified`}
                        dataKey={`${ledgerId} Unverified`}
                        stackId="stack"
                        barSize={24}
                        fill={ledgerColorMap[ledgerId]}
                        fillOpacity={0.3}
                        onClick={(graphData) =>
                            handleClick(graphData, ledgerId)
                        }
                        style={{ cursor: "pointer" }}
                    />
                ))}
                <Bar
                    key="Total Unverified"
                    dataKey="totalUnverified"
                    barSize={24}
                    fill="none"
                    stroke="#8D58FF"
                    strokeWidth={2}
                    strokeDasharray="3 3"
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

const ledgerShape = PropTypes.shape({
    ledger_name: PropTypes.string.isRequired,
    amount_cents: PropTypes.number.isRequired,
    proportion: PropTypes.number.isRequired,
    ledger_id: PropTypes.string.isRequired,
});

const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        ledgers: PropTypes.arrayOf(ledgerShape).isRequired,
    }).isRequired
);

StackedBarGraphContent.propTypes = {
    data: dataProps.isRequired,
};

function StackedBarGraph({ data }) {
    return (
        <SharedDefsContextProvider>
            <StackedBarGraphContent data={data} />
        </SharedDefsContextProvider>
    );
}

StackedBarGraph.propTypes = {
    data: dataProps.isRequired,
};

export default StackedBarGraph;
