import { useRef } from "react";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import raiseError from "../../redux/slices/error/thunks/raiseError";
import {
    useLazyGetPlaidLinkTokenQuery,
    useCreatePlaidItemMutation,
    useUpdatePlaidItemMutation,
} from "../../redux/slices/apiV2";
import useGetProviderId from "../useGetProviderId";

function usePlaidLink(internalPlaidItemId) {
    const providerId = useGetProviderId();
    const plaidHandlerRef = useRef(null);
    const dispatch = useDispatch();
    const [triggerGetPlaidLinkToken] = useLazyGetPlaidLinkTokenQuery();
    const [triggerCreatePlaidItemMutation] = useCreatePlaidItemMutation();
    const [triggerUpdatePlaidItemMutation] = useUpdatePlaidItemMutation();

    const onLinkSuccess = (publicToken) => {
        if (internalPlaidItemId) {
            triggerUpdatePlaidItemMutation({
                providerId,
                internalPlaidItemId,
            });
        } else {
            triggerCreatePlaidItemMutation({
                providerId,
                body: { public_token: publicToken },
            });
        }

        plaidHandlerRef.current.destroy();
    };

    const onExit = () => {
        plaidHandlerRef.current.destroy();
    };

    const openPlaidLink = async () => {
        let linkToken;
        try {
            const result = await triggerGetPlaidLinkToken({
                providerId,
                params: internalPlaidItemId ? { internalPlaidItemId } : {},
            }).unwrap();
            linkToken = result.link_token;
        } catch (error) {
            // We don't need to raiseException here because middleware will handle exceptions
            return;
        }

        try {
            const config = {
                token: linkToken,
                onSuccess: onLinkSuccess,
                onExit,
            };
            // eslint-disable-next-line no-undef
            const handler = Plaid.create(config);
            plaidHandlerRef.current = handler;
            plaidHandlerRef.current.open();
        } catch (error) {
            dispatch(raiseError(error));
        }
    };

    return { openPlaidLink };
}

export default usePlaidLink;
