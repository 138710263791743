import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../hooks/useGetProviderId";
import useGetGraphPeriodOptions from "../../../hooks/useGetGraphPeriodOptions";
import { GRAPH_PERIOD } from "../constants";
import { useGetLedgerTotalsGraphDataQuery } from "../../../redux/slices/apiV2";
import { SharedSectionTitle } from "../../shared/Text";
import { SharedStateSelectInput } from "../../shared/Inputs";
import SharedMonthlyGraph from "../../Graphs/MonthlyGraph";

function LedgerLinesMonthlyGraph({ ledgerName }) {
    const providerId = useGetProviderId();
    const { businessEntityId, ledgerId } = useParams();
    const [ledgerGraphPeriod, setLedgerGraphPeriod] = useState(
        GRAPH_PERIOD.LAST_12_MONTHS
    );
    const {
        data: graphPeriodOptions,
        isFetching: isFetchingGraphPeriodOptions,
    } = useGetGraphPeriodOptions({
        providerId,
        businessEntityId,
    });
    const { data: ledgerTotalsGraphData } = useGetLedgerTotalsGraphDataQuery({
        providerId,
        businessEntityId,
        ledgerId,
        params: { period: ledgerGraphPeriod },
    });

    if (
        !ledgerTotalsGraphData ||
        !graphPeriodOptions ||
        isFetchingGraphPeriodOptions
    ) {
        return null;
    }

    return (
        <div className="flex flex-col gap-y-[20px]">
            <div className="flex flex-row justify-between items-center">
                <SharedSectionTitle>Monthly Totals</SharedSectionTitle>
                <div className="mt-[5px]">
                    <SharedStateSelectInput
                        name="Ledger Period Selector"
                        label="Period"
                        variant="outlined"
                        size="small"
                        options={graphPeriodOptions}
                        currentValue={ledgerGraphPeriod}
                        onChange={(value) => {
                            setLedgerGraphPeriod(value);
                        }}
                    />
                </div>
            </div>
            <SharedMonthlyGraph
                data={ledgerTotalsGraphData}
                ledgerName={ledgerName}
            />
        </div>
    );
}

LedgerLinesMonthlyGraph.propTypes = {
    ledgerName: PropTypes.string.isRequired,
};

export default LedgerLinesMonthlyGraph;
