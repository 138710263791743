import React, { useEffect, useState, useRef } from "react";
import Provider from "./Provider";
import { SharedPageTitle } from "../../shared/Text";
import { SharedStateTextInput } from "../../shared/Inputs";
import CloseSvg from "../../../assets/Close.svg";
import { useGetProvidersQuery } from "../../../redux/slices/apiV2";

function GeneralLedgerDownload() {
    const scrollContainerRef = useRef(null);
    const { data: providersOnboardedToTeal } = useGetProvidersQuery({
        params: {
            onboardedToTeal: true,
        },
    });
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (providersOnboardedToTeal) {
            const filtered = Object.values(providersOnboardedToTeal).filter(
                (provider) =>
                    provider.legal_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    (provider.dba &&
                        provider.dba
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()))
            );

            setFilteredProviders(filtered);
        }
    }, [searchTerm, providersOnboardedToTeal]);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    }, [filteredProviders]);

    const clearSearch = () => {
        setSearchTerm("");
    };

    if (!providersOnboardedToTeal) return null;

    return (
        <div className="flex flex-col h-full pt-[60px] pl-[60px] gap-y-[20px]">
            <SharedPageTitle>Download General Ledger</SharedPageTitle>
            <div className="flex flex-row items-center w-full gap-x-[20px] pr-[60px]">
                <SharedStateTextInput
                    className="w-full"
                    name="search"
                    placeholder="Search for a provider"
                    value={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                />
                <img
                    className="cursor-pointer h-[15px]"
                    src={CloseSvg}
                    alt="Close"
                    onClick={clearSearch}
                />
            </div>
            {filteredProviders.length > 0 ? (
                <div
                    ref={scrollContainerRef}
                    className="flex flex-col gap-y-[20px] overflow-y-auto scrollbar scrollbar-thumb-flychainPurple-light grow pr-[30px] pb-[30px] mr-[30px]"
                >
                    {filteredProviders.map((provider) => (
                        <Provider key={provider.id} provider={provider} />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center h-full pb-[30px] pr-[60px]">
                    <div className="text-[24px] font-semibold">
                        No providers found
                    </div>
                </div>
            )}
        </div>
    );
}

export default GeneralLedgerDownload;
