import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import { SharedCard, SharedButton } from "../shared";
import FlychainLogoSvg from "../../assets/FlychainLogo.svg";
import CircleCheckSvg from "../../assets/CircleCheck";
import { PropelAuthResendEmailStatus, AuthPageEnums } from "../../constants";

function EmailConfirmation({
    setAuthPage,
    confirmationEmail,
    setConfirmationEmail,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [resendEmailStatus, setResendEmailStatus] = useState(null);
    const { resendEmailConfirmation } = useAuthFrontendApis();

    const handleResendEmailConfirmation = async () => {
        setIsLoading(true);
        const response = await resendEmailConfirmation();
        response.handle({
            success: () => {
                setResendEmailStatus(PropelAuthResendEmailStatus.SUCCESS);
                setIsLoading(false);
            },
            rateLimited: () => {
                setResendEmailStatus(PropelAuthResendEmailStatus.RATE_LIMITED);
                setIsLoading(false);
            },
            emailAlreadyConfirmed: () => {
                setResendEmailStatus(
                    PropelAuthResendEmailStatus.EMAIL_ALREADY_CONFIRMED
                );
                setIsLoading(false);
            },
            badRequest: () => {
                setResendEmailStatus(PropelAuthResendEmailStatus.BAD_REQUEST);
                setIsLoading(false);
            },
            unexpectedOrUnhandled: () => {
                setResendEmailStatus(
                    PropelAuthResendEmailStatus.UNEXPECTED_OR_UNHANDLED
                );
                setIsLoading(false);
            },
        });
    };

    const renderAction = () => {
        const goToLogIn = (
            <div
                className="underline cursor-pointer text-flychainPurple-light"
                onClick={() => {
                    setConfirmationEmail("");
                    setAuthPage(AuthPageEnums.LOG_IN);
                }}
            >
                Go to Log In
            </div>
        );

        switch (resendEmailStatus) {
            case PropelAuthResendEmailStatus.SUCCESS:
                return (
                    <div className="flex flex-row items-center mt-[15px] gap-x-[5px]">
                        <CircleCheckSvg width={15} height={15} fill="#00B894" />
                        <div className="text-flychainGray">
                            Email sent successfully.
                        </div>
                        {goToLogIn}
                    </div>
                );
            case PropelAuthResendEmailStatus.RATE_LIMITED:
                return (
                    <div className="flex flex-col text-center mt-[15px] gap-y-[5px]">
                        <div className="text-flychainGray">
                            Please wait, it may take a few minutes to receive
                            your confirmation email.
                        </div>
                        {goToLogIn}
                    </div>
                );
            case PropelAuthResendEmailStatus.EMAIL_ALREADY_CONFIRMED:
                return (
                    <div className="flex flex-row items-center mt-[15px] gap-x-[5px]">
                        <div className="text-flychainGray">
                            The email has already been confirmed.
                        </div>
                        {goToLogIn}
                    </div>
                );
            case PropelAuthResendEmailStatus.BAD_REQUEST:
            case PropelAuthResendEmailStatus.UNEXPECTED_OR_UNHANDLED:
                return (
                    <div className="flex flex-row items-center mt-[15px] gap-x-[5px]">
                        <div className="text-flychainError">
                            An unknown error occured.
                        </div>
                        {goToLogIn}
                    </div>
                );
            case null:
            default:
                return (
                    <>
                        <div className="flex flex-row items-center gap-x-[10px]">
                            <div className="text-flychainGray text-[15px]">
                                Didn&apos;t get the email?
                            </div>
                            <SharedButton
                                className="rounded-full w-fit px-[10px] py-[8px]"
                                onClick={handleResendEmailConfirmation}
                                disabled={isLoading}
                                loadingText={
                                    isLoading ? "Sending..." : undefined
                                }
                            >
                                Resend email
                            </SharedButton>
                        </div>
                        {goToLogIn}
                    </>
                );
        }
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <SharedCard className="max-w-[60%] bg-white">
                <div className="flex flex-col justify-center items-center gap-y-[25px] ">
                    <img
                        src={FlychainLogoSvg}
                        alt="Flychain Logo"
                        className="w-[160px]"
                    />
                    <div className="flex flex-col gap-y-[10px] text-center">
                        <div className="text-[16px] text-flychainPurple-dark">
                            We sent a confirmation email to{" "}
                            <span className="font-semibold">
                                {confirmationEmail}
                            </span>
                            .
                        </div>

                        <div className="text-[16px] text-flychainPurple-dark">
                            Click the link in the email to verify your account.
                        </div>
                    </div>
                    {renderAction()}
                </div>
            </SharedCard>
        </div>
    );
}

EmailConfirmation.propTypes = {
    setAuthPage: PropTypes.func.isRequired,
    confirmationEmail: PropTypes.string.isRequired,
    setConfirmationEmail: PropTypes.func.isRequired,
};

export default EmailConfirmation;
