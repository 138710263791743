import React, { useState } from "react";
import * as yup from "yup";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthFrontendApis } from "@propelauth/frontend-apis-react";
import {
    PropelAuthChangePasswordErrorType,
    PropelAuthSignUpBadRequestErrors,
} from "../../../../constants";
import { SharedButton, SharedSupportModal } from "../../../shared";
import {
    SharedFormTextInput,
    SharedRequiredMessage,
} from "../../../shared/Inputs";
import ChangePasswordError from "./ChangePasswordError";
import ChangePasswordSuccessModal from "./ChangePasswordSuccessModal";
import DownCaret from "../../../../assets/DownCaret.svg";
import RightCaret from "../../../../assets/RightCaret.svg";

const defaultValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const schema = yup.object({
    currentPassword: yup.string().required(SharedRequiredMessage),
    newPassword: yup
        .string()
        .required(SharedRequiredMessage)
        .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
        .string()
        .required(SharedRequiredMessage)
        .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export default function ChangePassword() {
    const [showContent, setShowContent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorType, setErrorType] = useState(null);
    const [contactSupport, setContactSupport] = useState(false);
    const { updatePassword } = useAuthFrontendApis();

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const newPassword = watch("newPassword");
    const confirmPassword = watch("confirmPassword");

    const handleErrorResponse = (newErrorType, error) => {
        Sentry.captureException(new Error(JSON.stringify(error)));
        setErrorType(newErrorType);
    };

    const onPasswordSubmit = async (formData) => {
        setIsLoading(true);
        setErrorType(null);
        const response = await updatePassword({
            current_password: formData.currentPassword,
            password: formData.newPassword,
        });
        response.handle({
            success: () => {
                setIsSuccess(true);
                setIsLoading(false);
            },
            incorrectPassword: () => {
                setError("currentPassword", {
                    message: "The password is incorrect",
                });
                setIsLoading(false);
            },
            badRequest: (error) => {
                if (
                    error.field_errors.password ===
                    PropelAuthSignUpBadRequestErrors.COMMON_PASSWORD
                ) {
                    setError("newPassword", {
                        message: error.user_facing_errors.password,
                    });
                } else {
                    handleErrorResponse(
                        PropelAuthChangePasswordErrorType.BAD_REQUEST,
                        error
                    );
                }
                setIsLoading(false);
            },
            userAccountLocked: (error) => {
                handleErrorResponse(
                    PropelAuthChangePasswordErrorType.USER_ACCOUNT_LOCKED,
                    error
                );
                setIsLoading(false);
            },
            unexpectedOrUnhandled(error) {
                handleErrorResponse(
                    PropelAuthChangePasswordErrorType.UNEXPECTED_OR_UNHANDLED,
                    error
                );
                setIsLoading(false);
            },
        });
    };

    const triggerPasswordUpdate = () => {
        handleSubmit(onPasswordSubmit)();
    };

    return (
        <div className="flex flex-col pt-[16px]">
            <div
                className="flex flex-row items-center cursor-pointer gap-x-[8px]"
                onClick={() => setShowContent(!showContent)}
            >
                <div className="text-[18px] font-semibold">Change Password</div>
                <img
                    src={showContent ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[12px] h-[12px] brightness-0"
                />
            </div>
            {showContent && (
                <>
                    <div className="flex flex-col mt-[6px]">
                        <div
                            className={`focus-within:mt-[12px] ${
                                newPassword && "mt-[12px]"
                            }`}
                        >
                            <SharedFormTextInput
                                className="w-[250px]"
                                type="password"
                                name="currentPassword"
                                label="Current password"
                                control={control}
                                error={errors.currentPassword}
                            />
                        </div>
                        <div
                            className={`focus-within:mt-[12px] ${
                                newPassword && "mt-[12px]"
                            }`}
                        >
                            <SharedFormTextInput
                                className="w-[250px]"
                                type="password"
                                name="newPassword"
                                label="New password"
                                control={control}
                                error={errors.newPassword}
                            />
                        </div>
                        <div
                            className={`focus-within:mt-[12px] ${
                                confirmPassword && "mt-[12px]"
                            }`}
                        >
                            <SharedFormTextInput
                                className="w-[250px]"
                                type="password"
                                name="confirmPassword"
                                label="Confirm password"
                                control={control}
                                error={errors.confirmPassword}
                            />
                        </div>
                    </div>
                    <div className="mt-[25px]">
                        {errorType && (
                            <div className="mb-[12px]">
                                <ChangePasswordError
                                    errorType={errorType}
                                    setContactSupport={setContactSupport}
                                />
                            </div>
                        )}
                        <SharedButton
                            onClick={triggerPasswordUpdate}
                            disabled={isLoading}
                            loadingText={
                                isLoading ? "Updating password..." : undefined
                            }
                        >
                            Update password
                        </SharedButton>
                    </div>
                </>
            )}
            {isSuccess && <ChangePasswordSuccessModal />}
            {contactSupport && (
                <SharedSupportModal onClose={() => setContactSupport(false)} />
            )}
        </div>
    );
}
