import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectActiveOrg } from "../../../../redux/slices/authentication";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { PropelAuthOrgPermissions } from "../../../../constants";
import { useGetUsersForProviderQuery } from "../../../../redux/slices/apiV2";
import { SharedButton } from "../../../shared";
import { SharedStateTextInput } from "../../../shared/Inputs";
import { SharedSectionTitle } from "../../../shared/Text";
import OrgMemberCard from "./OrgMemberCard";
import InviteUserModal from "./InviteUserModal";
import CloseSvg from "../../../../assets/Close.svg";
import PlusSvg from "../../../../assets/Plus";
import TriangleAlertSvg from "../../../../assets/TriangleAlert";
import DownCaret from "../../../../assets/DownCaret.svg";
import RightCaret from "../../../../assets/RightCaret.svg";

export default function OrgMemberTable() {
    const providerId = useGetProviderId();
    const activeOrg = useSelector(selectActiveOrg);
    const isAdminDashboard = useIsAdminDashboard();
    const [showContent, setShowContent] = useState(true);
    const [userQuery, setUserQuery] = useState("");
    const [orgMemberResults, setOrgMemberResults] = useState([]);
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const { data: users, isFetching } = useGetUsersForProviderQuery({
        providerId,
    });

    useEffect(() => {
        if (users) {
            const filteredUsers = users.users.filter(
                (user) =>
                    user.email
                        .toLowerCase()
                        .includes(userQuery.toLowerCase()) ||
                    user.last_name
                        .toLowerCase()
                        .includes(userQuery.toLowerCase()) ||
                    user.first_name
                        .toLowerCase()
                        .includes(userQuery.toLowerCase())
            );
            setOrgMemberResults(filteredUsers);
        }
    }, [users, userQuery]);

    const canInviteUsers =
        isAdminDashboard ||
        activeOrg.userPermissions.includes(PropelAuthOrgPermissions.CAN_INVITE);

    if (!users || isFetching) {
        return null;
    }

    const renderOrgMembers = () => {
        if (orgMemberResults.length === 0) {
            return (
                <div className="flex flex-row justify-center items-center gap-x-[10px] py-[20px] text-[16px] text-flychainPurple-dark">
                    <TriangleAlertSvg fill="#110823" width={20} height={20} />
                    No org members found for your query
                </div>
            );
        }

        return (
            <div className="flex flex-col">
                <div className="grid grid-cols-[2fr_2fr_1fr_1fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                    <div>Name</div>
                    <div>Email</div>
                    <div className="flex justify-center">Status</div>
                    <div className="flex justify-center">Role</div>
                    <div className="flex justify-center">Remove User</div>
                </div>
                {orgMemberResults.map((orgMember, index) => (
                    <OrgMemberCard
                        key={orgMember.user_id}
                        providerId={providerId}
                        propelOrgId={users.propel_org_id}
                        orgMember={orgMember}
                        isLast={index === orgMemberResults.length - 1}
                    />
                ))}
            </div>
        );
    };

    return (
        <div
            className={`flex flex-col border-t ${
                showContent ? "pt-[16px]" : "py-[16px]"
            }`}
        >
            <div
                className="flex flex-row items-center gap-x-[10px] cursor-pointer"
                onClick={() => setShowContent(!showContent)}
            >
                <SharedSectionTitle>Organization Members</SharedSectionTitle>
                <img
                    src={showContent ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[14px] h-[14px] brightness-0"
                />
            </div>
            {showContent && (
                <div className="flex flex-col gap-y-[15px] mt-[5px]">
                    <div className="flex flex-row justify-between items-center w-full gap-x-[40px]">
                        <div className="flex flex-row items-center w-full gap-x-[20px]">
                            <SharedStateTextInput
                                className="w-full"
                                name="search"
                                placeholder="Search for members of the organization"
                                value={userQuery}
                                onChange={(value) => setUserQuery(value)}
                            />
                            <img
                                className="cursor-pointer h-[15px]"
                                src={CloseSvg}
                                alt="Close"
                                onClick={() => setUserQuery("")}
                            />
                        </div>
                        <SharedButton
                            disabled={!canInviteUsers}
                            onClick={() => setShowInviteUserModal(true)}
                        >
                            <div className="flex flex-row items-center gap-x-[5px]">
                                <PlusSvg fill="white" width={18} height={18} />
                                Invite User
                            </div>
                        </SharedButton>
                    </div>
                    {renderOrgMembers()}
                    {showInviteUserModal && (
                        <InviteUserModal
                            providerId={providerId}
                            propelOrgId={users.propel_org_id}
                            setShowInviteUserModal={setShowInviteUserModal}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
