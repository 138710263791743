import React from "react";
import PropTypes from "prop-types";
import { CALENDLY_URLS } from "../../CalendlyModal/constants";
import { SharedPageTitle } from "../../shared/Text";
import TaxPartnerCard from "./TaxPartnerCard";
import UpriseIcon from "../../../assets/UpriseIcon.png";
import RivetIcon from "../../../assets/RivetIcon.png";
import UsersRoundSvg from "../../../assets/UsersRound";
import BookOpenCheckSvg from "../../../assets/BookOpenCheck";
import ActivitySvg from "../../../assets/Activity";
import MessagesSquareSvg from "../../../assets/MessagesSquare";
import CircleDollarSign from "../../../assets/CircleDollarSign";
import ShieldCheckSvg from "../../../assets/ShieldCheck";
import FileCheckSvg from "../../../assets/FileCheck";

function TaxPartners({ setTaxCallModalUrl }) {
    const upriseCards = [
        {
            icon: <UsersRoundSvg fill="#000" height={24} width={24} />,
            title: "Corporate and Personal",
            description:
                "Corporate tax and personal taxes for sole proprietors, LLCs, and S Corps.",
        },
        {
            icon: <BookOpenCheckSvg fill="#000" height={24} width={24} />,
            title: "Tax Strategy",
            description:
                "Comprehensive tax strategy for both your business and personal taxes.",
        },
        {
            icon: <ActivitySvg fill="#000" height={24} width={24} />,
            title: "Continuous Financial Monitoring",
            description:
                "Proactive tracking of your finances to identify new opportunities and optimizations.",
        },
        {
            icon: <MessagesSquareSvg fill="#000" height={24} width={24} />,
            title: "Ongoing Expert Support",
            description:
                "Unlimited messaging and calls with your Certified Financial Planner.",
        },
    ];

    const rivetCards = [
        {
            icon: <UsersRoundSvg fill="#000" height={24} width={24} />,
            title: "Corporate and Personal",
            description:
                "Corporate tax and personal taxes for sole proprietors, LLCs, and S Corps.",
        },
        {
            icon: <CircleDollarSign fill="#000" height={24} width={24} />,
            title: "R&D Credits",
            description:
                "Secure R&D credits and other tax refunds at the state and federal level.",
        },
        {
            icon: <ShieldCheckSvg fill="#000" height={24} width={24} />,
            title: "Audit Defenses",
            description:
                "Audit defenese are included even if Rivet didn’t prepare your original return.",
        },
        {
            icon: <FileCheckSvg fill="#000" height={24} width={24} />,
            title: "Fixed Price Engagements",
            description:
                "Rivet gives upfront and transparent quotes before starting an engagement.",
        },
    ];

    return (
        <div className="flex flex-col gap-y-[60px] py-[60px] px-[100px] ">
            <SharedPageTitle className="text-[36px] text-center">
                Two partners: Uprise and Rivet
            </SharedPageTitle>
            <div className="flex flex-col gap-y-[60px] justify-center items-center">
                <TaxPartnerCard
                    cardColor="bg-gradient-to-bl from-[#CCE6F0] via-[#e6f3f8] to-white"
                    partnerName="Uprise"
                    partnerLogo={
                        <div className="bg-white rounded-full p-[8px]">
                            <img
                                src={UpriseIcon}
                                alt="Uprise Logo"
                                className="w-[64px] h-[64px]"
                            />
                        </div>
                    }
                    partnerDescription={
                        <>
                            For businesses with{" "}
                            <span className="font-semibold">
                                less than $5M in annual revenue
                            </span>
                        </>
                    }
                    callToActionOnClick={() =>
                        setTaxCallModalUrl(CALENDLY_URLS.UPRISE_TAX_CALL)
                    }
                    subCards={upriseCards}
                />
                <TaxPartnerCard
                    cardColor="bg-gradient-to-bl from-[#DBEAC3] via-[#e8f3eb] to-white"
                    partnerName="Rivet"
                    partnerLogo={
                        <img
                            src={RivetIcon}
                            alt="Rivet Logo"
                            className="w-[80px] h-[80px] rounded-full"
                        />
                    }
                    partnerDescription={
                        <>
                            For businesses with{" "}
                            <span className="font-semibold">
                                more than $5M in annual revenue
                            </span>
                        </>
                    }
                    callToActionOnClick={() =>
                        setTaxCallModalUrl(CALENDLY_URLS.RIVET_TAX_CALL)
                    }
                    subCards={rivetCards}
                />
            </div>
        </div>
    );
}

TaxPartners.propTypes = {
    setTaxCallModalUrl: PropTypes.func.isRequired,
};

export default TaxPartners;
