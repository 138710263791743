import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuthInfo, useLogoutFunction } from "@propelauth/react";
import { useDispatch, useSelector } from "react-redux";
import setSentryUserInfo from "../../sentry";
import { identifyUser, trackEvent } from "../../mixpanel";
import {
    setAuth,
    clearAuth,
    selectAuthErrorOccurred,
    selectActiveOrg,
} from "../../redux/slices/authentication";
import signOut from "../../redux/slices/authentication/thunks/signOut";
import Loading from "../Loading";

function AuthWrapper({ children }) {
    const dispatch = useDispatch();
    const authInfo = useAuthInfo();
    const activeOrg = useSelector(selectActiveOrg);
    const logout = useLogoutFunction();
    const authErrorOccurred = useSelector(selectAuthErrorOccurred);

    // Handle auth state changes
    useEffect(() => {
        if (authInfo.loading) return;

        if (authInfo.isLoggedIn && authInfo.accessToken) {
            let userOrgs;
            if (authInfo.orgHelper) {
                userOrgs = authInfo.orgHelper.getOrgs();
            } else {
                // authInfo.orgHelper == null error was encountered by a user in the SideBar component in
                // production; however we haven't been able to reproduce it in development or production.
                // Thus, we're throwing an error here to alert the team if it occurs again.
                throw new Error(
                    `No org helper found on authInfo in AuthWrapper: ${JSON.stringify(
                        authInfo
                    )}`
                );
            }

            const platformAdminOrg = userOrgs.find(
                (org) => org.orgMetadata.platform_admin === "true"
            );

            let newActiveOrg = null;
            if (platformAdminOrg) {
                newActiveOrg = platformAdminOrg;
            } else if (activeOrg) {
                // Find the active org in the refreshed list of orgs, this is important because the
                // user's role and permissions may have changed in the authInfo refresh
                newActiveOrg = userOrgs.find(
                    (org) => org.orgId === activeOrg.orgId
                );

                // If the active org is not found in the refreshed list of orgs,
                // and there are orgs in the list, set the first org in the list as the active org
                if (!newActiveOrg && userOrgs.length > 0) {
                    [newActiveOrg] = userOrgs;
                } else if (!newActiveOrg && userOrgs.length === 0) {
                    // If the active org is not found in the refreshed list of orgs, and there are no
                    // orgs in the list, set the active org to null
                    newActiveOrg = null;
                }
            } else if (userOrgs.length > 0) {
                [newActiveOrg] = userOrgs;
            }

            setSentryUserInfo(authInfo.user);
            // Identify user and transition from anonymous tracking
            identifyUser(authInfo.user, newActiveOrg);

            trackEvent("Authentication Complete", {
                method: "email",
                hasOrganization: !!newActiveOrg,
            });

            dispatch(
                setAuth({
                    isAuthenticated: true,
                    user: authInfo.user,
                    accessToken: authInfo.accessToken,
                    activeOrg: newActiveOrg,
                })
            );
        } else {
            setSentryUserInfo(null);
            dispatch(clearAuth());
        }
    }, [authInfo, dispatch]);

    // Handle auth errors
    useEffect(() => {
        if (authErrorOccurred) {
            dispatch(signOut(logout));
        }
    }, [authErrorOccurred, dispatch, logout]);

    if (authInfo.loading) {
        return <Loading />;
    }

    return children;
}

AuthWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthWrapper;
