import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { SharedButton } from "../../../shared";
import {
    SharedFormTextInput,
    SharedRequiredMessage,
} from "../../../shared/Inputs";
import { useGetMercurySignupLinkForProviderMutation } from "../../../../redux/slices/apiV2";

const defaultValues = {
    email: "",
};

const schema = yup.object({
    email: yup.string().email().required(SharedRequiredMessage),
});

function Signup() {
    const providerId = useGetProviderId();
    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [getMercurySignupLink] = useGetMercurySignupLinkForProviderMutation();

    const handleFormSubmit = async (formData) => {
        try {
            const response = await getMercurySignupLink({
                providerId,
                body: { invite_email: formData.email },
            });
            window.open(response.data.signup_link, "_blank");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex flex-row items-center bg-white rounded-[48px] p-[4px] gap-x-[10px] pl-[20px]">
            <div className="flex-1">
                <SharedFormTextInput
                    name="email"
                    placeholder="Enter your email"
                    control={control}
                    className="w-full"
                />
            </div>
            <SharedButton
                onClick={handleSubmit(handleFormSubmit)}
                disabled={!isValid}
                className="rounded-full w-fit px-[18px] py-[10px]"
            >
                Sign Up
            </SharedButton>
        </div>
    );
}

export default Signup;
