import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetProviderId from "../../../../hooks/useGetProviderId";
import { useGetExpensesByCategoryGraphDataQuery } from "../../../../redux/slices/apiV2";
import SharedStackedBarGraph from "../../../Graphs/StackedBarGraph";

function ExpensesByCategoryGraph({ period }) {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const { data: expensesByCategoryGraphData } =
        useGetExpensesByCategoryGraphDataQuery({
            providerId,
            businessEntityId,
            params: { period },
        });

    if (!expensesByCategoryGraphData) {
        return null;
    }

    return <SharedStackedBarGraph data={expensesByCategoryGraphData} />;
}

ExpensesByCategoryGraph.propTypes = {
    period: PropTypes.string.isRequired,
};

export default ExpensesByCategoryGraph;
