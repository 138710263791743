import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useGetProviderId from "../../../../../hooks/useGetProviderId";
import { selectDateRange } from "../../../../../redux/slices/dateRange";
import { getCurrencyString } from "../../../../../utils";
import { SharedLabeledText } from "../../../../shared/Text";
import { useGetFinancialSummaryQuery } from "../../../../../redux/slices/apiV2";

function Summary() {
    const providerId = useGetProviderId();
    const { businessEntityId } = useParams();
    const dateRange = useSelector(selectDateRange);

    const { data: financialSummaryData } = useGetFinancialSummaryQuery({
        providerId,
        businessEntityId,
        params: { ...dateRange },
    });

    if (!financialSummaryData) return null;

    return (
        <div className="flex flex-row gap-x-[100px]">
            <SharedLabeledText
                label="Total Revenue"
                text={getCurrencyString(
                    financialSummaryData.total_revenue_cents
                )}
            />
            <SharedLabeledText
                label="Total Expenses"
                text={getCurrencyString(
                    financialSummaryData.total_expenses_cents
                )}
            />
            <SharedLabeledText
                label="Net Profit"
                text={getCurrencyString(financialSummaryData.net_profit_cents)}
            />
            {financialSummaryData.total_revenue_cents !== 0 && (
                <SharedLabeledText
                    label="Net Profit Margin"
                    text={`${financialSummaryData.percent_profit_margin.toFixed(
                        2
                    )}%`}
                />
            )}
        </div>
    );
}

export default Summary;
