import { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateEndDate, selectEndDate } from "../../redux/slices/endDate";

function useResetEndDateIfInvalid(entriesStartData, validUpUntilData) {
    const dispatch = useDispatch();
    const endDate = useSelector(selectEndDate);

    useEffect(() => {
        if (entriesStartData && validUpUntilData) {
            const parsedEndDate = dayjs(endDate);
            const parsedEntriesStart = dayjs(entriesStartData.entries_start);
            if (parsedEndDate.isBefore(parsedEntriesStart, "day")) {
                dispatch(updateEndDate(validUpUntilData.valid_up_until));
            }
        }
    }, [entriesStartData, validUpUntilData, endDate]);
}

export default useResetEndDateIfInvalid;
