import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectActiveOrg } from "../../redux/slices/authentication";

const useGetProviderId = () => {
    const { providerId } = useParams();
    const { legacyOrgId } = useSelector(selectActiveOrg);

    if (providerId) {
        return providerId;
    }
    return legacyOrgId;
};

export default useGetProviderId;
