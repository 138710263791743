import mixpanel from "mixpanel-browser";

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

const ANONYMOUS_ID_KEY = "flychain-mixpanel-anonymous-id";
const ANONYMOUS_ID_PREFIX = "anon-";

// Add this helper function at the top
const debugLog = (message, ...args) => {
    if (process.env.NODE_ENV === "development") {
        console.warn(message, ...args);
    }
};

if (!mixpanelToken) {
    debugLog("Mixpanel token is missing. Analytics will be disabled.");
}

try {
    mixpanel.init(mixpanelToken, {
        debug: process.env.NODE_ENV === "development",
        track_pageview: false, // Disable pageview tracking because its too general
        persistence: "localStorage",
    });

    try {
        const existingMixpanelId = mixpanel.get_distinct_id();
        const storedAnonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);

        if (existingMixpanelId) {
            // Mixpanel already has an ID - ensure it's stored locally
            if (!storedAnonymousId) {
                localStorage.setItem(ANONYMOUS_ID_KEY, existingMixpanelId);
            }
        } else if (storedAnonymousId) {
            // No Mixpanel ID but we have a stored ID - use it
            mixpanel.identify(storedAnonymousId);
        } else {
            // No IDs anywhere - generate new anonymous ID
            const anonymousId = `${ANONYMOUS_ID_PREFIX}-${Math.random()
                .toString(36)
                .substr(2, 9)}`;
            mixpanel.identify(anonymousId);
            localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId);
        }
    } catch (error) {
        debugLog("Error setting up anonymous tracking:", error);
    }
} catch (error) {
    debugLog("Failed to initialize Mixpanel:", error);
}

export default mixpanel;

export const identifyUser = (user, activeOrg) => {
    try {
        const previousId = mixpanel.get_distinct_id();

        // Identify user with their userId
        mixpanel.identify(user.userId);

        // If they were previously anonymous, alias the two IDs
        if (previousId !== user.userId) {
            mixpanel.alias(user.userId, previousId);
        }

        // Set user properties matching backend structure
        mixpanel.people.set({
            $name: `${user.firstName} ${user.lastName}`.trim(),
            $email: user.email,
            user_id: user.userId,
            // Additional properties as needed
            organization_name: activeOrg?.orgName,
        });
    } catch (error) {
        debugLog("Error in identifyUser:", error);
    }
};

export const trackEvent = (eventName, properties = {}) => {
    if (process.env.NODE_ENV === "development") {
        debugLog("Not tracking event in development:", eventName, properties);
        return;
    }

    try {
        // Always include the current distinct_id in properties
        const distinctId = mixpanel.get_distinct_id();
        if (!distinctId) {
            debugLog("No distinct_id available, skipping event:", eventName);
            return;
        }

        mixpanel.track(eventName, {
            ...properties,
            distinct_id: distinctId,
            // Include whether user is anonymous or authenticated
            is_authenticated: !distinctId.startsWith(ANONYMOUS_ID_PREFIX),
        });
    } catch (error) {
        debugLog("Error tracking event:", eventName, error);
    }
};

// Helper to get current distinct_id (anonymous or user_id)
export const getCurrentUserId = () => {
    try {
        return mixpanel.get_distinct_id();
    } catch (error) {
        debugLog("Error getting current user ID:", error);
        return null;
    }
};
