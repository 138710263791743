import React, { useState } from "react";
import DeleteUserModal from "./DeleteUserModal";
import { SharedButton } from "../../shared";
import { SharedPageTitle, SharedSubheading } from "../../shared/Text";
import {
    useGetAllUsersQuery,
    useDeleteUserMutation,
    useGetProvidersQuery,
} from "../../../redux/slices/apiV2";

function TestUsers() {
    const { data: testUsers } = useGetAllUsersQuery({
        params: { testUsers: true },
    });

    const { data: providerIdToProvider } = useGetProvidersQuery({
        params: {},
    });

    const [deleteUser] = useDeleteUserMutation();

    const [provider, setProvider] = useState(null);
    const [user, setUser] = useState(null);

    const onClickForModal = () => {
        deleteUser({ userId: user.user_id });
        setUser(null);
        setProvider(null);
    };

    const onCloseForModal = () => {
        setUser(null);
        setProvider(null);
    };

    if (!testUsers || !providerIdToProvider) {
        return null;
    }

    const renderTableRows = () =>
        testUsers.map((testUser) => {
            const providerForTestUser =
                testUser.org_id_to_org_info &&
                Object.keys(testUser.org_id_to_org_info).length > 0
                    ? providerIdToProvider[
                          Object.values(testUser.org_id_to_org_info)[0]
                              .legacy_org_id
                      ]
                    : null;

            const onClick = () => {
                setUser(testUser);
                if (providerForTestUser) {
                    setProvider(providerForTestUser);
                } else {
                    setProvider(null);
                }
            };

            return (
                <div
                    key={testUser.user_id}
                    className="grid grid-cols-[2.5fr_2.5fr_2fr_2.5fr_1fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{testUser.email}</div>
                    <div>{testUser.user_id}</div>
                    <div>
                        {providerForTestUser
                            ? providerForTestUser.legal_name
                            : ""}
                    </div>
                    <div>
                        {providerForTestUser ? providerForTestUser.id : ""}
                    </div>
                    <div className="flex justify-center w-full">
                        <SharedButton
                            onClick={onClick}
                            className="rounded-full w-fit px-[10px] py-[8px]"
                        >
                            Delete user
                        </SharedButton>
                    </div>
                </div>
            );
        });

    return (
        <div className="flex flex-col p-[30px] gap-y-[30px]">
            <div className="flex flex-col gap-y-[5px]">
                <SharedPageTitle>Test Users</SharedPageTitle>
                <SharedSubheading>
                    You can delete any of the users below
                </SharedSubheading>
            </div>
            <div className="flex flex-col">
                <div className="grid grid-cols-[2.5fr_2.5fr_2fr_2.5fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                    <div>Email</div>
                    <div>User ID</div>
                    <div>Provider</div>
                    <div>Provider ID</div>
                    <div className="flex justify-center w-full">Action</div>
                </div>
                {renderTableRows()}
            </div>
            {user && (
                <DeleteUserModal
                    user={user}
                    provider={provider}
                    onClick={onClickForModal}
                    onClose={onCloseForModal}
                />
            )}
        </div>
    );
}

export default TestUsers;
