import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SharedButton } from "../../../../../shared";
import ChevronDownSvg from "../../../../../../assets/ChevronDown";
import CopySvg from "../../../../../../assets/Copy";
import EyeSvg from "../../../../../../assets/Eye";

function UnconfirmedOfferActions({ offer }) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        if (event && event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const copyProviderLink = () => {
        navigator.clipboard.writeText(
            `https://application.flychain.us/#/businessEntity/${offer.business_entity_id}/capital/offer/${offer.id}`
        );
    };

    const reviewOffer = () => {
        navigate(
            `/provider/${offer.provider_id}/businessEntity/${offer.business_entity_id}/capital/offer/${offer.id}`
        );
    };

    return (
        <div className="flex justify-center w-full">
            <SharedButton
                className="rounded-[6px] px-[10px] py-[8px]"
                onClick={handleClick}
            >
                <div className="flex flex-row items-center gap-[5px] ">
                    Actions
                    <ChevronDownSvg fill="#FFFFFF" width={20} height={20} />
                </div>
            </SharedButton>
            <Menu
                id="admin-actions"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "&:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
            >
                <MenuItem
                    key="copy_link"
                    value="copy_link"
                    className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
                    onClick={copyProviderLink}
                >
                    <CopySvg fill="currentColor" width={16} height={16} />
                    Copy Link
                </MenuItem>
                <MenuItem
                    key="review_offer"
                    value="review_offer"
                    className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
                    onClick={reviewOffer}
                >
                    <EyeSvg fill="currentColor" width={16} height={16} />
                    Review Offer
                </MenuItem>
            </Menu>
        </div>
    );
}

UnconfirmedOfferActions.propTypes = {
    offer: PropTypes.object.isRequired,
};

export default UnconfirmedOfferActions;
